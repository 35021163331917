/* ================= Global btn =================== */
.v-btn{
	border: none;
	border-radius: 3px;
	cursor: pointer;
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
	@include cp-property(transition, 400ms ease-out);
	.txt{
		position: relative;
		color: #fff;
		z-index: 10;
		vertical-align: middle;
		@include cp-property(transition, 400ms ease-out);
	}
	> .icon{
		position: relative;
		width: 20px;
        fill: #fff;
        margin-right: 2px;
        z-index: 10;
        @include cp-property(transition, 400ms ease-out);
	}
	&::after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 5;
		border-radius: 3px;
		@include cp-property(transition, 400ms ease-out);
	}
	&[btn="error"]{
		background: $color-red-dark !important;
	}
	&[btn="success"]{
		background: $color-green-light !important;
		&.pulse:not(.disabled){
		    @include cp-property(animation, pulse-green-light 2s ease infinite);
	    }
	}
	&[btn="warning"]{
		background: $color--yellow  !important;
	}
	&[btn="stop"]{
		background: $color-red-dark  !important;
		> .icon{
			width: 14px;
		}
	}
	&[btn="confirm"]{
		border: 2px solid $color-green-light;
		background: #fff  !important;
		.txt{
			color: $color-green-light;
		}
		> .icon{
			fill: $color-green-light;
		}
		&:hover:not(.disabled){
			&::after{
        	    background: $color-green-light;
            }
			.txt{
				color: #fff;
			}
			> .icon{
				fill: #fff;
			}
		}
	}
	&:focus{
		outline: none;
	}
	&:hover:not(.disabled), &:focus:not(.disabled){
        &::after{
        	background: rgba(0,0,0,0.15);
        }
	}
	&.disabled{
		background: #d8d8d8 !important;
		pointer-events: none;
		.txt{
			color: $color--text;
		}
		> .icon{
			fill: $color--text;
		}
	}

	&.sending{
        pointer-events: none;
		cursor: none;
		&::after{
        	background: rgba(0,0,0,0.15);
        }
	}
}

/* ================= Error 404 y 403 =================== */
#error{
    min-height: 100vh;
    .circles-error{
        perspective: 80px;
        transform-style: preserve-3d;
        transform-origin: 40%; 
        .circle-transform{
        	width: 100%;
            max-width: 450px;
            height: 120px;
            transform: rotateX(40deg);
            transform-origin: bottom;
            .content-circle:nth-child(1){
                width: 80%;
                height: 30%;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 20;
            }
            .content-circle:nth-child(2){
                width: 50%;
                height: 25%;
                position: absolute;
                bottom: 20%;
                left: 0;
                z-index: 5;
            }
            .content-circle:nth-child(3){
                width: 34%;
                height: 17%;
                position: absolute;
                top: 20%;
                right: 30%;
                z-index: 5;
            }
            .content-circle:nth-child(4){
                width: 25%;
                height: 12.5%;
                position: absolute;
                top: 0%;
                left: 0;
                z-index: 5;
            }
        }
    }
    &.error-403{
        .txt-error{
            font-size: 61px;
            font-weight: 500;
        }
        .descrip-error{
            font-size: 17px;
            font-weight: 500;
        }
    }
    &.error-404{
        .txt-error{
            font-size: 85px;
            font-weight: 500;
            line-height: 90px;
        }
        .descrip-error{
            font-size: 31px;
            font-weight: 500;
        }
    }
}

/* ================ More info ================ */
.more-info{
    display: inline-block;
    position: relative;
    vertical-align: middle;
    .cloud{
        position: absolute;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        opacity: 0;
        z-index: 250;
        @include cp-property(transition, 300ms linear);
        .cloud-txt{
            color: #fff;
            font-weight: 400;
        }
        .arrow{
        	position: absolute;
        	border: 8px solid red;
        }
        &.show{
            opacity: 1;
        }
        .icon-cross{
        	position: absolute;
        	top: 3px;
        	right: 3px;
        	width: 14px;
			cursor: pointer;
        }
    }
    .info-icon{
    	position: absolute;
    	left: 0;
    	top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        @include cp-property(transition, 300ms ease-in);
    }
}

/* ================ Hover txt ================ */
.v-hover{
    .txt-hover{
    	overflow: hidden;
    	color: $color--text2;
    	position: relative;
    	&::after {
    		content: '';
    		display: block;
    	}
    }
    .txt-cloud{
    	@extend %horizontal-align;
    	background: $color--whiteDark;
    	box-shadow: 0 0 3px rgba(0,0,0,0.2);
    	border-radius: 5px;
    	padding: .3rem .5rem;
    	pointer-events: none;
    	z-index: 250;
    	p{
    		text-align: center;
    		font-size: 12px;
    	    line-height: 14px;
    	    color: $color--text;
    	}
    }
}

/* ================= Hover buble =================== */
.cloud-hover{
	.cloud-txt{
		position: absolute;
		bottom: 100%;
		font-size: 10px;
		line-height: 12px;
		text-align: center;
		min-width: 50px;
		width: intrinsic;   
		width: max-content;      
		width: -moz-max-content;
		width: -webkit-max-content;
		padding: .2rem .5rem;
		border-radius: 5px;
		background: #fff;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
		color: $color--text2;
		pointer-events: none;
		opacity: 0;
		z-index: 20;
		@include cp-property(transform, translateX(-50%) translateY(10px) scale(0.5));
		@include cp-property(transition, 100ms ease);
		&:not(.cd-right), &:not(.cd-left){
			left: 50%;
		}
		&.cd-right{
            right: 0;
		}
		&.cd-left{
			left: 0;	
		}
	}
	&:hover{
		.cloud-txt{
			opacity: 1;
			@include cp-property(transform, translateX(-50%) translateY(-5px) scale(1));
			@include cp-property(transition, 400ms ease);
		}
	}
}

// Check

/* ================= Start Circles left =================== */
.content-circle{
	position: relative;
    .circle{
    	position: absolute;
	    left: 0;
	    top: 0;
    	border-radius: 50%;
    	width: 100%;
    	height: 100%;
    }
}

#circles1{
	width: 280px;
	height: 280px;
	position: relative;
	.content-circle{
		&:nth-child(1){
			width: 82%;
			height: 82%;
			position: absolute;
			bottom: 0;
			right: 0;
			.circle{  
				@include cp-property(animation, circles-animation 25s ease-in-out infinite);
			}
		}
		&:nth-child(2){
			width: 20%;
			height: 20%;
			position: absolute;
			top: 15%;
			left: 5%;
			.circle{
				@include cp-property(animation, circles-animation 20s linear infinite);
			}
		}
		&:nth-child(3){
			width: 8%;
			height: 8%;
			position: absolute;
			top: 4%;
			left: 20%;
			.circle{ 
				@include cp-property(animation, circles-animation 15s ease infinite);
			}
		}
		&:nth-child(4){
			width: 5%;
			height: 5%;
			position: absolute;
			top: 0;
			left: 10%;
			.circle{
				@include cp-property(animation, circles-animation 18s linear infinite);
			}
		}
	}
}

#circles3{
	width: 300px;
	height: 300px;
	position: relative;
	.content-circle{
		&:nth-child(1){
			width: 82%;
			height: 82%;
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 5;
			.circle{
				@include cp-property(animation, circles-animation 22s ease-in-out infinite);
			}
		}
		&:nth-child(2){
			width: 40%;
			height: 40%;
			position: absolute;
			bottom: 15%;
			left: 0;
			.circle{
				@include cp-property(animation, circles-animation 20s linear infinite);
			}
		}
		&:nth-child(3){
			width: 8%;
			height: 8%;
			position: absolute;
			top: 36%;
			left: 0;
			.circle{
				@include cp-property(animation, circles-animation 16s ease-in-out infinite);
			}
		}
		&:nth-child(4){
			width: 20%;
			height: 20%;
			position: absolute;
			top: 15%;
			left: 6%;
			.circle{
				@include cp-property(animation, circles-animation 13s linear infinite);
			}
		}
	}
}

/* ================= Start Circles right =================== */
#circles2{
	width: 280px;
	height: 280px;
	position: relative;
	.content-circle{
		&:nth-child(1){
			width: 70%;
			height: 70%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			.circle{
				@include cp-property(animation, circles-animation 21s ease-in-out infinite);
			}
		}
		&:nth-child(2){
			width: 40%;
			height: 40%;
			position: absolute;
			bottom: 10%;
			right: 30%;
			.circle{
				@include cp-property(animation, circles-animation 17s ease-in-out infinite);
			}
		}
		&:nth-child(3){
			width: 15%;
			height: 15%;
			position: absolute;
			bottom: 38%;
			right: 13%;
			.circle{
				@include cp-property(animation, circles-animation 19s linear infinite);
			}
		}
	}
}

#circles4{
	width: 200px;
	height: 200px;
	position: relative;
	.content-circle{
		&:nth-child(1){
			width: 70%;
			height: 70%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			.circle{
				@include cp-property(animation, circles-animation 23s ease-in-out infinite);
			}
		}
		&:nth-child(2){
			width: 30%;
			height: 30%;
			position: absolute;
			bottom: 30%;
			right: 0;
			.circle{
				@include cp-property(animation, circles-animation 19s linear infinite);
			}
		}
		&:nth-child(3){
			width: 20%;
			height: 20%;
			position: absolute;
			bottom: 5%;
			right: 30%;
			.circle{
				@include cp-property(animation, circles-animation 16s ease-in-out infinite);
			}
		}
	}
}

/* ================= Search autocomplete =================== */
#search-autocomplete{

    position: relative;
    z-index: 50;

	.search{
		.icon-search{
			@extend %vertical-align;
			left: 15px;
			width: 20px;
			fill: $color--text3;
		}
		.icon-arrow-down{
			@extend %vertical-align;
			right: 15px;
			width: 20px;
			fill: $color--text3;
		}
		input{
			height: 40px;
			width: 100%;
			border-radius: 20px;
			background: #fff;
			color: #000;
			padding-left: 50px;
			padding-right: 50px;
			font-size: 14px;
			border: none;
			&:focus{
				outline: none;
			}
		}
		.more-info{
			@extend %vertical-align;
			right: 15px;
		}
	}

	.dropdown{
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        max-height: 300px;
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.45);
        border-radius: 8px;
        overflow: hidden;
        .msg-search{
        	font-size: 14px;
        	font-weight: 400;
        	color: $color--text3;
        }
        .dropdown-item{
        	border-bottom: 1px solid #efefef;
        	padding-top: 7px;
        	padding-bottom: 7px;
        	cursor: pointer;
            .content-img{
            	width: 45px;
            	height: 45px;
            }
            .content-icon{
            	width: 45px;
            	height: 45px;
            	border-radius: 50%;
            	position: relative;
            	box-shadow: inset 0 0 0 4px rgba(255,255,255,0.5);
            	> .icon{
            		@extend %center-align;
            		width: 45%;
            		fill: #fff;
            	}
            }
        	.subject-tutor{
        		@include cp-style(max-width, calc, unquote('(100% - 45px)'));
        		.name{
        			font-weight: 300;
        			overflow:hidden;
                    white-space:nowrap;
                    text-overflow: ellipsis;
                    .bold{
                    	font-weight: 500;
                    }
        		}
        		.descrip{
        			font-size: 12px;
        		}
        	}
        	&.hover{
        		color: #16181b;
        		text-decoration: none;
        		background-color: #f8f9fa;
        	}
        }
	}
}

/* ================= Dropdown =================== */
.v-dropdown{
    position: relative;
    .txt{
		font-weight: 400;
		font-size: 14px;
		position: relative;
		color: #fff;
		.btn-icon{
            @extend %vertical-align;
            left: 0;
            width: 20px;
            height: 20px;
		}
	}
	.v-dropdown-hover{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}
	.v-dropdown-text-hover{
		right: 50% !important;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		min-width: 50px;
		width: intrinsic;   
		width: max-content;      
		width: -moz-max-content;
		width: -webkit-max-content;
		padding: .3rem 1rem;
		border-radius: 5px;
		background: #fff;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
		color: $color--text2;
		pointer-events: none;
		z-index: 20;
		@include cp-property(transform, translateX(-50%));
	}
	> input{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		pointer-events: none;
		&:focus{
			outline: none;
		}
	}
	.v-dropdown-list{
		position: absolute;
		top: 130%;
		z-index: 200;
		&.hidden > * {
            overflow: hidden;
		}
		.submenu{
		    padding-left: 0;
		    list-style: none;
		    background: #fff;
		    border-radius: 4px;
		    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.21);
		    overflow: hidden;
		    @include cp-style(width, max-content, '');
		    .link-submenu{
			    padding: 6px 20px;
			    color: $color--text2;
			    font-size: 14px;
			    position: relative;
			    z-index: 10;
			    cursor: pointer;
			    @include cp-property(transition, 400ms);
			    span{
				    line-height: 16px;
			    }
			    .sub{
				    font-size: 10px;
				    font-weight: 300;
				    color: $color--text3;
				    line-height: 12px;
			    }
			    > .icon{
				    @extend %vertical-align;
				    width: 20px;
				    left: 20px;
				    fill: $color--text2;
			    }
				&.disabled{
					pointer-events: none;
					opacity: 0.5;
				}
			    &:not(:last-child)::after{
				    content: '';
				    position: absolute;
				    bottom: 0;
				    left: 5%;
				    width: 90%;
				    height: 1px;
				    background: $color--border;
			    }
			    &:not(.disabled):hover{
				    background: #fafafa;
			    }
		    }
	    }
	}
    
}

/* ================= Global btn =================== */
#notifications{
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 90%;
    max-width: 300px;
    z-index: 300;
    .notification{
    	width: 100%;
    	position: relative;
    	border-radius: 10px;
    	padding: 0.5rem 2.5rem 0.5rem 2.5rem;
    	box-shadow: 0px 2px 5px rgba(0,0,0,0.20);
    	.n-icon{
    		position: absolute;
    		left: 15px;
    		top: 8px;
    		left: 12px;
    		width: 22px;
    		fill: #fff;
    	}
    	.title{
    		font-size: 14px;
    		font-weight: 500;
    		color: #fff;
    	}
    	.msg{
    		font-size: 14px;
    		font-weight: 300;
			line-height: 17px;
    		color: #fff;
			.hyphens{
				font-weight: 300;
				white-space: pre-line;
				overflow-wrap: break-word;
				-ms-word-break: break-word;
				word-break: break-word;
				-ms-hyphens: auto;
				-moz-hyphens: auto;
				-webkit-hyphens: auto;
				hyphens: auto;
			}
    	}
    	.btn-close{
    		@extend %vertical-align;
    		right: 15px;
    		width: 18px;
    		fill: #fff;
    		cursor: pointer;
    	}
    	&[type="error"]{
    		background: rgba(246, 86, 86,0.8);
    	}
    	&[type="warning"]{
    		background: rgba(245, 166, 35,0.8);
    	}
    	&[type="successt"]{
    		background: rgba(0, 94, 88,0.8);
    	}
    	&[type="success"]{
    		background: rgba(33, 136, 252, 0.8);
    	}
    }
}

/* ================= Spinner =================== */
.spinner {
	@include cp-property(animation, rotate 2s linear infinite);
    z-index: 2;
  
    & .path {
    	stroke-linecap: round;
    	@include cp-property(animation, dash 1.5s ease-in-out infinite);
    } 
}

.v-spinner-logo{
	$spinner-time: 800;
	@extend %center-align;
	path{
		fill: #fff;
	}
	path:nth-child(4), path:nth-child(9), path:nth-child(11){
        @include cp-property(animation, unquote('logo1 #{$spinner-time}ms ease-out infinite'));
	}

	path:nth-child(3), path:nth-child(10), path:nth-child(14){	
        @include cp-property(animation, unquote('logo2 #{$spinner-time}ms ease-out infinite'));
	}

	path:nth-child(1), path:nth-child(6), path:nth-child(7), path:nth-child(13), path:nth-child(15){	
        @include cp-property(animation, unquote('logo3 #{$spinner-time}ms ease-out infinite'));
	}

	path:nth-child(2), path:nth-child(5){	
        @include cp-property(animation, unquote('logo4 #{$spinner-time}ms ease-out infinite'));
	}
	path:nth-child(8), path:nth-child(12){	
        @include cp-property(animation, unquote('logo5 #{$spinner-time}ms ease-out infinite'));
	}
}

@keyframes logo1{
	0%,60%, 100%{
		opacity: 0.2;
	}
	10%, 50%{
		opacity: 1;
	}
}

@-webkit-keyframes logo1{
	0%,60%, 100%{
		opacity: 0.2;
	}
	10%, 50%{
		opacity: 1;
	}
}

@-moz-keyframes logo1{
	0%,60%, 100%{
		opacity: 0.2;
	}
	10%, 50%{
		opacity: 1;
	}
}

@keyframes logo2{
	0%, 10%, 70%, 100%{
		opacity: 0.2;
	}
	20%, 60%{
		opacity: 1;
	}
}

@-webkit-keyframes logo2{
	0%, 10%, 70%, 100%{
		opacity: 0.2;
	}
	20%, 60%{
		opacity: 1;
	}
}

@-moz-keyframes logo2{
	0%, 10%, 70%, 100%{
		opacity: 0.2;
	}
	20%, 60%{
		opacity: 1;
	}
}

@keyframes logo3{
	0%, 20%, 80%, 100%{
		opacity: 0.2;
	}
	30%, 70%{
		opacity: 1;
	}
}

@-webkit-keyframes logo3{
	0%, 20%, 80%, 100%{
		opacity: 0.2;
	}
	30%, 70%{
		opacity: 1;
	}
}

@-moz-keyframes logo3{
	0%, 20%, 80%, 100%{
		opacity: 0.2;
	}
	30%, 70%{
		opacity: 1;
	}
}

@keyframes logo4{
	0%, 30%, 90%, 100%{
		opacity: 0.2;
	}
	40%, 80%{
		opacity: 1;
	}
}

@-webkit-keyframes logo4{
	0%, 30%, 90%, 100%{
		opacity: 0.2;
	}
	40%, 80%{
		opacity: 1;
	}
}

@-moz-keyframes logo4{
	0%, 30%, 90%, 100%{
		opacity: 0.2;
	}
	40%, 80%{
		opacity: 1;
	}
}

@keyframes logo5{
	0%, 40%, 90%, 100%{
		opacity: 0.2;
	}
	50%, 90%{
		opacity: 1;
	}
}

@-webkit-keyframes logo5{
	0%, 40%, 90%, 100%{
		opacity: 0.2;
	}
	50%, 90%{
		opacity: 1;
	}
}

@-moz-keyframes logo5{
	0%, 40%, 90%, 100%{
		opacity: 0.2;
	}
	50%, 90%{
		opacity: 1;
	}
}