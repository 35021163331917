#v-room {
	.dev-bandWidth {
		position: absolute;
		bottom: 65px;
		right: 0;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 3px;

		p {
			color: #fff;
			font-size: 14px;
		}
	}

	.dev-videoMetric {
		position: absolute;
		left: 20px;
		top: 10px;
		background: rgba(0, 0, 0, 0.3);
		border-radius: 3px;

		p {
			color: #fff;
			font-size: 12px;
		}
	}

	.v-modal-permissions {
		.v-content {
			max-width: 400px;
			max-height: 90%;

			.title {
				font-size: 1.4rem;
				line-height: 1.2em;
			}

			.txt-info {
				line-height: 1.2em;
			}
		}
	}
}

#roomOboarding {
	min-height: 100vh;

	header {
		height: 60px;

		.logo {
			max-width: 260px;
			overflow: hidden;
			@include cp-property(transition, 500ms ease);

			img {
				max-width: 200px;
				max-height: 35px;
				cursor: pointer;
			}
		}

		.btn-exit {
			@extend %removeBtnStyles;
			@include cp-property(transition, 400ms);
			border-radius: 3px;

			.icon-exit {
				width: 22px;
				fill: #fff;
			}

			.exit-txt {
				font-size: 14px;
				font-weight: 300;
				color: #fff;
			}

			&:hover {
				background: rgba(0, 0, 0, 0.2);
			}
		}
	}

	.v-modal-permissions {
		.v-content {
			max-width: 400px;
			max-height: 90%;

			.title {
				font-size: 1.4rem;
				line-height: 1.2em;
			}

			.txt-info {
				line-height: 1.2em;
			}
		}
	}

	.btn-join {
		border-radius: 20px;

		&::after {
			border-radius: 20px;
		}
	}

	.c-roomOboarding {
		//@include cp-style(min-height, calc, unquote('(100vh - 60px)'));
		min-height: calc(100vh - 60px);

		.btn-settings {
			@extend %removeBtnStyles;
			font-size: 13px;
			color: #000;
			padding: .5rem 1rem;
			border: 1px solid var(--pColor) !important;
			border-radius: 20px;
			@include cp-property(transition, 400ms);

			.noun-icon {
				width: 13px;

				path {
					fill: var(--pColor);
					@include cp-property(transition, 400ms);
				}
			}

			&[disabled] {
				border: 1px solid $color--text !important;
				color: $color--text;
				opacity: 0.8;
			}

			&:not([disabled]):hover {
				background: var(--pColor);
				color: #fff;

				.noun-icon path {
					fill: #fff;
				}
			}
		}

		.info-session {
			max-width: 350px;

			.ro-title {
				font-size: 1.4rem;
				font-weight: 400;
				color: #3A506B;
			}

			.ro-subject {
				.ro-icon {
					position: absolute;
					top: 1px;
					left: 0;
					width: 15px;
				}

				.ro-txt {
					font-size: 1rem;
					line-height: 1.1em;
					color: #000;
				}
			}

			.info-txt {
				font-size: 16px;
				line-height: 19px;
				font-weight: 300;
				color: #3A506B;
				display: block;
			}
		}
	}
}

#room {
	background: #000;
	min-height: 100vh;
	height: 100%;

	.fullscreen {
		background: #000 !important;
	}

	.chat-blurcolor {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 120;
		background: rgba(0, 0, 0, 0);
		cursor: pointer;
		pointer-events: none;
		@include cp-property(transition, 400ms);

		&.show {
			background: rgba(0, 0, 0, 0.45);
			pointer-events: all;
		}
	}

	.grid-users {
		width: 100%;
		@include cp-property(transition, max-width 400ms ease);

		&.compact {
			@include cp-style(max-width, calc, unquote('(100% - 350px)'));
			@include cp-property(transition, max-width 400ms ease);
		}
	}

	.msg-promptError {
		.title {
			font-size: 24px;
			color: $color-green-light;
		}

		.msg {
			font-size: 20px;
			font-weight: 500;
			color: #ffF;
		}

		.link {
			font-size: 14px;
			font-weight: 300;
			color: rgba(255, 255, 255, 0.7);
		}
	}

	.msg-denied {
		min-height: 100vh;

		.content-gif {
			@extend %center-align;
			width: 90%;
			max-width: 700px;
			z-index: 5;

			.btn-close {
				border: none;
				background: transparent;
				position: absolute;
				right: 3rem;
				top: 1.5rem;
				z-index: 5;

				>.icon {
					width: 20px;
					fill: $color--text3;
					@include cp-property(transition, 400ms);
				}

				&:hover {
					>.icon {
						fill: $color-red-light;
					}
				}

				&:focus {
					outline: none;
					outline-style: none;
					border: none;
				}
			}

			.title {
				font-size: 24px;
				font-weight: 500;
			}

			.tip:not(.txt) {
				font-size: 14px;
				font-weight: 500;
				position: relative;
				padding-left: 20px;

				.tip-icon {
					@extend %vertical-align;
					left: 0;
					width: 14px;
				}
			}

			.gif {
				max-width: 100%;
				max-height: 220px;
			}

			.txt {
				font-size: 14px;
				font-weight: 300;

				&.tip {
					font-weight: 400;
				}
			}
		}
	}

	.v-chat {
		.v-content-chat .v-chat-bubble[type='file'] {
			max-width: 200px;
		}

		.v-emojis .emoji-picker {
			left: 0;
			@include cp-style(width, calc, unquote('(100% + 70px)'));
		}
	}
}

#v-room {
	.room-settings {
		.v-room-video {
			overflow: initial;
			//background: #000;
		}

		.v-content {
			max-width: 600px;
			max-height: 90%;

			.title {
				font-size: 24px;
				line-height: 26px;

				>span {
					font-size: 24px;
				}
			}

			.mic-icon,
			.sound-icon {
				width: 20px;
				height: 20px;
			}

			.vol-bars {
				left: initial;
				@include cp-property(transform, initial);
				top: initial;

				.content-vol-bar {
					background: rgba(0, 0, 0, 0.2);
				}
			}

			.v-onboarding-video {
				border-radius: initial;
			}

			.noMic,
			.noVideo {
				text-align: center;
				font-weight: 500;
				background: #fafafa;
				color: #979797;
			}

			.noCamera,
			.noMic {
				font-size: 14px;
				line-height: 16px;
			}

			.btn-sound {
				@extend %removeBtnStyles;
				line-height: 12px;
				height: 30px;

				.sound-txt {
					font-size: 10px;
					line-height: 12px;
					text-decoration: underline;
					color: #2188FC;
					@include cp-property(transition, 300ms);
				}

				&.active {
					pointer-events: none;

					.sound-txt {
						text-decoration: initial;
						color: $color--text3;
					}
				}

				&:hover {
					.sound-txt {
						color: $color-blue-dark;
					}
				}
			}

			.v-room-video video {
				@include cp-property(transform, scaleX(-1));
			}
		}
	}
}