/* ================= Start Slick slider =================== */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-dots{
	@extend %horizontal-align;
	bottom: 10px;
	margin-bottom: 0;
	padding-left: 0;
	li[role]{
		display: inline-block;
		width: 7px;
		height: 7px;
		margin-left: 4px;
		margin-right: 4px;
		background: rgba(255,255,255,0.5);
		border-radius: 50%;
		overflow: hidden;
		@include cp-property(transition, 400ms);
		button{
			height: 100%;
			width: 100%;
			opacity: 0;
		}
		&:hover,&.slick-active{
			@include cp-property(transform, scale(1.7));
			background: rgba(255,255,255,1);
		}
	}
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    min-width: 100%;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

/* Select list */
.lists-select{
    max-width: 750px;
	#circles1{
		.content-circle:nth-child(4){
			.circle{
				background: $color-blue-light;
			}
		}
	}
	&.group{
		   .title-list{
                background: $color--yellow2;
		   }
		   .ps__rail-y .ps__thumb-y{
                background: $color--yellow;
           }
           #circles1,#circles2{
            .content-circle{
            	.circle {
            		@include cp-property(transition, 400ms);
            	}
            	   &:nth-child(4), &:nth-child(3), &:nth-child(2){
            	    .circle{
                           background: $color--yellow2;
            	    }
            	   }
            	   &:nth-child(1){
            	    .circle{
            	    	background: $color--yellow;
            	    }
            	   }
            }
           }
           .selected-list-item .title{
            background: $color--yellow;
           }
	}
	.arrow{
		position: relative;
		max-width: 70px;
		z-index: 10;
		.icon{
			width: 40px;
		}
	}
	#circles1{
		position: absolute;
		left: 0;
		bottom: 10%;
		width: 250px;
		height: 250px;
		@include cp-property(transform, translateX(-40%));
		z-index: 5;
	}
	#circles2{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 250px;
		height: 250px;
		@include cp-property(transform, translateX(40%));
		z-index: 5;
	}
	.selected-list-item{
		max-width: 300px;
		position: relative;
		z-index: 10;
	}
}	