/* ================= v-switch =================== */
.v-switch{
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;
    min-height: 42px;
    box-sizing: border-box;
    &::before{
        content: '';
        @extend %vertical-align;
        width: 100%;
        height: 80%;
        background: #eaeaea;
        border-radius: 20px;
        z-index: 1;
    }
    &:not([isActive='']):after{
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 52.5%;
        border-radius: 25px;
        background: $color-blue-main;
        @include cp-property(transition, 300ms);
        z-index: 5;
    }
    .btn-switch{
        height: 100%;
        min-width: 55%;
        border: none;
        background: transparent;
        position: relative;
        top: 0;
        border-radius: 25px;
        color: $color--text3;
        padding-left: 50px;
        padding-right: 35px;
        text-align: left;
        z-index: 15;
        .btn-text{
            font-size: 18px;
            font-weight: 300;
            position: relative;
            line-height: 18px;
            display: block;
            transform-origin: left;
            @include cp-property(transition, 300ms);
        }
        .icon{
            width: 22px;
            height: 22px;
            left: 20px;
            fill: $color--text3;
            @include cp-property(transition, 300ms);
            @extend %vertical-align;
        }
        &[disabled]{
            pointer-events: none;
            opacity: 0.3;
        }
        &:focus{
            outline: none;
        }
    }
    &[isActive="opc1"]{
        .btn-switch{
            &:nth-child(1){
                color: #fff;
                z-index: 20;
                .btn-text{
                    font-weight: 400;
                    @include cp-property(transform, scale(1.1));
                }
                .icon{
                    @include cp-property(transform, translateY(-50%) scale(1.15));
                    fill: #fff;;
                }
            }
        }
        &:after{
            left: 0;    
        }
    }
    &[isActive="opc2"]{
        .btn-switch{
            &:nth-child(2){
                color: #fff;
                z-index: 20;
                .btn-text{
                    font-weight: 400;
                    @include cp-property(transform, scale(1.1));
                }
                .icon{
                    @include cp-property(transform, translateY(-50%) scale(1.2));
                    fill: #fff;;
                }
            }
        }
        &:after{
            left: 47.5%;
            background: $color--yellow;
        }
    }       
}

/* ================= textField, v-datePiket, v-selec =================== */
.v-field{
    line-height: 100%;
    position: relative;
    text-align: left;
    select.field_{
        cursor: pointer;
    }
    .field_{
        height: 30px;
        border: none;
        width: 100%;
        background: #fff;
        color: #6E6E6E;
        padding-left: 0;
        box-shadow: initial;
        @include cp-property(appearance, none);
        &::-webkit-input-placeholder { /* Edge */
            color: #dadada;
            font-weight: 300;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #dadada;
            font-weight: 300;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #dadada;
            font-weight: 300;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #dadada;
            font-weight: 300;
        }
        &::placeholder {
            color: #dadada;
            font-weight: 300;
        }
        &:focus{
            outline: none;
        }
    }
    .content-icon{
        position: absolute;
        z-index: 4;
        right: 0;
        bottom: 0;
        background: #fff;
        height: 35px;
        width: 35px;
        pointer-events: none;
        .v-icon{
            @extend %center-align;
            width: 20px;
            color: $color--text;
        }
        &.btn-eye{
        	pointer-events: all;
        	cursor: pointer;
        }
    }
    .v-content-field::after{
        content: '';
        @extend %horizontal-align;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: $color--text;
        opacity: 0.4;
        z-index: 5;
    }
    .v-content-field::before{
        content: '';
        @extend %horizontal-align;
        bottom: 0;
        width: 0%;
        height: 2px;
        @include cp-property(transition, 400ms);
        z-index: 10;
    }
    .v-placeholder{
        margin-bottom: 0;
        @include cp-property(transform, var(--transform));
        @include cp-property(transform-origin, left);
        position: relative;
        pointer-events: none;
        z-index: 10;
        @include cp-property(transition, 400ms);
        &.isActive{
            @include cp-property(transform, var(--transform-active));
            pointer-events: all;
        }
    }
    .mx-datepicker{
        width: 100% !important;
    }
    &.v-field-s, &.v-field-t{
        color: $color--text3;
        line-height: 100%;
        height: 12px;
        font-size: 16px;    
        font-weight: 400;
    }
    &.focus{
        .v-placeholder{
            color: var(--focus-color) !important;
        }
        .v-content-field::before{
            background: var(--focus-color);
            width: 100%;
        }
        &.v-field-s{ // Remover mas adelante
            .v-placeholder{
                color: $color-blue-main;
            }
            .v-content-field::before{
                background: $color-blue-main;
                width: 100%;
            }
        }
        &.v-field-t{
            .v-placeholder{
                color: $color-green-main;
            }
            .v-content-field::before{
                background: $color-green-main;
                width: 100%;
            }
        }   // Remover mas adelante
    }
    &.active{
        .v-placeholder{
            color: var(--focus-color) !important;
        }
        &.v-field-s{ // Remover mas adelante
            .v-placeholder{
                color: $color-blue-main;
            }
        }
        &.v-field-t{
            .v-placeholder{
                color: $color-green-main;
            }
        }   // Rem
    }
    &.onlyRead{
        pointer-events: none;
    }
    &.disabled{
        pointer-events: none;
        opacity: 0.6;
    }
    &.error{
        .v-placeholder{
            color: $color-red-light !important;
        }
        .v-content-field::before{
            background: $color-red-light !important;
            width: 100%;
        }
    }
}

.v-radio{
    .v-radio-label{
        color: #4A4A4A;
        font-size: 1rem;
        font-weight: 500;
        @include cp-property(transition, 300ms);
    }

    .v-radio-item{
        font-size: 14px;
        color: $color--text2;
        cursor: pointer;
        .v-radio-check{
            width: 16px;
            height: 16px;
            border: 1px solid #979797;
            border-radius: 50%;
            &::after{
                content: '';
                @extend %center-align;
                width: 12px;
                height: 12px;
                background: $color-blue-main;
                border-radius: 50%;
                opacity: 0;
                @include cp-property(transition, 300ms);
            }
        }
        &.active{
            .v-radio-check{
                &::after{
                    opacity: 1;
                }
            }
        }
    }

    &.onlyRead{
        pointer-events: none;
    }

    &.error{
        .v-radio-label{
           color: $color-red-light;
        }
        .v-radio-item{
            .v-radio-check{
                border-color: $color-red-light;
            }
        }
    }

    &.disabled{
        pointer-events: none;
        opacity: 0.6;
    }
}

/* ================= select lang init =================== */
.v-select-lang{
    position: absolute;
    bottom: 40px;
    right: 30px;
    .language{
        background: transparent;
        border: none;
        height: 35px;
        min-width: 100px;
        color: $color--text2;
        padding-right: 30px;
        cursor: pointer;
        @include cp-property(transition, 400ms);
        @include cp-property(appearance, none);
        &.active{
            background: $color-blue-main;
            color: #fff;
        }
        &:focus{
            outline: none;
        }
    }
    .content-icon{
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 35px;
        background: #fff;
        pointer-events: none;
        .icon{
            @extend %center-align;
            width: 20px;
            fill: $color--text2;
        }
    }
}

// Check 

.v-select-v2{
    position: relative;
    background: #f6f6f6;
    border-radius: 15px;
    border: 1px solid $color-blue-main;
    overflow: hidden;
    .select-content{
        height: 30px;   
        padding-left: 30px;
        padding-right: 30px;
        font-size: 14px;
        color: $color-blue-main;
        background: transparent;
        border: none;
        cursor: pointer;
        @include cp-property(appearance, none);
        &:focus{
            outline:none;
            outline-style:none;
            box-shadow:none;
            border-color:transparent;
        }
    }
    > .icon-select{
        @extend %vertical-align;
        right: 0;
        width: 30px;
        height: 26px;
        background: #f6f6f6;
        pointer-events: none;
        > .icon{
            @extend %center-align;
            width: 18px;
            fill: $color-blue-main;
        }
    }
    &[color="green"]{
        border: 1px solid $color-green-main;
        .select-content{
            color: $color-green-main;
        }
        > .icon-select{
            > .icon{
                fill: $color-green-main;
            }
        }
    }
}

/* ================= Range time (availability) =================== */
.v-range-time{
    .v-select2{
        position: relative;
        .v-select2-selected-opc{
            border-radius: 2px;
            border: 1px solid #000;
            height: 35px;
            &::after{
                content: '';
                @extend %vertical-align;
                right: 30px;
                height: 80%;
                width: 1px;
                background: $color--border;
                pointer-events: none;
            }
            .v-select2-selected{
                color: #333;
                font-size: 14px;
                border: none;
                height: 100%;
                background: transparent;
                padding: 5px 30px 5px 15px;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
            }
             .v-select2-icon{
                @extend %vertical-align;
                pointer-events: none;
                right: 7px;
                width: 16px;
            }
        }
        .v-select2-options{
            position: absolute;
            top: calc(100% - 1px);
            left: 0;
            z-index: 50;
            padding: 5px 0;
            margin: 0;
            width: 100%;
            max-height: 190px;
            min-width: 160px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
            border: 1px solid rgba(60, 60, 60, 0.26);
            border-top-style: none;
            border-radius: 0 0 4px 4px;
            text-align: left;
            list-style: none;
            background: #fff;
            .v-select2-opc{
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;
                display: block;
                padding: 3px 20px;
                cursor: pointer;
                position: relative;
                &:hover{
                    color: #fff;
                    background: $color-green-light;
                }
                &.disabled{
                    pointer-events: none;
                    opacity: 0.6;
                }
                &.selected{
                    color: $color-blue-dark;
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }

    }
    &.disabled{
        .v-select2{
            pointer-events: none;
            .v-select2-selected-opc{
                border: 1px solid #E2E2E2;
                .v-select2-selected{
                    color: #E0E0E0;
                }
                .v-select2-icon{
                    fill: #E0E0E0;
                }
            }
        }
        .to .txt-to{
            color: #E0E0E0; 
        }
    }
    .to{
        max-width: 50px;
        @include cp-property(transition, 400ms);
    }
}



/* ================= Time range =================== */
.v-range{

    .title-range{
        font-size: 13px;
        font-weight:500;
        color: $color--text2;
    }
    .range-text{
        font-size: 26px;
        font-weight: 200;
    }
    &.time{
        .range-text{
            font-size: 24px;
            .sg{
                font-size: 15px;
                font-weight: 200;
                margin-left: 4px;
            }
        }
    }
    .range-slider{
        padding-left: 36px;
        padding-right: 36px;
        position: relative;
        height: 30px;
        .vue-slider{
            height: 2px !important;
        } 
        .vue-slider-process{
            background: $color-blue-light;
        }
        .vue-slider-dot-handle{
            background: $color-blue-light;
            box-shadow: 0 0 0 3px rgba(0, 192, 255, 0.4);
        }
    }
    .icon-left{
        @extend %vertical-align;
        left: 0;
        width: 20px;
        height: 20px;
        fill: #000;
    }
    .icon-right{
        @extend %vertical-align;
        right: 0;
        width: 20px;
        height: 20px;
        fill: #000;
        &.moon{
            height: 16px;
        }
    }
}

/* ================= Checkbox =================== */
.v-check{
    .title-checkbox{
        font-size: 13px;
        font-weight:500;
        color: $color--text2;
        margin-bottom: 10px;
    }
    .checkbox-group{
        padding-left: 10px;
        .checkbox-item{
            padding-left: 20px;
            position: relative;
            line-height: 100%;
            & > .checkbox{
                @extend %vertical-align;
                left: 0;
                height: 10px;
                width: 10px;
                border: 1px solid $color--text;
                border-radius: 1px;
                cursor: pointer;
                input[type="checkbox"]{
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    cursor: pointer;
                }
                &.active{
                    border-color: $color-blue-main;
                    background: $color-blue-main;
                }
            }
            .text-checkbox{
                font-size: 13px;
                color: $color--text2;
                cursor: pointer;
            }
        }
    }
}

/* ================= Any =================== */
.check-any{
    padding-left: 15px;
    position: relative;
    .check{
        @extend %vertical-align;
        left: 0;
        height: 10px;
        width: 10px;
        border: 1px solid $color--text;
        border-radius: 1px;
        cursor: pointer;
        &.active{
            border-color: $color-blue-main;
            background: $color-blue-main;
        }
    }

    .text-any{
        font-size: 12px;
        color: $color--text2;
        cursor: pointer;
    }
}

/* ================= Filter radio =================== */
.v-radio-button{
    .title-radio{
        font-size: 13px;
        font-weight:500;
        color: $color--text2;
        margin-bottom: 10px;
    }
    .radios-button{
        margin-right: -5px;
        margin-left: -5px;
    }
    .radio-button{
        padding-left: 5px;
        padding-right: 5px;
        .radio{
            background: #efefef;
            color: $color--text3;
            font-size: 12px;
            border: none;
            border-radius: 2px;
            width: 100%;
            padding-bottom: 0;
            height: 25px;
            @include cp-property(transition, 400ms);
            &:hover,&.active{
                color: #fff;
                font-weight: 400;
                &.primary{
                    background: $color-blue-main;
                }
                &.secundary{
                    background: $color-blue-light;
                }
                &.pink{
                    background: $color--pink;
                }
                &.yellow{
                    background: $color--yellow;
                }
                &.green{
                    background: #83BB38;
                }
                &.purple{
                    background: #AD29D8;
                }
            }
            &:focus{
                outline: none;
            }
        }
        
    }
}

/* ======== Crop image ========= */
.v-crop-image{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    opacity: 0;
    @include cp-property(transition, 400ms);
    z-index: 60;
    .blurcolor{
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
        background: rgba(0,0,0,0.6);
        z-index: 5;
    }
    .crop-spinner{
        @extend %center-align;
    }
    &.show{
        pointer-events: all;
        opacity: 1;
    }
    .modal-crop-image{
        @extend %horizontal-align;
        top: 40px;
        img{
            max-height: 70vh;
        }
        max-width: 90%;
        min-width: 250px;
        background: #fff;
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        border-radius: 5px;
        z-index: 10;
        .crop-title{
            font-size: 20px;
            font-weight: 400;
        }
        .btn-close{
            @extend %vertical-align;
            right: 15px;
            border: none;
            background: transparent;
            cursor: pointer;
            &:focus{
                outline: none;
            }
            .icon-close{
               width: 18px;
            }
            &:hover{
                .icon-close{
                    fill: $color-red-light;
                    @include cp-property(transition, 400ms);
                }
            }
        }
        .btn-reset{
            position: absolute;
            top: 0;
            right: 0;
            padding: .5rem;
            border: none;
            background: transparent;
            cursor: pointer;
            &:focus{
                outline: none;
            }
            .icon-reset{
               width: 18px;
               fill: #fff;
            }
            &:hover{
                .icon-reset{
                    fill: $color-red-light;
                    @include cp-property(transition, 400ms);
                }
            }
        }
        input[type="file"]{
            position: absolute;
            opacity: 0;
            pointer-events: none;
        }
        .img-cropper{
            min-height: 150px;
        }
        .cropper-view-box{
            border-radius: 50%;
            overflow: hidden;
            outline: initial;
        }
        .cropper-line{
            background: transparent;
        }
        .cropper-bg{
            background: #000; 
        }
    }
}