.v-main-format{
    .mf-title, .mf-txt{
        color: #5E5E5E;
        font-size: 1.25rem;
        line-height: 1.2em;
    }
    .mf-title{
        font-weight: 500;
    }
    .mf-txt .bold{
        font-weight: 500;
    }

    .v-fotmat-user-info{
        border-bottom: 2px solid #F2F2F2;
    }

    .vf-txt{
        color: #4A4A4A;
        font-size: 1rem;
        line-height: 1.2em;
        .bold{
            font-weight: 500;
        }
    }

    .mf-menu{
        .v-item-main-format{
            border: 2px solid #E8E8E8;
            border-radius: 4px;
            @include cp-property(transition, 400ms linear);
            .mf-btn-content{
                min-height: 80px;
            }
            .mf-btn-txt{
                font-size: 1.37rem;
                font-weight: 500;
                line-height: 1.2em;
                color: #5E5E5E;
            }
            .mf-completed{
                color: #447100;
                font-weight: 500;
                font-size: 1rem;
            }
            .mf-completed-icon{
                width: 30px;
            }
            &:not(.disabled){
                cursor: pointer;
                &:hover{
                    border-color: $color-blue-main;
                    border-color: var(--hover-color);
                }
            }
            &.disabled{
                pointer-events: none;
                .mf-completed{
                    color: #5E5E5E;
                    opacity: 0.7;
                }
                .mf-completed-icon path:first-child{
                    fill: #5E5E5E;
                    opacity: 0.7;
                }
            }
        }
    }

    .v-format-msg-icon{
        width: 120px;
        fill: #F0F0F0;
    }
    .v-format-msg{
        font-size: 1rem;
        line-height: 1.2em;
        font-weight: 500;
        color: #ccc;
        max-width: 300px;
    }
    .ps__rail-y .ps__thumb-y{
        background: $color-green-main;
    }
}

.v-format{
    min-height: 700px;
    .v-format-info{
        border-bottom: 2px solid #F2F2F2;
    }
    .v-fotmat-user-info{
        border-bottom: 2px solid #F2F2F2;
    }
    .v-border-top{
        border-top: 2px solid #F2F2F2;
    }
    .v-format-title{
        font-size: 1.55rem;
        font-weight: 500;
        line-height: 1.2em;
        color: #4A4A4A;
    }
    #input-file{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        pointer-events: none;
        opacity: 0;
    }
    .v-format-subTitle{
        color: #4A4A4A;
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.2em;
    }
    .v-formt-txt{
        color: #4A4A4A;
        font-size: 1rem;
        line-height: 1.2em;
    }

    .vf-txt{
        color: #4A4A4A;
        font-size: 1rem;
        line-height: 1.2em;
        .bold{
            font-weight: 500;
        }
    }
    .vf-label{
        color: #4A4A4A;
        font-size: 1rem;
        font-weight: 500;
    }
    .v-format-result{
        font-size: 1.125rem;
        line-height: 1.2em;
        color: #4A4A4A;
        background: #DDEDFF;
        border-radius: 3px;
        max-width: 500px;
        .v-format-result-t{
            font-weight: 500;
        }
    }
}

.v-format-question-yes-no, .v-format-question-scale, .v-format-question-radio{
    .v-table-questions {
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 5px 4px 0 rgba(0,0,0,0.12);
    }

    .v-fq-title{
        background: $color-blue-main;
        .v-fq-title-txt{
            color: #fff;
            font-size: 22px;
            line-height: 24px;
        }
    }

    .v-content-question{
        min-height: 48px;
        &.bg-dark{
            background: #F4F4F4 !important;
        }
    }

    .v-content-question-radio{
        border-left: 2px solid #e1e1e1;
    }

    .v-question-radio{
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #979797;
        cursor: pointer;
        position: relative;
        &::after{
            content: '';
            @extend %center-align;
            width: 0;
            height: 0;
            border-radius: 50%;
            background: $color-blue-main;
        }
        &.active::after{
            width: 18px;
            height: 18px;
        }
        &.error{
            border-color: $color-red-light;
        }
        &.disabled{
            pointer-events: none;
        }
    }

    .v-question{
        font-size: 1rem;
        line-height: 1.2em;
        color: #515151;
    }
}

.v-format-question-scale{
    .v-fq-title{
        .v-answer{
            width: 50px;
        }
    }

    .v-content-question-radio{
        width: 50px;
    }
}

.v-format-question-yes-no{
    .v-fq-title{
        .v-answer{
            width: 100px;
        }
    }

    .v-content-question-radio{
        width: 100px;
    }
    
    .v-question-txt{
        font-size: 1rem;
        line-height: 1.2em;
        color: #515151;
    }
    .quality-txt{
        font-size: 1.125rem;
        line-height: 1.2em;
        font-weight: 500;
        color: #4A4A4A;
    }
    .quality-result{
        min-width: 170px;
        border-bottom: 2px solid #4A4A4A;
    }
}

.v-format-question-radio{
    .v-table-questions {
        box-shadow: initial;
    }

    .v-content-question-radio{
        width: 120px;
    }

    .v-fq-title-txt{
        font-size: 1.125rem !important;
        line-height: 1.2em !important;
    }

    .v-question-radio-txt{
        font-size: 1.25rem;
    }
}

.v-questions-result{
    box-shadow: 0 5px 5px 0 rgba(0,0,0,0.12);
    .v-qr-column:not(:last-child){
        border-right: 2px solid #e1e1e1;
    }
    .v-qr-column{
        &:nth-child(1){
            width: 150px;
        }
        &:nth-child(2){
            width: 140px;
        }
        &:last-child{
            width: 180px;
        }
    }
    .v-qr-txt{
        font-size: 1.25rem;
        font-weight: 400;
        color: #515151;
        margin-left: auto;
        margin-right: auto;
        &[color="green"]{
            color: #487C00;
        }
        &[color="greenBold"]{
            font-weight: 500;
            color: #487C00;
        }
        &[size="s"]{
            font-size: 1rem;
        }
    }
    .v-qr-head{
        height: 55px;
        background: #dcedff;
    }
    .v-qr-body{
        height: 45px;
        background: #fff;
    }
    
}


.format-pdf{
    .bottom-line{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: #F2F2F2;
    }
    .left-line{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        width: 1px;
        background: #515151;
    }
    .top-line{
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        width: 100%;
        background: #F2F2F2;
    }
    .pdf-title{
        font-size: 22px;
        font-weight: 500;
        line-height: 25px;
        color: #000;
    }
    .pdf-txt, .pdf-txt .v-formt-txt{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #000;
        .bold{
            font-weight: 500;
        }
    }
    .pdf-txt-18{
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        color: #000;
        &.greenBold{
            color: #487C00;
        }
    }
    .pdf-txt.pdf-txt .v-formt-txt{
        margin-bottom: 1rem !important;
    }
    .pdf-subTitle{
        color: #000;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
    }
    .pdf-label{
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #000;
    }
    .pdf-input-content{
        border-bottom: 1px solid #000;
        min-height: 27px;
    }
    .pdf-input{
        font-size: 14px;
        line-height: 16px;
        color: #4A4A4A;
    }

    .pdf-table-head{
        background: $color-blue-main;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
    }

    .pdf-table-title{
        background: $color-blue-main; // quitar
        color: #fff;
        font-size: 22px;
        line-height: 24px;
    }
    .pdf-table-body{
        &.bg-dark{
            background: #F4F4F4 !important;
        }
        &.bg-blue{
            background: #DCEDFF !important;
        }
    }
    .pdf-radio{
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #4A4A4A;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        &.active{
           background: $color-blue-main;
        }
    }
    .pdf-radio-result-b{
        font-weight: 500;
    }
    .pdf-radio-result{
        border-bottom: 2px solid #000;
    }
    .pdf-result{
        font-size: 18px;
        line-height: 20px;
        color: #4A4A4A;
        background: #DDEDFF;
        border-radius: 3px;
        max-width: 400px;
        .pdf-result-t{
            font-weight: 500;
        }
    }
}