#formats {
    //@include cp-style(min-height, calc, unquote('(100vh - #{$header-h})'));
    min-height: calc(100vh - #{$header-h});

    .v-format-spinner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 50;

        .v-spinner-logo {
            @extend %center-align;

            path {
                fill: rgba(0, 0, 0, 0.5)
            }
        }
    }

    .f-tooltip {
        background: #E7ECF6;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);

        .f-close {
            @extend %removeBtnStyles;
            position: absolute;
            top: 10px;
            right: 10px;

            .f-close-icon {
                width: 24px;
                fill: #000;
                @include cp-property(transition, 300ms);
            }

            &:hover {
                .f-close-icon {
                    fill: $color-red-light;
                }
            }
        }

        .f-icon-tooltip {
            width: 65px;
        }

        .f-title,
        .f.txt {
            font-size: 1.125rem;
            line-height: 1.2em;
        }

        .f-title {
            font-weight: 500;
        }
    }

    .f-btn-prev {
        @extend %removeBtnStyles;

        .f-btn-icon {
            width: 30px;
            fill: #4A4A4A;
            @include cp-property(transition, 300ms);
        }

        .f-btn-prev-txt {
            font-size: 1.125rem;
            color: #4A4A4A;
            @include cp-property(transition, 300ms);
        }

        &:hover {
            .f-btn-prev-txt {
                color: var(--hover-color);
            }

            .f-btn-icon {
                fill: var(--hover-color);
            }
        }
    }

    .v-paper {
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    }
}