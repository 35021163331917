.v-lesson-photo{
    border-radius: 50%;
    .group-icon{
        @extend %center-align;
    }
    .v-lesson-img{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 50%;
        z-index: 5;
        overflow: hidden;
        img{
            @extend %center-align;
            width: 100%;
        }
    }
    .v-lp-type{
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 50%;
        z-index: 10;
        @include cp-property(transform, translate(-15%, 15%));
        box-shadow: inset 0 0 0 1px #fff;
        .v-lp-icon{
            @extend %center-align;
        }
    }
}

.v-lesson-tag{
    border-radius: 12px;
    .v-sp{
        .v-lt-txt::after{
            content: '';
            @extend %vertical-align;
            right: 0;
            width: 1px;
            height: 100%;
            background: #fff;
        }
    }
    .v-lt-txt{
        color: #fff;
        font-size: 11px;
        line-height: 14px;
    }
    .v-lt-content-icon{
        width: 15px;
        height: 12px;
        .v-lt-icon{
            @extend %center-align;
            width: 100%;
            height: 100%;
        }
    }
    .v-lt-btnAssistants{
        @extend %removeBtnStyles;
        width: 100%;
        height: 100%;
    }
}

.v-lesson-topic{
    .v-lt-tag{
        background: #54CD23;
        color: #fff;
        font-size: 11px;
        line-height: 12px;
        border-radius: 2px;
    }
    .v-lt-btnEdit{
        @extend %vertical-align;
        right: .25rem;
        @extend %removeBtnStyles;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        z-index: 5;
        @include cp-property(transition, 400ms ease);
        .v-lt-btnIcon{
            @extend %center-align;
            width: 12px;
            height: 12px;
        }
        &:hover{
            background: rgba(0,0,0, 0.15);
        }
    }
}

.v-lesson-sch{
    .v-lsch-calendar{
        left: 0;
        width: 15px;
    }

    :not(.v-spt).v-lsch-calendar{
        @extend %vertical-align;
    }

    .v-spt{
        position: absolute;
        top: 2px;
    }
    .txt-hover{
        text-transform: capitalize;
    }
}

.v-lesson-place{
    .v-lp-icon{
        @extend %vertical-align;
        width: 15px;
    }
    .v-lp-place{
        font-size: 14px;
        line-height: 16px;
        color: #000;  
        overflow:hidden; 
        text-overflow:ellipsis;
        display:-webkit-box; 
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
    }
    .v-lp-btn{
        @extend %removeBtnStyles;
        .v-hover .txt-hover{
            @include cp-property(transition, 400ms);
            text-decoration: underline;
            line-height: 18px !important,
        }
        &:hover{
            .v-hover .txt-hover{
                color: $color-green-main !important;
            }
        }
    }
}

.v-lesson-btn{
    position: relative;
    @extend %removeBtnStyles;
    border-radius: var(--border-radius);
    @include cp-property(transition, 400ms);
    .v-lb-content-icon{
        position: relative;
        z-index: 10;
        background: var(--bg-icon);
        border-radius: var(--border-radius);
    }
    .bg-W{
        @extend %center-align;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
        z-index: 8;
    }
    .v-lb-icon{
        @extend %center-align;
        z-index: 10;
        @include cp-property(transition, 400ms);
    }
    .v-lb-txt{
        position: relative;
        font-size: 15px;
        font-weight: 300;
        @include cp-property(transition, 400ms);
        z-index: 10;
    }
    &[name="btn-lesson-confirm"]{
        .v-lb-icon path[pid="1"]{
            fill: $color-green-light;
        }
    }
    &.bound{
        .v-lb-content-icon{
            @include cp-property(animation, pulse-green-light 2s ease infinite);
        }
        .v-lb-icon{
            left: 0;
            @include cp-property(transform, translateY(-50%) );
        }
    }
    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: var(--border-radius);
        z-index: 5;
        @include cp-property(transition, 400ms);
    }
    &:not(.bound):hover{
        .v-lb-txt{
            color: var(--hover-txt) !important;
        }
        .v-lb-icon{
            fill: var(--hover-txt) !important;
        }
        &::before{
            background: var(--hover);
        }
    }
}

.v-lesson{
    background: #fff;
    border-radius: 4px;
    .parting::after{
        content: '';
        @extend %vertical-align;
        right: 0;
        width: 1px;
        height: 90%;
        background: #ECECE9;
    }
}

.v-lesson-history{
    background: #fff;
    border-radius: 4px;
    .v-lh-content{
        cursor: pointer;
        border-radius: 4px;
        @include cp-property(transition, 400ms);
        &:hover{
            background: rgba(0,0,0, 0.05);
        }
    }
    
    .parting::after{
        content: '';
        @extend %vertical-align;
        right: 0;
        width: 1px;
        height: 90%;
        background: #ECECE9;
    }
}

#v-ongoing-lesson{
    position: relative;
    z-index: 140;
    opacity: 0;
    pointer-events: none;
    @include cp-property(transition, 400ms);
    &.active{
        opacity: 1;
        pointer-events: all;
    }
    .notification-lesson{
        position: fixed;
        bottom: 12px;
        right: 12px;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: rgba(255,255,255,0.8);
        padding: 12px;
        box-shadow: 0 0 10px rgba(0,0,0,0.15);
        cursor: pointer;
        @include cp-property(transition, 400ms);
        &.hide{
            opacity: 0;
            pointer-events: none;
        }
        .bg-color{
            position: absolute;
            left: 6px;
            right: 6px;
            top: 6px;
            bottom: 6px;
            border-radius: 50%;
            z-index: 5;
            &.bgColor-yellow-main{
                @include cp-property(animation, pulse-yellow 2s linear infinite);
            }
            &.bgColor-student-main{
                @include cp-property(animation, pulse-blue 2s linear infinite);
            }
            &.bgColor-tutor-main{
                @include cp-property(animation, pulse-green 2s linear infinite);
            }
        }
        &:hover{
            .og-content .og-txt{
                opacity: 0;
            }
            .og-content .icon-eye{
                opacity: 1;
            }
            .bg-color{
                &::after, &::before{
                    background: transparent;
                    @include cp-property(animation, initial);
                }
            }
        }
        .og-content{
            position: absolute;
            left: 12px;
            right: 12px;
            top: 12px;
            bottom: 12px;
            background: #fff;
            border-radius: 50%;
            z-index: 10;
            .og-txt{
                font-size: 16px;
                font-weight: 300;
                line-height: 18px;
                color: $color--text2;
                @include cp-property(transition, 400ms);
                .bold{
                    font-weight: 500;
                }
            }
            .icon-eye{
                @extend %center-align;
                fill: $color--text2;
                width: 40px;
                opacity: 0;
                @include cp-property(transition, 400ms);
            }
        }
    }
    .v-modal-lesson{
        position: fixed;
        top: $header-h;
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 350px;
        background: #fff;
        border-radius: 4px 0 0 4px;
        box-shadow: -3px 0 13px rgba(0,0,0,0.07);
        @include cp-property(transition, 400ms ease-in);
        @include cp-property(transform, translateX(100%));
        &.show{
            @include cp-property(transform, translateX(0));
        }
        .btn-close{
            position: absolute;
            left: 1.5rem;
            top: 1.5rem;
            cursor: pointer;
            width: 25px;
            fill: $color--text2;
            @include cp-property(transition, 400ms);
            z-index: 10;
            &:hover{
                fill: $color-red-light;
            }
        }
        .v-modal-title {
            font-size: 1.5rem;
            font-weight: 500;
        }
        .v-ol-name{
            font-size: 1.5rem;
            line-height: 1.2em;
            color: #252525;
        }
        .v-ol-subject{
            font-size: 1.2rem;
            font-weight: 300;
            line-height: 1.2em;
            color: #252525;
        }
        .v-lesson-btn[name="btn-lesson-online"]{
            .v-lb-txt{
                font-size: 18px !important;
            }
        }
    }
}