#dashboard{

    overflow: hidden;

    .d-title{
        position: relative;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
        color: $color--text2;
        display: inline-block;
    }
    
    $height--slick: 220px;
	.dashboard-slick{
        border-radius: 4px;
        overflow: hidden;
        a{
            &:link{
                text-decoration: none;
            }
        }
        .item{
        	height: $height--slick;
            position: relative;
            cursor: pointer;
            .bgColor{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
            }
        	.content{
        		color: #fff;
                position: relative;
                z-index: 10;
        		.title{
        			font-size: 30px;
                    font-weight: 200;
                    max-width: 75%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
        		}
        		.descrip{
        			font-size: 24px;
                    font-weight: 500;
                    max-width: 75%;
                    -webkit-box-orient: vertical;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    text-overflow: ellipsis;
        		}
                
        	}
        }
        .VueCarousel-pagination{
            @extend %horizontal-align;
            bottom: 1rem;
            .VueCarousel-dot-container{
                margin-top: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                .VueCarousel-dot{
                    margin-top: 0 !important;
                    width: 8px !important;
                    height: 8px !important;
                    padding: 0 !important;
                    margin-left: 4px;
                    margin-right: 4px;
                    background: #fff !important;
                    opacity: 0.7;
                    &.VueCarousel-dot--active{
                        width: 12px !important;
                        height: 12px !important;
                        opacity: 1;
                    }
                }
            }
        }
	}
        
    .btn-lessons{
        font-size: 14px;
        line-height: 17px;
        position: relative;
        @include cp-property(transition, 400ms);
        .icon{
            @extend %vertical-align;
            width: 18px;
            right: 0;
            fill: $color-blue-main;
            @include cp-property(transition, 400ms);
        }
        &:link{
            text-decoration: none;
        }
        &:not(.disabled):hover{
            color: $color-red-light !important;
            .icon{
                fill: $color-red-light !important;
            }
        }
        &.disabled{
            color: $color--text2;
            pointer-events: none;
            opacity: 0.6;
            .icon{
                fill: $color--text2;
            }
        }
    }

    .latest-lessons{
        .lessons{
            .VueCarousel-inner{
                margin-left: -1rem;
                margin-right: -1rem;
            }
            .item{
                overflow: hidden;
            }
            .lesson{
                position: relative;
                cursor: pointer;
                .blurcolor{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    border-radius: 4px;
                    background: rgba(0,0,0,0.3);
                    opacity: 0;
                    @include cp-property(transition, 400ms);
                    z-index: 10;
                    pointer-events: none;
                }
                &:hover{
                    .blurcolor{
                        opacity: 1;
                    }
                }
                .lesson-icon{
                    position: relative;
                    > .icon{
                        max-width: 56px;
                        max-height: 56px;
                        fill: #fff;
                    }
                    &::after{
                        content: '';
                        @extend %horizontal-align;
                        bottom: 0;
                        width: 54px;
                        height: 1px;
                        background: #fff;
                    }
                }
                .lesson-name{
                    font-size: 22px;
                    font-weight: 200;
                    color: #fff;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .tutor-name{
                    font-size: 14px;
                    font-weight: 500;
                    color: #fff;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }

    .latest-tutors{
        .tutors{
            .VueCarousel-inner{
                margin-left: -1rem;
                margin-right: -1rem;
            }
            .item{
                overflow: hidden;
            }
            .tutor{
                position: relative;
                background: #fff;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.05);
                cursor: pointer;
                .img{
                    height: 210px;
                    .blurcolor{
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        @include cp-property(transition, 300ms);
                    }
                }
                .info{
                    .name{
                        font-weight: 500;
                        font-size: 20px;
                        color: $color--text2;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .matter{
                        font-size: 14px;
                        color: $color-blue-main;
                        line-height: 100%;
                    }
                    .schedule{
                        font-size: 12px;
                        color: $color--text2;
                    }
                }
                .btn-see{
                    position: absolute;
                    top: 15px;
                    right: 25px;
                    border: none;
                    background: transparent;
                    color: #fff;
                    font-size: 12px;
                    padding-right: 20px;
                    font-weight: normal;
                    opacity: 0;
                    pointer-events: none;
                    @include cp-property(transition, 400ms ease-out);
                    .icon{
                        @extend %vertical-align;
                        top: 45%;
                        right: 0;
                        fill: #fff;
                        width: 15px;
                    }
                    &:focus{
                        outline: none;
                    }
                }
                &:hover{
                    .btn-see{
                        right: 15px;
                        opacity: 1;
                        pointer-events: all;
                    }
                    .blurcolor{
                        background: rgba(0,0,0,0.4);
                    }
                }
            }
        }
    }

    .tutorin-static-info{
        background: #fff;
        border-radius: 4px;
        padding: 2rem 1.5rem;
        .icon{
            position: absolute;
            left: 0;
            top: 0;
            width: 20px;
        }
        .txt1{
            display: block;
            font-size: 19px;
            color: $color--text4;
            line-height: 100%;
            font-weight: 300;
        }
        .txt2{
            display: block;
            font-size: 16px;
            color: #b0b0b0;
            line-height: 70%;
            font-weight: 300;
        }
        .line::after{
            content: '';
            @extend %vertical-align;
            right: 0;
            height: 90%;
            width: 1px;
            background: #E8E8E8;

        }
        .cont{
            font-size: 53px;
            font-weight: 300;
        }
        .completed, .hours, .canceled{
            position: relative;
            padding-left: 28px;
        }
        .content-completed{
            .cont{
                color: $color-green-main;
            }
        }
        .content-hours{
            .cont{
                color: $color-blue-light;
            }
        }
        .content-canceled{
            .cont{
                color: $color-red-light;
            }
        }
        .content-this-week{
            max-width: 210px;
            .this-week{
                position: relative;
                padding-left: 28px;
                .txt1{
                    color: #b0b0b0;
                    font-size: 28px;
                }
                .txt2{
                    font-size: 42px;
                    color: #000;
                }
            }
        }
    }
}


.v-tutoring-place, .v-student-topic,.confirm-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    pointer-events: none;
    
    .blurColor{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.0);
        @include cp-property(transition, opacity 400ms ease-out);
        cursor: pointer;
        z-index: 5;
    }
    &.active{
        pointer-events: all;
        .v-content{
            opacity: 1;
        }
        .blurColor{
            background: rgba(0,0,0,0.6);
        }
    }
    .v-content{
        @extend %center-align;
        max-width: 340px;
        width: 90%;
        border-radius: 8px;
        background: #fff;
        z-index: 10;
        overflow: hidden;
        opacity: 0;
        @include cp-property(transition, opacity 400ms ease-out);
        .title{
            color: #fff;
            line-height: 30px;
            font-size: 28px;
            font-weight: 300;
            position: relative;
            .icon-market{
                fill: #fff;
                top: 1.8rem;
                left: 1.4rem;
                width: 25px;
                position: absolute;
            }
            .btn-close{
                @extend %vertical-align;
                right: 20px;
                width: 22px;
                fill: #fff;
                cursor: pointer;
            }
            > span{
                color: #fff;
                font-size: 32px;
                font-weight: 500;
            }
        }
        .icon{
            width: 110px;
            height: 95px;
        }
        .alert-msg{
            font-size: 24px;
            font-weight: 500;
            line-height: 26px;
        }
        .alert-legend{
            font-weight: 300;
            strong{
                font-weight: 500;
            }
        }
        .btn-cancel{
            font-size: 12px;
            border: none;
            background: transparent;
            padding: 0;
            position: relative;
            cursor: pointer;
            &::after{
                content: '';
                @extend %horizontal-align;
                bottom: 0;
                height: 1px;
                width: 100%;
                background: #000;
            }
            &:focus{
                outline: none;
            }
        }
        .descrip{
            font-size: 18px;
            line-height: 22px;
            color: $color--text2;
            margin-bottom: 2.4rem;
        }
        .field-place, .field-topic{
            border: 1px solid #979797;
            border-radius: 4px;
            height: 48px;
            font-size: 18px;
            margin-bottom: 2.4rem;
            padding-left: 15px;
            padding-right: 15px;
            line-height: 19px;
            &:focus{
                outline: none;
            }
        }
        .field-topic{
            resize: none;
        }
        .field-topic {
            height: 80px;
            line-height: 19px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .btnA{
            height: 44px;
            font-size: 20px;
        }
    }
}