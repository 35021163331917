#chat{
	.vn-btn-prev{
        @extend %removeBtnStyles;
        .vn-btn-icon{
            width: 18px;
            fill: #000;
        }
        .vn-btn-txt{
            color: #000;
        }
        .vn-btn-icon, .vn-btn-txt{
            @include cp-property(transition, 300ms ease);
        }

        &:hover{
            .vn-btn-icon{
                fill: var(--hover-color);
            }
            .vn-btn-txt{
                color: var(--hover-color);
            }
        }
    }
	.contacts{
	    max-width: 360px;
	}
	.pending-request{
		max-width: 300px;
	}
	.v-chat, .v-chat-contacts{
		//@include cp-style(height, calc, unquote('(100vh - 60px - 112px)') !important);
		min-height: 500px;
	}

    .v-chat-msg{
        > .img{
            width: 130px;
            max-width: 300px;
        }
        .msg{
            font-weight: 500;
            color: #ccc;
        }
    }
}