/* ================= Start Card tutor =================== */
.card-tutor {
    .col-img {
        max-width: 84px;
    }

    .score {
        .starts {
            position: relative;
            padding-right: 3px;

            .vue-star-rating-star {
                margin-right: 5px;
            }

            &::after {
                content: attr(attr);
                position: absolute;
                top: 3px;
                left: 100%;
                font-size: 10px;
                color: $color--text3;
                line-height: 100%;
                font-weight: 300;
            }
        }

        .custom-text {
            font-size: 20px;
            font-weight: 500;
            line-height: 100%;
            margin: 0;

        }
    }

    .title-subjects {
        font-size: 10px;
        color: #6e6e6e;
        font-weight: 400;
    }

    .card-tags {
        margin-right: -3px;
        margin-left: -3px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: -15px;
            top: 0;
            width: 1px;
            background: #ececec;
            //@include cp-style(height, calc, unquote('(100% + 20px)'));
            height: calc(100% + 20px);
        }
    }

    .card-tag {
        display: inline-block;
        padding: 0px 9px;
        margin-left: 3px;
        margin-right: 3px;
        border-radius: 10px;
        font-size: 12px;
        color: $color--text;
        border: 1px solid $color--text;
        cursor: pointer;
        max-width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include cp-property(transition, 400ms);

        &.schedule {
            color: $color-blue-main;
            border-color: $color-blue-main;
        }

        &:hover,
        &.active {
            border-color: $color-blue-main;
            background: $color-blue-main;
            color: #fff;
        }
    }

    .info {
        max-width: 230px;

        .v-btn {
            .txt {
                font-weight: 400;
            }
        }

        .type-lesson {
            .icon {
                height: 27px;
                width: 27px;
                position: relative;
                margin-right: 10px;
                border-radius: 50%;

                &.group {
                    background: $color--yellow;
                }

                &.one-on-one {
                    background: $color-blue-main;
                }

                svg {
                    @extend %center-align;
                    width: 72%;
                    fill: #fff;
                }
            }

            .txt {
                line-height: 100%;

                .lesson {
                    font-size: 14.5px;
                    font-weight: 500;
                    color: #000;
                }

                .quotas {
                    font-size: 12px;
                    font-weight: 300;
                }
            }
        }

        .lesson-site {
            .icon {
                width: 20px;
                fill: #000;
                margin-right: 10px;
            }

            .type {
                font-size: 14.5px;
                font-weight: 500;
                color: #000
            }
        }

        .btnA {
            height: 36px;
        }
    }

    .content-img {
        width: 65px;
        height: 65px;
    }

    .info-tutor {
        width: calc(100% - 84px - 230px);
        width: -webkit-calc(100% - 84px - 230px);
        width: -moz-calc(100% - 84px - 230px);
    }

    .name-tutor {
        position: relative;

        .txt-hover {
            color: $color--text4 !important;
            cursor: pointer;
            @include cp-property(transition, 300ms ease);
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 0.5px;
            background: $color--text4;
            @include cp-property(transition, 300ms ease);
        }

        &:hover {
            .txt-hover {
                color: $color-blue-main !important;
            }

            &::after {
                background: $color-blue-main;
            }
        }
    }

    .descrip {
        font-size: 14px;
        color: #6e6e6e;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 100px;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: -10px;
            height: 100%;
            width: 1px;
            background: #ececec;
        }

        p,
        span,
        strong,
        b {
            font-weight: 300 !important;
        }
    }

    .title-step {
        font-size: 26px;
        font-weight: 400;
    }

    .btn-close-step {
        button {
            border: none;
            background: transparent;
            cursor: pointer;

            svg {
                width: 20px;
                fill: $color--text2;
                @include cp-property(transition, 400ms);
            }

            &:hover {
                svg {
                    fill: $color-red-light;
                }
            }

            &:focus {
                outline: none;
            }
        }
    }

    .step {
        position: relative;

        .btnA {
            max-width: 220px;
            font-size: 20px;
            font-weight: 200;
            height: 36px;
        }
    }

    .schedule {
        .calendar {
            padding-left: 10%;

            .c-pane {
                .c-header {
                    width: 80%;
                    margin-left: 10%;

                    .c-title-layout .c-title-popover .c-title-anchor {
                        color: $color-blue-main;

                        .month-label {
                            font-weight: 500;
                        }
                    }
                }
            }
        }

        .select-hour {
            padding-right: 10%;

            .hour-picker {
                .mx-input {
                    height: 44px;
                    border-radius: 22px;
                    padding-right: 50px;
                    padding-left: 20px;
                }

                .mx-input-append {
                    width: 50px;

                    .mx-input-icon {
                        @extend %center-align;
                        width: 20px;
                    }
                }
            }
        }

        .lesson-at {
            .icon {
                width: 25px;
            }

            .txt {
                font-size: 20px;
                color: $color--text2;
                font-weight: 400;
            }
        }

        .lesson-date {
            font-size: 18px;
            font-weight: 300;
            color: $color--text;
        }
    }

    .content-places {
        max-width: 550px;
    }
}

/* ================= Start List Item =================== */
.selected-list-item {
    &.paper {
        overflow: hidden;
    }

    .title-list {
        background: $color-blue-main;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
        padding: 8px 15px;
        @include cp-property(transition, 400ms);
    }

    .list-items {
        height: 270px;

        .list-item-search {
            padding: 7px 10px;

            input {
                width: 100%;
                height: 30px;
                border: none;
                ;
                background: #f6f6f6;
                color: $color--text3;
                border-radius: 15px;
                padding-left: 15px;
                padding-right: 15px;

                &:focus {
                    outline: none;
                }
            }

            &~ul {
                //@include cp-style(height, calc, unquote('(100% - 44px)'));
                height: calc(100% - 44px);
            }

            &.disabled {
                pointer-events: none;
                opacity: 0.6;
            }
        }

        .content-msg {
            //@include cp-style(height, calc, unquote('(100% - 44px)'));
            height: calc(100% - 44px);


            .msg-list {
                font-size: 14px;
                color: $color--text;
            }
        }

        ul {
            list-style: none;
            overflow-y: scroll;
            height: 100%;

            .btn-list {
                padding-top: 10px;
                padding-bottom: 10px;
                cursor: pointer;
                @include cp-property(transition, 400ms);
                position: relative;
                padding-right: 50px;

                .icon {
                    @extend %vertical-align;
                    right: 25px;
                    width: 20px;
                }

                &:hover,
                &.list-item-active {
                    background: #f4f4f4;
                }
            }
        }
    }
}

/* ============= Subjects ============= */
.v-schedule-subjects {
    max-width: 500px;

    >.title {
        font-size: 26px;
        font-weight: 400;
    }

    .search-subject {
        height: 30px;
        background: #f6f6f6;
        padding-left: 20px;
        padding-right: 20px;
        border: none;
        border-radius: 15px;
        font-size: 14px;

        &:focus {
            outline: none;
        }
    }

    .in {
        color: $color-blue-main;
        font-size: 12px;
        font-weight: 500;
        max-width: 20px;
    }

    .v-subjects-list {
        max-height: 200px;
        overflow: hidden;

        .msg-search {
            font-size: 14px;
            font-weight: 400;
            color: $color--text3;
        }

        .radio-subjects {
            cursor: pointer;
            height: 60px;
            @include cp-property(transition, 400ms linear);

            .l-subject {
                padding-left: 3rem;
                padding-right: 105px;
                position: relative;

                .content-subject-name {
                    position: relative;
                }

                .icon-subject {
                    @extend %vertical-align;
                    left: 0;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.4);

                    >.icon {
                        @extend %center-align;
                        fill: #fff;
                        max-width: 50%;
                        max-height: 50%;
                        width: 16px;
                    }

                    &[color='y'] {
                        background: $color--yellow;
                    }

                    &[color='b'] {
                        background: $color-blue-main;
                    }
                }

                .modality-subject {
                    @extend %vertical-align;
                    right: 0;
                    padding-left: 25px;
                    padding-right: 1.5rem;

                    .check-subject {
                        @extend %vertical-align;
                        right: 0;
                        width: 17px;
                        height: 17px;
                        border-radius: 50%;
                        background: transparent;
                        border: 1px solid $color--text;
                    }
                }
            }

            .name-subject {
                font-size: 14px;
                color: $color--text2;
                line-height: 120%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .category-subject {
                font-size: 9px;
                color: $color--text2;
            }


            &[color="clear"] {
                background: #fff;
            }

            &[color="dark"] {
                background: #fafafa;
            }

            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }

            &.active {
                .l-subject .modality-subject .check-subject {
                    background: $color-blue-light;
                    border-color: $color-blue-light;
                }
            }
        }
    }
}

/* ============= hour and time ============= */
.v-schedule {
    max-width: 800px;

    .c-title {
        text-transform: capitalize;
    }

    .lesson {
        .lesson-icon {
            width: 27px;
        }

        .subject {
            font-size: 18px;
            font-weight: 500;
            color: $color--text2;
        }

        .border-r {
            border-right: 1px solid #f0f0f0;
        }
    }

    .lesson-date {
        font-size: 18px;
        font-weight: 300;
        color: #060606;
    }

    .schedule-time {
        position: relative;
        border-radius: 20px;
        border: 1px solid #979797;
        padding-left: 20px;
        cursor: pointer;

        >.time {
            height: 34px;
            width: 100%;
            border: none;
            background: transparent;
            cursor: pointer;
            @include cp-property(appearance, none);

            &:focus {
                outline: none;
            }
        }

        >.content-icon {
            position: absolute;
            top: 1px;
            height: 32px;
            width: 30px;
            border-radius: 0 20px 20px;
            background: #fff;
            right: 1px;
            pointer-events: none;

            >.icon {
                @extend %vertical-align;
                width: 20px;
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

/* ============= Places ============= */
.v-places {
    .v-places-content {
        max-width: 550px;
    }
}

.v-places-list {
    max-height: 200px;
    overflow: hidden;

    .msg-search {
        font-size: 14px;
        font-weight: 400;
        color: $color--text3;
    }

    .radio-place {
        cursor: pointer;
        @include cp-property(transition, 400ms linear);

        .icon-place {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.4);
            position: relative;

            .icon {
                @extend %center-align;
                fill: #fff;
                max-width: 50%;
                max-height: 50%;
            }
        }

        .name-place {
            font-size: 14px;
            color: $color--text2;
            line-height: 120%;
        }

        .val-place {
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: transparent;
            border: 1px solid $color--text;
            position: relative;
        }

        &[color="clear"] {
            background: #fff;
        }

        &[color="dark"] {
            background: #fafafa;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }

        &.active {
            .val-place {
                background: $color-blue-light;
                border-color: $color-blue-light;
            }
        }
    }
}

/* ============= Lesson Summary ============= */
.v-lesson-summary {
    .v-lesson-summary-info {
        border: 2px solid $color-blue-main;
        border-radius: 5px;

        .title-step {
            font-size: 18px !important;
            line-height: 20px;
            font-weight: 500 !important;
            color: #000;
        }

        .v-lesson-tutor,
        .v-lesson-subject,
        ,
        .v-lesson-date {
            font-size: 1rem;
            line-height: 18px;
            color: #000;
        }

        .v-lesson-tutor,
        .v-lesson-subject,
        .v-lesson-date {
            font-weight: 500;
        }

        .v-lesson-subject,
        .v-lesson-date {
            padding-left: 25px;

            .v-ls-icon {
                position: absolute;
                top: 3px;
                left: 0;
                width: 14px;
            }
        }

        .v-topic-tag {
            background: #54CD23;
            color: #fff;
            font-size: 11px;
            line-height: 12px;
            border-radius: 2px;
        }

        .v-topic-opc {
            font-size: 12px;
            line-height: 15px;
            color: #000;
        }

        .v-topic-field {
            height: 28px;
            background: #ececec;
            border-radius: 3px;
            font-size: 12px;
            color: #000;
            padding-left: 1rem;
            padding-right: 1rem;
            border: none;
            min-width: 200px;
            box-shadow: initial;
            @include cp-property(appearance, none);

            &::-webkit-input-placeholder {
                /* Edge */
                color: #7f7f7f;
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #7f7f7f;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #7f7f7f;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: #7f7f7f;
            }

            &::placeholder {
                color: #7f7f7f;
            }

            &:focus {
                outline: none;
            }
        }
    }
}