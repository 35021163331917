@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #get-app {
        .info {
            max-width: initial;
        }
    }

    #history:not(.compact) {
        .lesson-detail {
            .space {
                display: none !important;
            }
        }
    }

    #dashboard:not(.compact) .tutorin-static-info .content-this-week {
        max-width: initial;
    }

    #dashboard:not(.compact) .tutorin-static-info .content-completed.line::after {
        background: transparent;
    }

    .content-filters.no-fix {
        position: relative;
        left: initial;
        top: initial;
        right: initial;
        bottom: initial;
        pointer-events: all;
        opacity: 1;
        z-index: initial;
        display: block;

        .filters {
            position: relative;
            left: initial;
            top: initial;
            height: initial;
            @include cp-property(transform, initial);
        }
    }
}

@media only screen and (max-width: 1199px) {
    #manage .lesson {

        .card-lesson-itemn,
        .place {
            &.line-right::after {
                background: transparent;
            }
        }
    }

    .content-filters {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 250;
        pointer-events: none;
        opacity: 0;
        display: none;

        .blurcolor {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            background: rgba(0, 0, 0, 0.5);
            z-index: 5;
        }

        .filters {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 10;

            .v-range .range-slider {
                pointer-events: none !important;
            }

            .paper {
                height: 100%;
            }

            .btn-close {
                position: absolute;
                right: 1rem;
                top: 50%;
                @include cp-property(transform, translateY(-50%));
                width: 18px;
                cursor: pointer;
                fill: #fff;
                @include cp-property(transition, 400ms);

                &:hover {
                    fill: $color-red-dark;
                }
            }
        }

        &.active {
            pointer-events: all;
            opacity: 1;
            display: block;

            .v-range .range-slider {
                pointer-events: all !important;
            }
        }
    }

    .v-questions-result .v-qr-txt {
        font-size: 1.15rem;
    }

    .v-questions-result .v-qr-column:nth-child(1) {
        width: 140px;
    }

    .v-questions-result .v-qr-column:last-child {
        width: 165px;
    }

    #chat .contacts {
        max-width: initial;
    }
}

@media only screen and (max-height: 820px) {
    #v-ongoing-lesson {
        .v-modal-lesson {
            .v-ol-content {
                flex-wrap: nowrap !important;
                margin-bottom: 1rem !important;

                .v-lesson-photo {
                    width: 75px !important;
                    height: 75px !important;
                }

                .v-ol-name {
                    font-size: 1.4rem;
                }

                .w-100 {
                    width: initial !important;
                    text-align: left !important;
                    padding-left: 10px;
                    @include cp-style(max-width, calc, unquote('(100% - 75px)'));
                }
            }
        }
    }
}

@media only screen and (max-height: 740px) {
    #v-ongoing-lesson {
        .v-modal-lesson {
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;

            .btn-close {
                top: 1rem;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    #main-menu {
        .ps {
            overflow: initial !important;

            .ps__rail-y {
                display: none !important;
            }
        }

        &.compact {
            max-width: 56px;

            .menu {
                .content-btn {
                    .btn-menu {
                        padding-right: 0;
                        padding-left: 0;
                    }

                    .btn-sub {
                        padding-right: 15px;

                        &.nav-name {
                            padding-left: 15px;
                        }

                        color: $color--text2;

                        &::before {
                            content: none;
                        }
                    }

                    position: relative;

                    .v-slide-animated {
                        position: absolute;
                        left: 100%;
                        top: 0;
                        pointer-events: none;
                    }

                    &:hover {
                        .v-slide-animated {
                            pointer-events: all;
                        }
                    }

                    .sub {
                        width: 240px !important;
                        background: #f8f8f8;
                        border-radius: 0 4px 4px 0;
                        overflow: hidden;
                        border-left: none;
                        opacity: 0;
                        pointer-events: none;

                        .btn-sub {
                            &:hover {
                                background: darken(#f8f8f8, 5%);
                            }
                        }

                        @include cp-property(transition, 0ms);
                    }

                    .nav-name {
                        color: #fff;
                        font-weight: 400;
                    }

                    &:hover,
                    &.active-link {
                        .btn-menu {
                            .icon {
                                fill: #fff;
                            }
                        }

                        .sub {
                            opacity: 1;
                            pointer-events: all;
                            @include cp-property(transition, 400ms);
                        }
                    }
                }

                &[activeColor='blue'] .content-btn {
                    .sub .content-btn:first-child .btn-sub {
                        background: $color-blue-main;
                    }

                    .nav-name {
                        background: $color-blue-main;
                    }

                    &:hover .btn-menu,
                    &.active-link .btn-menu {
                        background: $color-blue-main;
                    }
                }

                &[activeColor='green'] .content-btn {
                    .sub .content-btn:first-child .btn-sub {
                        background: $color-green-main;
                    }

                    .nav-name {
                        background: $color-green-main;
                    }

                    &:hover .btn-menu,
                    &.active-link .btn-menu {
                        background: $color-green-main;
                    }
                }
            }
        }

        &.hide-text {
            .schedule {
                padding-left: 0.25rem;
                padding-right: 0.25rem;
                @include cp-property(transition, 500ms all);

                .btn-schedule {
                    .text {
                        position: absolute;
                        height: 50px;
                        width: 260px;
                        top: 0;
                        left: 100%;
                        background: $color-blue-main;
                        padding: 12px 15px;
                        border-radius: 0 4px 4px 0;
                        text-align: left;
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                &:hover {
                    background: var(--bg-color);

                    .btn-schedule .text {
                        opacity: 1;
                        pointer-events: all;
                        @include cp-property(transition, 500ms);
                    }
                }
            }

            .linc-app {
                padding-left: 0.25rem;
                padding-right: 0.25rem;
                @include cp-property(transition, 500ms all);

                .btn-linc-app {
                    .text {
                        position: absolute;
                        height: 50px;
                        width: 260px;
                        top: 0;
                        left: 100%;
                        color: #fff;
                        padding: 12px 15px;
                        border-radius: 0 4px 4px 0;
                        text-align: left;
                        opacity: 0;
                        pointer-events: none;
                    }
                }

                &:hover {
                    .btn-linc-app {
                        box-shadow: none;

                        .text {
                            opacity: 1;
                            pointer-events: all;
                            @include cp-property(transition, 500ms);
                        }
                    }
                }

                &[activeColor='blue'] {
                    .text {
                        background: $color-blue-main;
                    }

                    &:hover {
                        background: $color-blue-main;

                        .btn-linc-app {
                            background: $color-blue-main;
                        }
                    }
                }

                &[activeColor='green'] {
                    .text {
                        background: $color-green-main;
                    }

                    &:hover {
                        background: $color-green-main;

                        .btn-linc-app {
                            background: $color-green-main;
                        }
                    }
                }
            }

            .schedule {
                .btn-schedule {
                    .icon {
                        margin-right: 0;
                    }
                }
            }

            .linc-app {
                .btn-linc-app {
                    .icon {
                        margin-right: 0;
                        box-shadow: none;
                    }

                    .thin,
                    .bold {
                        display: none;
                    }

                    .all {
                        display: block;
                    }
                }
            }

            .menu {
                .content-btn {
                    .btn-menu {
                        .text {
                            opacity: 0;
                            @include cp-property(transition, 0ms);
                        }

                        .icon-sub {
                            opacity: 0;
                            @include cp-property(transition, 0ms);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    #dashboard .tutorin-static-info {
        .content-this-week {
            max-width: initial;
        }

        .content-canceled {
            min-width: initial;
        }

        .content-completed.line::after {
            background: transparent;
        }
    }

    #main-menu {
        @include cp-property(transform, translateX(-100%));
        @include cp-property(transition, 500ms);

        .schedule {
            margin-bottom: 5px;
            margin-top: 5px;

            .btn-schedule {
                height: 40px;
            }
        }

        .menu {
            &[activecolor="green"] {
                //@include cp-style(max-height, calc, unquote('(100vh - 54px - 60px)'));
                max-height: calc(100vh - 54px - 60px);
            }

            &[activecolor="blue"] {
                //@include cp-style(max-height, calc, unquote('(100vh - 54px - 54px - 60px)'));
                max-height: calc(100vh - 54px - 54px - 60px);
            }
        }

        .linc-app {
            margin-bottom: 5px;
            margin-top: 5px;

            .btn-linc-app {
                height: 40px;
            }
        }

        &.show {
            @include cp-property(transform, translateX(0));
        }
    }

    .blurcolor-menu {
        position: fixed;
        z-index: 100;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    #room {
        .v-room-header {
            .v-room-users {
                .v-users {
                    width: 90vw;
                    max-width: 350px;
                }
            }
        }
    }

    #header-main {
        .btn-menu {
            .content-btn-menu {
                background: transparent !important;
            }
        }
    }

    .view {
        margin-left: 0px !important;
    }

    #get-app {

        .info,
        .img {
            //max-width: initial;
            min-width: initial;
        }
    }
}

@media only screen and (max-height: 750px) {
    .v-modal.mFeedback {
        .v-modal-paper {
            max-height: 90%;
        }

        .img-feedback-group,
        .content-img {
            width: 70px !important;
            height: 70px !important;
        }

        .v-feedback-duration {
            margin-bottom: 1rem !important;
        }

        #feedback-review {
            height: 100px;
        }

        .vue-star-rating {
            margin-bottom: 0 !important;
        }

        .v-modal-paper {
            .v-modal-title {
                padding-top: 1rem;
                padding-bottom: .5rem;

                .v-t {
                    font-size: 24px;
                }
            }
        }

        .v-btn {
            margin-bottom: .5rem !important;
        }
    }

}

@media only screen and (max-width: 767px) {
    #header-main {
        .btn-menu {
            max-width: 60px;

            .content-btn-menu {}
        }

        .menu {
            .nav-link.profile {
                .picture {
                    margin-right: 0;
                }

                .name {
                    display: none;
                }
            }
        }
    }

    .card-lesson-item {
        max-width: initial !important;
    }

    .lesson {
        .actions {
            margin-left: initial;
            width: 100%;
            text-align: center;
        }
    }

    .init-form#sign-up,
    .init-form#confirm {
        padding-bottom: 4.5rem !important;

        .v-select-lang {
            right: 15px;
            bottom: 15px;
        }
    }

    #account-details,
    #change-password,
    #notification-preferences,
    #biografhy {
        overflow: hidden;

        >.paper {
            background: transparent;
            box-shadow: initial;
            overflow: initial;
        }
    }

    #history .lesson {
        .mobility {
            &.line-right::after {
                background: transparent;
            }
        }
    }

    .card-tutor {
        .card-tags::after {
            background: transparent;
        }

        .btn-prev-step,
        .btn-close-step {
            max-width: initial;
        }

        .title-step {
            font-size: 22px;
        }

        .schedule {
            .calendar {
                padding-left: 1rem;
            }

            .select-hour {
                padding-right: 1rem;
            }
        }
    }

    #tutor-profile {
        .name-tutor {
            font-size: 32px;
        }
    }

    .v-crop-image .modal-crop-image {
        width: 90%;
    }

    //n

    #schedule-lesson {
        .main-title {
            font-size: 26px;
        }

        .switch-title {
            font-size: 18px;
        }

        .arrow {
            max-width: initial;
            padding-top: 15px;
            padding-bottom: 15px;
            pointer-events: none;
            @include cp-property(transform, rotate(90deg));
        }

        .lists-select {
            max-width: 750px;

            .content-list {
                max-width: initial;
            }
        }

        #circles1 {
            bottom: initial;
            top: 0;
            @include cp-property(transform, initial);
        }

        #circles2 {
            @include cp-property(transform, initial);
        }
    }

    .config-video-room {
        .btn-close {
            right: 1rem;
            top: 1rem;
        }

        .config-content {
            max-width: 400px;

            .btn-prev {
                left: 1rem;
                top: 1rem;
            }

            .v-room-video {
                height: 180px;
            }
        }
    }

    #search-results {
        .results {
            .card-tutor {
                min-width: initial;

                .info {
                    max-width: initial;
                }

                .descrip::after {
                    opacity: 0;
                }
            }
        }
    }

    #tutoring-lesson-alert {
        .container-alert {
            .status-msg {
                .msg {
                    font-size: 22px;
                }
            }
        }
    }

    .v-format-question-scale {
        .v-content-question-radio {
            width: initial;
            border: initial;
        }
    }

    .v-questions-result {
        .v-qr-column {
            &:nth-child(1) {
                width: initial;
            }

            &:nth-child(2) {
                width: initial;
            }

            &:last-child {
                width: initial;
            }
        }
    }

    .v-format-question-radio {
        .v-content-question-radio {
            width: 90px;
        }
    }
}

@media only screen and (max-width: 575px) {

    #dashboard .tutorin-static-info {
        .line::after {
            background: transparent;
        }
    }

    #change-password {
        .details form .picture .icon-lock {
            max-width: 150px;
        }

        .details form .picture .icon-locks {
            width: 120px;
            height: 120px;
        }
    }

    .lesson {
        .line-right::after {
            background: transparent;
        }

        .hour,
        .place,
        .tutorial-type,
        .mobility,
        .schedule {
            min-width: initial !important;
        }
    }

    .v-range-time {
        .to {
            max-width: 30px;
        }

        .v-select2 {
            .v-select2-selected-opc {
                height: 32px;

                .v-select2-selected {
                    padding: 5px 20px 5px 7px;
                }

                .v-select2-icon {
                    right: 5px;
                }

                &:after {
                    right: 25px;
                }
            }
        }
    }

    #availability {
        .content-availability {
            .menu-days {
                display: none,
            }

            .content-btn-delete {
                max-width: 25px;
            }
        }

        .v-modal .v-modal-paper.modal-availability {
            padding-left: 1.5rem;
            padding-right: 1.5rem !important;
        }
    }

    #error {
        &.error-404 {
            .txt-error {
                font-size: 60px;
                line-height: 60px;
            }

            .descrip-error {
                font-size: 25px;
            }
        }

        &.error-403 {
            .txt-error {
                font-size: 40px;
                line-height: 40px;
                width: 100%;
            }

            .descrip-error {
                width: 100%;
            }
        }
    }

    .card-tutor {
        .col-img {
            max-width: initial;
        }

        .places-list {
            .radio-place {
                .icon-place {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    #search-results {
        .results {
            .card-tutor {
                .col-img {
                    max-width: initial;
                }
            }
        }
    }

    #tutoring-lesson-alert {
        .container-alert {
            .content-alert {
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }

            .icon-alert {
                margin-bottom: 1rem;
            }

            .status-msg {
                width: 100%;

                .msg {
                    font-size: 20px;
                }
            }
        }

        .lesson-details {
            .paper {
                box-shadow: initial;
            }

            .content-img {
                margin-top: 0;
            }
        }
    }

    .v-schedule-subjects {
        .v-subjects-list {
            .radio-subjects {
                position: relative;

                .l-subject {
                    padding-right: 60px;
                }

                .icon-subject {
                    position: absolute;
                    @include cp-property(transform, translateY(-50%));
                    top: 50%;
                    left: 8px;
                    width: 35px;
                    height: 35px;
                }

                .check-subject {
                    position: absolute;
                    @include cp-property(transform, translateY(-50%));
                    top: 50%;
                    right: 6px;
                }

                .modality-subject {
                    position: absolute;
                    @include cp-property(transform, translateY(-50%));
                    top: 50%;
                    right: 10px;
                }
            }
        }
    }

    .v-schedule {
        .lesson {
            .subject {
                font-size: 16px;
            }
        }
    }

    .v-lesson-summary {
        .v-subject {
            .type-lesson {
                .txt {
                    font-size: 16px;
                }
            }
        }

        .date,
        .time,
        .place {
            .txt {
                font-size: 16px;
            }
        }
    }

    .v-btn-prev {
        button {
            padding-left: 26px;
        }
    }

    #tutor-profile {
        .image-tutor {
            .content-img {
                width: 120px !important;
                height: 120px !important;
            }
        }

        .name-tutor {
            font-size: 22px;
        }

        .subject {
            .btn-schedule {
                max-width: initial;
            }

            .info .icon-subject {
                width: 26px;
            }
        }

        .preview {
            .info-review {
                min-width: initial;

                .name-review {
                    font-size: 16px;
                }
            }

            .score-date {
                max-width: initial;
            }
        }
    }

    #v-room {
        .room-settings {
            .v-content {
                max-width: 350px;

                .v-onboarding-video {
                    height: 140px !important;
                }
            }
        }
    }

    #room {
        .v-room-header {
            .v-actions {

                .v-rduration,
                .v-ruser {
                    @include cp-property(transition, 400ms ease-out);
                }

                &.hide {

                    .v-rduration,
                    .v-ruser {
                        overflow: hidden;
                        width: 0;
                        @include cp-property(transition-timing-function, 400ms ease-in);
                    }
                }
            }
        }

        .v-room-toolbar {
            .btn-session-details {
                line-height: 16px;
                font-size: 14px;
            }

            .toolbar-actions {
                .action-toolbar {
                    width: 34px;
                    height: 34px;

                    .icon-action:not(.i-100) {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }

        .v-session-details {
            left: 10px;
        }

        .toolbar-menu-more {
            right: 10px;
        }

        .room-settings {
            .v-room-video {
                height: 180px !important;
            }

            .v-content {
                .title {
                    font-size: 20px;
                    line-height: 22px;

                    >span {
                        font-size: 20px;
                    }
                }

                .noCamera,
                .noMic {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }

    .v-room-video {
        .v-user {
            padding: .4rem .8rem .4rem 2rem;

            .v-user-name {
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    #get-app .img .qr-code {
        position: relative;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    #formats {
        .f-tooltip {

            .f-title,
            .f-txt {
                font-size: 1rem;
            }
        }
    }

    .v-main-format {

        .mf-title,
        .mf-txt {
            font-size: 1.1rem;
        }

        .mf-menu {
            .v-item-main-format {
                .mf-btn-txt {
                    font-size: 1.15rem;
                }

                .mf-completed-icon {
                    width: 25px;
                }
            }
        }
    }

    .v-format {
        .v-format-title {
            font-size: 1.23rem;
        }

        .v-format-subTitle {
            font-size: 1.1rem;
        }
    }

    .v-format-question-yes-no {
        .v-content-question-radio {
            width: initial;
            border: initial;
        }

        .quality-txt {
            font-size: 1rem;
        }
    }

    .v-questions-result {
        .v-qr-txt {
            font-size: 1.1rem;
        }
    }

    .v-format-question-radio {
        .v-fq-title-txt {
            font-size: 1.05rem;
        }

        .v-question-radio-txt {
            font-size: 1rem;
        }

        .v-content-question-radio {
            width: 70px;
        }
    }

    .v-format {
        .v-format-result {
            font-size: 1rem;
        }
    }
}

@media only screen and (max-width: 500px) {

    #dashboard .d-title,
    #manage .s-title {
        font-size: 17px;
    }

    .card-tutor {
        .title-step {
            font-size: 22px;
        }

        .c-pane {
            min-width: initial !important;
        }

        .schedule {
            .lesson-at {
                .icon {
                    width: 22px;
                }

                .txt {
                    font-size: 18px;
                }
            }
        }

        .lesson-summary {
            .tutor {
                font-size: 20px;
            }

            .lesson-site,
            .date,
            .time,
            .place {
                .icon {
                    width: 20px;
                }

                .txt {
                    font-size: 16px;
                }
            }
        }
    }

    #account-details {
        .details {
            min-width: initial;
        }
    }

    #search-results {
        .results {
            .title-results {
                font-size: 20px;
            }

            .card-tutor .name-tutor {
                font-size: 20px;
            }
        }
    }

    #alert-modal {
        .modal-alert {
            max-width: 350px;

            .btn-accept {
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width: 400px) {

    #header-main {
        .menu {
            .nav-link.btn-help {
                .submenu {
                    left: -100%;
                    right: initial !important;
                }
            }
        }
    }

    .init-form {
        .txt-info {
            font-size: 16px !important;
        }

        .webApp {
            .btn-close {
                top: 0.8rem;
                right: 0.8rem;
            }
        }

        .v-btn {
            height: 40px !important;
        }

        .v-select-lang {
            right: 15px;
            bottom: 15px;
        }

        &#recovery-password {
            .v-btn {
                font-size: 15px !important;
            }
        }
    }

    #dashboard {
        .dashboard-slick {
            height: 180px;

            .item {
                height: 180px;

                .content {
                    .title {
                        font-size: 24px;
                        max-width: 90%;
                    }

                    .descrip {
                        max-width: 90%;
                        font-size: 18px;
                    }
                }
            }
        }
    }

    #schedule-lesson {
        .lists.group {
            .selected-list-item {
                .title {
                    font-size: 20px;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }
        }
    }

    #search-autocomplete {
        .dropdown {
            .dropdown-item {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }

    #calendar {
        .calendar-lessons {
            .content {
                .title {
                    font-size: 18px;
                    padding-left: 35px;

                    .icon {
                        width: 25px;
                    }
                }
            }
        }
    }

    #notification-preferences {
        br {
            display: none;
        }
    }

    #get-app {
        .info {
            .title {
                font-size: 26px;
            }

            .subtitle {
                font-size: 17px;
            }

            .list-benefits {
                dt {
                    font-size: 17px;
                }

                dd {
                    font-size: 14px;
                }
            }
        }
    }

    .config-video-room {
        .config-content {
            .title {
                font-size: 20px;
                line-height: 18px;
            }

            .icon-hearing {
                width: 120px;
            }
        }
    }

    #v-ongoing-lesson {
        .v-modal-lesson {

            .v-img-group,
            .v-img-profile {
                width: 70px;
                height: 70px;
            }

            .v-modal-name {
                @include cp-style(width, calc, unquote('(100% - 86px)'))
            }
        }
    }
}