#subjects{
	> .paper{
		box-shadow: initial;
        overflow: hidden;
	}
	.content-subjects{
		max-width: 680px;
		> .paper{
		    box-shadow: -3px 3px 3px 0 rgba(0,0,0,0.12);
		    border: 1px solid #E2E2E2;
		    z-index: 10;
            min-height: 400px;
	    }
		.title-subjects{
            font-size: 24px;
            padding-left: 40px;
            position: relative;
            .icon{
                width: 25px;
                @extend %vertical-align;
                left: 0;
            }
        }
        .descrip-subjects{
        	padding-left: 40px;
        	font-size: 14px;
        	line-height: 18px;
        	strong{
        		font-weight: 300;
        		color: $color-red-light;
        	}
        }
        .title-list-subject{
        	font-size: 10px;
        	color: $color--text3;
        }
        .ps__rail-y .ps__thumb-y{
            background: $color-green-light;
        }
        .subjects-list{
        	max-height: 360px;
        	z-index: 10;
           
            .subject{
            	&:not(:last-child){
            		border-bottom: 1px solid #e4e4e4;
            	}
            	.color{
                    @extend %vertical-align;
                    left: 0;
            		width: 33px;
            		height: 33px;
            		border-radius: 50%;
            		border: 4px solid rgba(255,255,255,0.4);
            	}
                .duration{
                    font-size: 10px;
                    color: $color--text2;
                    position: relative;padding-left: 30px;
                    font-weight: 500;
                    .icon-mode{
                        @extend %vertical-align;
                        left: 0;
                        width: 20px;
                    }
                }
            }
        }
        #circles3{
			position: absolute;
			top: 15%;
			left: 0;
			z-index: 5;
			@include cp-property(transform, translateX(-50%));
		}
		#circles4{
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 5;
			@include cp-property(transform, translateX(60%));
		}
	}
}

#biografhy{
	> .paper{
		box-shadow: initial;
        overflow: hidden;
	}
    .content-biografhy{
        max-width: 550px;
		> .paper{
		    box-shadow: -3px 3px 3px 0 rgba(0,0,0,0.12);
		    border: 1px solid #E2E2E2;
		    z-index: 10;
	    }
	    .title-biografhy{
            font-size: 24px;
            padding-left: 30px;
            position: relative;
            .icon{
                width: 25px;
                @extend %vertical-align;
                left: 0;
            }
        }
        .descrip-biografhy{
        	padding-left: 30px;
        	font-size: 14px;
        	line-height: 18px;
        	strong{
        		font-weight: 500;
        		font-style: italic;
                cursor: pointer;
        		color: $color-green-light;
        	}
        }
        .content-img{
            max-width: 60px;
        }
        .name{
            font-weight: 300;
            font-size: 24px;
        }
        .biografhy{
            height: 200px;
            border: none;
            padding: 1rem;
            color: $color--text3;
            background: #F9F9F9;
            font-size: 14px;
            resize: none;
            line-height: 18px;
            border-radius: 4px;
            &::-webkit-input-placeholder { /* Edge */
                color: lighten($color--text3, 20%);
            }
            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: lighten($color--text3, 20%);
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: lighten($color--text3, 20%);
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: lighten($color--text3, 20%);
            }
            &::placeholder {
                color: lighten($color--text3, 20%);
            }
            &:focus{
                outline: none;
            }
        }
        .btnA{
            width: 100%;
            max-width: 180px;
        }
        #circles3{
            position: absolute;
            top: 15%;
            left: 0;
            z-index: 5;
            @include cp-property(transform, translateX(-50%));
        }
    }
}

#availability{
    > .paper{
        box-shadow: initial;
    }
    .content-availability{
        max-width: 1000px;
        .content-title{
            max-width: 500px;
        }
        .title-availability{
            font-size: 23px;
            font-weight: 500;
            color: #fff;
        }
        .descrip-availability{
            font-size: 12px;
            color: #fff;
        }
        .icon-clock{
            width: 170px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0; 
            @include cp-property(transform, translateY(20%));
            .icon{
                width: 100%;
                fill: #fff; 
            }
        }
        .toolbar-calendar {
            border-bottom: 1px solid #e5e5e5;
            .btns-calendar-view{
                .nav-item{
                    font-size: 14px;
                    color: #a8b2b9;
                    font-weight: 300;
                    position: relative;
                    cursor: pointer;
                    @include cp-property(transition, 400ms);
                    &::after{
                        content: '';
                        @extend %horizontal-align;
                        bottom: 0;
                        width: 0;
                        height: 2px;
                        background: $color-green-light;
                        @include cp-property(transition, 400ms);
                    }
                    &.active{
                        color: $color-green-main;
                        font-weight: 500;
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
            .btn-prev{
                background: transparent;
                border: none;
                font-size: 14px;
                color: $color--text4;
                @include cp-property(transition, 400ms);
                .icon{
                    width: 15px;
                    stroke: $color-green-light;
                    vertical-align: text-top;
                }
                &:hover{
                   color: $color-green-light;
                }
                &:focus{
                    outline-style: none;
                    outline: none;
                }
            }
        }
        .editBySubject{
            background: transparent;
            border: none;
            font-size: 14px;
            color: $color--text2;
            padding: 0 0 0 25px;
            position: relative;
            @include cp-property(transition, 400ms);
            .icon{
                width: 20px;
                @extend %vertical-align;
                left: 0;
                fill: $color--text2;
                @include cp-property(transition, 400ms);
            }
            &:focus{
                outline: none;
                outline-style:none;
            }
            &:hover{
                color: $color-green-main;
                .icon{
                    fill: $color-green-main;
                }
            }
        }
        .vacation-mode{
            .icon-plane{
                width: 20px;
                position: relative;
                @include cp-property(transform, rotate(-90deg));
                .icon{
                    width: 100%;
                    fill: $color--text2;
                }
            }
            .txt-vacation-mode{
                font-size: 14px;
                color: $color--text2;
            }
        }

        .switch{
            height: 20px;
            width: 40px;
            border: 2px solid #c2c1c1;
            border-radius: 10px;
            position: relative;
            cursor: pointer;
            @include cp-property(transition, 400ms);
            &::before{
                content: '';
                @extend %vertical-align;
                width: 14px;
                height: 14px;
                background: #c2c1c1;
                border-radius: 50%;
                left: 2px;
                @include cp-property(transition, 400ms);
            }
            &.active{
                border-color: $color-green-light;
                &::before{
                    left: 20px;
                    background: $color-green-light;
                }
            }
        }

        .mode-vacation-mode{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            opacity: 0;
            pointer-events: none;
            background: rgba(255,255,255,0.7);
            @include cp-property(transition, 400ms);
            z-index: 50;
            .info-vacation-mode{
                @extend %center-align;
                padding: 3.5rem 3rem;
                border: 3px solid $color-green-main;
                width: 100%;
                background: #fff;
                max-width: 440px;
                max-height: 100%;
                .title{
                    font-size: 25px;
                    font-weight: 500;
                    padding-left: 50px;
                    position: relative;
                    .icon-plane{
                        position: absolute;
                        @include cp-property(transform, translateY(-50%) rotate(-90deg));
                        top: 50%;
                        left: 0;
                        .icon{
                            width: 38px;
                        }
                    }
                }
                .descrip{
                    padding-left: 50px;
                    line-height: 20px;
                }
            }
            &.active{
                pointer-events: all;
                opacity: 1;
            }
        }

        .search{
            position: relative;
            input{
                border: 2px solid #E8E8E8;
                background: #fff;
                height: 34px;
                width: 100%;
                font-size: 14px;
                border-radius: 17px;
                padding-left: 35px;
                padding-right: 15px;
                &:focus{
                    outline-style: none;
                    outline: none;
                }
            }
            .icon-search{
                @extend %vertical-align;
                left: 15px;
                cursor: pointer;
                width: 15px;
                fill: $color--text3;
            }
        }

        .btn-pagination{
            border: none;
            background: transparent;
            padding: 0 .5rem;
            font-size: 12px;
            color: $color--text3;
            @include cp-property(transition, 400ms);
            &:focus{
                outline: none;
                outline-style: none;
                border: none;
            }
            &:hover, &.active{
                color: $color-green-main;
                font-weight: 500;
            }
        }
        


        .vacations-mode {
            padding-left: 50px;
            display: inline-block;
            .v-checkbox{
                @extend %vertical-align;
                left: 30px;
            }
            .txt-check{
                font-size: 14px;
                font-weight: 500;
            }
        }
        > .paper{
            box-shadow: -3px 3px 3px 0 rgba(0,0,0,0.12);
            border: 1px solid #E2E2E2;
            z-index: 20;
            min-height: 320px;
            &[mode="vacation"]{
                box-shadow: initial;
                .menu-days{
                    .btn-day, .btn-day.active{
                        pointer-events: none;
                        background: transparent;
                        border: 1px solid #E2E2E2;
                        width: 90%;
                        height: 32px;
                        .day{
                            color: #E0E0E0;
                            font-weight: 300;
                        }
                    }
                }
            }
        }
        .schedule-by-day{
            &[mode="vacation"]{
                .title-day{
                    color: #E1E1E1;
                }
                .btn-add-block{
                    color: #E0E0E0;
                    pointer-events: none;
                    .icon{
                        fill: #E0E0E0;
                    }
                }
                .btn-delete-availability{
                    fill: #E0E0E0;
                    pointer-events: none;
                }
            }
        }
        .select-availability{
            background: $color-green-light;
            border-radius: 6px 6px 0 0;
            width: 150px;
            margin-left: 10px;
            overflow: hidden;
            position: relative;
            @include cp-property(transition, 400ms);
            select{
                border: none;
                background: transparent;
                width: 100%;
                height: 32px;
                color: #fff;
                padding-left: 1rem;
                padding-right: 30px;
                @include cp-property(transition, 400ms);
                &:focus{
                    outline: none;
                    color: #000;
                }
            }
            .content-icon{
                position: absolute;
                right: 0;
                top: 0;
                width: 30px;
                height: 100%;
                background: $color-green-light;
                pointer-events: none;
                @include cp-property(transition, 400ms);
                .icon{
                    @extend %center-align;
                    width: 18px;
                    fill: #fff;
                    @include cp-property(transition, 400ms);
                }
            }
            &.disabled{
                background: transparent;
                border: 1px solid #E2E2E2;
                pointer-events: none;
                select{
                    color: #E0E0E0;
                }
                .content-icon{
                    background: #F4F7FC;
                    .icon{
                        fill: #E0E0E0;
                    }
                }
            }
        }
    }
}