#manage{

    .s-title{
        //position: relative;
        font-weight: normal;
        font-size: 18px;
        line-height: 20px;
    	color: $color--text2;
    	
    }
    .ongoing-icon{
        height: 20px;
        width: 20px;
        path{
            stroke: #b6e69a;
            stroke-opacity: 0.9;
        }
    }

    .ongoing-lesson{
    	.lesson{
    	    .l-100{
    	    	position: relative;
    			&::after{
    				content: '';
    				position: absolute;
    				top: 0;
    				right: 0;
    				height: 100%;
    				width: 1px;
    				background: $color--border;
    			}
    	    }
            .info{
                .name{
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 120%;
                }
                .matter{
                    font-size: 21px;
                    font-weight: 300;
                    line-height: 120%;
                }
            }
            .seats{
                .text{
                    padding-left: 0;
                    padding-right: 30px;
                    color: $color-green-light;
                    display: block;
                    position: relative;
                    cursor: pointer;
                    @include cp-property(transition, 400ms);
                    .txt1::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        bottom: -2px;
                        background: $color-green-light;
                        @include cp-property(transition, 400ms);
                    }
                    .icon{
                        @extend %vertical-align;
                        right: 0;
                        width: 20px;
                        fill: $color-green-light;
                        @include cp-property(transition, 400ms);
                    }
                    &:hover{
                        color: $color--yellow;
                        .txt1:after{
                            background: $color--yellow;
                        }
                        .icon{
                            fill: $color--yellow;
                        }
                    }
                }
            }
    	    .btnA{
    	    	width: 100%;
    	    	max-width: 185px;
    	    }
    		.content{
    			.name-matter{
    				line-height: 150%;
    				.name{
    					font-size: 26px;
    					font-weight: 300;
    					color: $color--text4;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
    				}
    				.matter{
    					font-size: 18px;
    					font-weight: 500;
    					color: $color--text2;
    		        }
    			}
                &::after{
                    height: 90%;
                }
    		}
            .current-time-r{
                .title-time{
                    font-size: 20px;
                    font-weight: 300;
                }
                .time{
                    font-size: 20px;
                    font-weight: 500;
                }
            }
    		.current-time{
    			@include cp-style(max-width, calc, unquote('(100% - 60px - (125px * 4) - 325px )'));
    			min-width: 210px;
                max-width: 240px;
    			color: $color--text2;
    			.title-time{
    				font-size: 20px;
    				font-weight: 300;
    			}
    			.time{
    				font-size: 51px;
    				font-weight: 500;
    			}
    		}
    	}
    }

}

#history{
    .main-title{
        font-size: 18px;
        color: $color--text2;
    }
    .btn-show{
        background: transparent;
        border: none;
        .icon{
            width: 24px;
            fill: #000;
        }
        &:focus{
            outline: none;
        }
    }
    .stars{
        .vue-star-rating-star{
            margin-right: 2px;
        }
        .custom-text{
            font-size: 20px;
            font-weight: 500;
            padding-left: 8px;
            margin: 0;
        }
    }
    .lesson-detail{
        .h-start, .h-end, .h-duration{
            padding-left: 26px;
            font-size: 14px;
            position: relative;
            b{
                font-weight: 500;
            }
            .icon{
                @extend %vertical-align;
                left: 0;
                width: 20px;
            }
        }
        .btn-chat{
            width: 100%;
            max-width: 170px;
        }
        .studentFeedback, .myFeedback{
            .title{
                padding-left: 30px;
                font-weight: 500;
                position: relative;
                .icon{
                    @extend %vertical-align;
                    left: 0;
                    width: 20px;
                }
            }
            .feedback{
                font-size: 14px;
                line-height: 18px;
            }
            .btn-feedback{
                border: none;
                font-size: 14px;
                background: transparent;
                cursor: pointer;
                color: $color-blue-main;
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background: $color-blue-main;
                }
                &:focus{
                    outline: none;
                }
            }
        }
    }
}

#calendar{
    .calendar-lessons{
        background: #fff;
        border-radius: 4px;
        .content{
            max-width: 720px;
            position: relative;
            z-index: 10;
            .title{
                font-size: 24px;
                padding-left: 40px;
                position: relative;
                .icon{
                    width: 30px;
                    @extend %vertical-align;
                    left: 0;
                }
            }
            .text{
                font-size: 14px;
                strong{
                    font-weight: 500;
                }
                &.b-line{
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 1px;
                        width: 40px;
                        background: $color--text;
                    }
                }
            }
            .title-url{
                color: $color-blue-main;
                font-size: 14px;
                font-weight: 500;
            }
            .content-url{
                input[type="link"]{
                    height: 42px;
                    border: 1px solid $color--text;
                    border-radius: 25px;
                    padding-left: 30px;
                    padding-right: 80px;
                    font-size: 14px;
                    background: white;
                    width: 100%;
                    position: relative;
                    &:focus{
                        outline: none;
                    }
                }
                .btn-copy{
                    font-size: 14px;
                    font-weight: 500;
                    @extend %vertical-align;
                    right: 15px;
                    border: none;
                    background: transparent;
                    color: $color-blue-main;
                    cursor: pointer;
                    &:focus{
                        outline: none;
                    }
                }
                .text-enabled{
                    font-size: 12px;
                    strong{
                        font-weight: 500;
                    }
                }
                .btn-disable,.btn-enabled-t,.btn-enabled-s{
                    border: none;
                    background: transparent;
                    font-size: 12px;
                    padding-bottom: 2px;
                    position: relative;
                    &:focus{
                        outline: none;
                    }
                    &::after{
                        content: '';
                        @extend %horizontal-align;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        @include cp-property(transition, 400ms);
                    }
                    &:hover::after{
                        bottom: 3px;
                        width: 90%;
                    }
                }
                .btn-disable{
                    color: $color-red-dark;
                    &:after{
                        background: $color-red-dark;
                    }
                }
                .btn-enabled-s{
                    color: $color-blue-main;
                    &:after{
                        background: $color-blue-main;
                    }
                }
                .btn-enabled-t{
                    color: $color-green-main;
                    &:after{
                        background: $color-green-main;
                    }
                }
            }
            .title-calendars{
                font-size: 14px;
                font-weight: 500;
            }
            .link-calendar{
                &.disabled{
                    pointer-events: none;
                    opacity: 0.2;
                }
            }
            .content-calendars{
                .btn-calendar{
                    &:nth-child(-n+2){
                        border-bottom: 1px solid $color--border;
                    }
                    img{
                        max-width: 100px;
                        max-height: 35px;
                        cursor: pointer;
                        @include cp-property(transition, 300ms);
                    }
                }
                &.disabled{
                    pointer-events: none;
                    img{
                        opacity: 0.2;
                    }
                }
            }
        }
    }
    .trello-left{
        position: absolute;
        left: 5%;
        bottom: 10%;
        width: 20%;
        min-width: 180px;
        max-width: 250px;
        z-index: 5;
    }
    .trello-right{
        position: absolute;
        right:5%;;
        bottom: 10%;
        width: 20%;
        min-width: 180px;
        max-width: 250px;
        z-index: 5;
    }
}