#schedule-lesson{
	.main-title{
		font-weight: 400;
		font-size: 30px;
		color: $color--text2;
	}
	#search-autocomplete{
		max-width: 820px;
	}
	.selected-list-item{
		position: relative;
		z-index: 10;
	}
	.switch-title{
		font-weight: 300;
		font-size: 20px;
        color: $color--text2;
	}
	.lists-select{
        max-width: 750px;
        .content-list{
        	@include cp-style(max-width, calc, unquote('( 50% - 35px )'));
        }
    }
}