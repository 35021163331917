// Full calendar
.v-full-calendar{
    min-height: 140px;
    .bgColor{
        background: #fff;
        z-index: 10;
        > .content-spinner{
            @extend %center-align;
        }
    }

    td.fc-day.fc-day-today{ // Current day
        background: transparent;
    }
    .fc-theme-standard td, .fc-theme-standard th{ // Border color
        border-color: #E6E5E6;
    }
    .fc .fc-daygrid-body-natural .fc-daygrid-day-events{
        margin-bottom: .5rem;
    }
    .fc-theme-standard td{ // tbody text
        .fc-daygrid-day-number{
            color: #AFAFAF;
            font-weight: 300;
        }  
    }
    .fc-theme-standard th{ // thead text
        .fc-col-header-cell-cushion{
            padding-top: 8px;
            padding-bottom: 8px;
            color: #AFAFAF;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 14px;
        }
    }
    .fc-daygrid-day{ // Day number
        .fc-daygrid-day-top{
            height: 32px;
            align-items: center;
        }
        .fc-daygrid-day-number{
            font-size: 15px;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            text-align: center;
            padding: 1px;
            right: 10px;
        }
        &.fc-day-today .fc-daygrid-day-number{
            color: #fff;
            font-weight: 500;
            background: $color-green-light;
        }
    }
    .fc-daygrid-day-events{
        pointer-events: none;
    }
    .fc .fc-daygrid-day-frame{
        height: 135px;
    }

    .full-calendar{
        .fc-toolbar{
            margin-bottom: 3rem;
        }
        .fc-toolbar-chunk{
            display: flex;
        }
        .fc-toolbar-title{ // title
            font-size: 22px;
            margin-right: 15px;
        }
        .fc-col-header-cell{
            &.fc-day-today{
                background: $color-green-light;
                .fc-col-header-cell-cushion {
                    color: #fff;
                }
            }
        }
        .fc-button{ // prev and next btns
            background: transparent;
            border-color: #CFD5DA;
            margin-left: 0;
            width: 30px;
            height: 25px;
            padding: 0;
            position: relative;
            @include cp-property(transition, 400ms);
            &.fc-today-button{
                width: initial;
                color: #9CA4A9;
                padding-left: .4rem;
                padding-right: .4rem;
                margin-left: .1rem;
                margin-right: .1rem;
                font-size: 15px;
            }
            .fc-icon{
                color: #9CA4A9;
                font-size: 1.2rem;
                @extend %center-align;
                @include cp-property(transition, 400ms);
            }
            &:not([disabled]):hover{
                border-color: $color-green-main;
                background: $color-green-main;
                color: #fff;
                .fc-icon{
                    color: #fff;
                }
            }
            &[disabled]{
                opacity: 0.7;
            }
            &:focus{
                outline: none;
                outline-style:none;
                box-shadow: initial;
            }
        }
         .fc-day{
            box-shadow: inset 0 0 0 3px transparent;
            @include cp-property(transition, 300ms);
            &.fc-day-past{ // Past days
                @include cp-style(background, linear-gradient, unquote('(45deg, #EFEFEF 10%, #fff 10%, #fff 50%, #EFEFEF 50%, #EFEFEF 60%, #fff 60%, #fff 100%)'));
                background-size: 14px 14px;
            }
            &.fc-day-future.fc-day-other{ // Days next month
                background: #F7F7F7;
            }
            .fc-day-other{
                opacity: 1;
            }
            &:not(.fc-day-past):not(.fc-day-other):not(.fc-col-header-cell):hover{ // Hover
                cursor: pointer;
                box-shadow: inset 0 0 0 3px $color-green-light;
            }
        }

        .fc-dayGridWeek-view .sch-time.sch-lt{
            margin-top: 25px;
        }

        .sch-time{
            margin: 0 8px;
            border-radius: 0;
            padding: 0 4px;
            &:not(.sch-lt){
                border-top: none;
            }
            &:not(.sch-lb){
                border-bottom: none;
            }
            &.sch-lt{
                border-radius: 4px 4px 0 0;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 100%;
                    width: 10px;
                    height: 10px;
                    background: $color-green-light;
                    border-radius: 50%;
                    box-shadow: inset 0 0 0 2px rgba(255,255,255,0.6);
                    @include cp-property(transform, translateY(-10px));
                }
                .fc-event-title{
                    padding-top: 3px;
                }
            }
            &.sch-lb{
                border-radius: 0 0 4px 4px;
                padding-bottom: 2px;
                .fc-event-title{
                    padding-bottom: 3px;
                }
            }
            .fc-daygrid-event-dot, .fc-event-time{
                display: none;
            }
            .fc-event-title{
                font-weight: 300;
                font-size: 10px;
                color: $color--text2;
            }
            &.sch-general{
                border-left: 2px solid #E6E5E6;
                border-right: 2px solid #E6E5E6;
                &.sch-lt{
                    border-top: 2px solid #E6E5E6;
                    .fc-event-title{
                        &::after{
                            content: '';
                            position: absolute;
                            right: 20%;
                            top: 0;
                            width: 14px;
                            background: #fff;
                            transform: translateY(-3.5px);
                            border-left: 6px solid #E6E5E6;
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                        }
                    }
                }
                &.sch-lb{
                    border-bottom: 2px solid #E6E5E6;
                    .fc-event-title{
                        &::before{
                            content: '';
                            position: absolute;
                            left: 20%;
                            bottom: 0;
                            width: 14px;
                            background: #fff;
                            transform: translateY(3.5px);
                            border-right: 6px solid #E6E5E6;
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                        }
                    }
                }
            }
        }

        .fc-daygrid-day-bottom{
            text-align: center;
            font-size: 12px;
            font-weight: 500;
        }
    }
}

// Aply to multiple
.applyToMultiple{
    .l-borrom{
        border-bottom: 1px solid #c6c6c6;
    }
    .c-title{
        text-transform: capitalize;
    }
    .am-radioButton{
        .radio-check{
            width: 16px;
            height: 16px;
            cursor: pointer;
            box-shadow: inset 0 0 0 1px #c6c6c6;
            border-radius: 50%;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                pointer-events: none;
                border-radius: 50%;
            }
            &.active::after{
                box-shadow: inset 0 0 0 5px $color-green-light;
            }
        }
        .txt-radio{
            font-size: 14px;
            color: $color--text2;
        }
    }

    .c-day-background{
        background: $color-green-main !important;
    }
    /*.c-day-content[style="color: rgb(217, 140, 140); font-weight: 600; opacity: 0.6; border-radius: 0px;"]{
        border-radius: 50%;
        background: $color--gray;
    }*/

    .checkbox-day{
        position: relative;
        .am-checkbox{
            height: 16px;
            width: 16px;
            border: 1px solid #979797;
            border-radius: 2px;
            cursor: pointer;
            position: relative;
            .icon-check{
                @extend %center-align;
                width: 80%;
                fill: #979797;
                stroke: #979797;
                pointer-events: none;
                opacity: 0;
            }
            input[type="checkbox"]{
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                cursor: pointer;
            }
            &.check{
                pointer-events: none;
                .icon-check{
                    opacity: 1;
                }
            }
            &:not(.check).active{
                border-color: $color-green-light;
                .icon-check{
                    opacity: 1;
                    stroke: $color-green-light;
                    fill: $color-green-light;
                }
            }
        }
        &.disabled{
            pointer-events: none;
            opacity: 0.7;
        }
        .text-checkbox{
            font-size: 14px;
            color: $color--text2;
        }
    }
    .v-calendar{
        &.disabled{
            pointer-events: none;
            opacity: 0.7;
            .c-day-content-wrapper{
                pointer-events: none;
            }
        }
    }
    .days-selected{
        font-size: 14px;
        font-weight: 300;
        color: #9B9B9B;
    }
    .btn-clear{
        background: transparent;
        border: none;
        color: $color-green-main;
        font-size: 14px;
        font-weight: 300;
        &:focus{
            outline: none;
            outline-style: none;
        }
    }
}

// Schedule by day
.schedule-by-day{
    .t-time{
        font-size: 14px;
        color: $color--text2;
    }
    .txt-check{
        font-size: 14px;
        font-weight: 500;
        color: $color--text2;
    }
    .btn-add-block{
        border: none;
        background: transparent;
        font-size: 12px;
        font-weight: 500;
        color: $color-green-light;
        padding-left: 22px;
        padding-right: 0;
        height: 30px;
        position: relative;
        cursor: pointer;
        @include cp-property(transition, 400ms);
        .icon{
            @extend %vertical-align;
            left: 0;
            fill: $color-green-light;
            width: 18px;
            @include cp-property(transition, 400ms);
        }
        &.disabled{
            pointer-events: none;
            opacity: 0.7;
        }
        &:focus{
            outline: none;
        }
        &:hover{
            color: $color-green-main;
            .icon{
                fill: $color-green-main;
            }
        }
    }
    .imUnavailable{
        height: 40px;
        border: 1px dotted #D0D0D0;
        span{
            font-size: 14px;
            color: #D0D0D0;
        }
    }
    .content-btn-delete{
        @extend %vertical-align;
        left: 100%;
        width: 18px;
        .btn-delete-availability{
            width: 100%;
            fill: $color-red-light;
            cursor: pointer;
            path{
                fill: #fff;
            }
            &.disabled{
                pointer-events: none;
                opacity: 0.7;
            }
        }
    }
}

// Range time
.v-range-time{
    .v-select2{
        position: relative;
        .v-select2-selected-opc{
            border-radius: 2px;
            border: 1px solid #000;
            height: 35px;
            .v-select2-selected{
                color: #333;
                font-size: 14px;
                border: none;
                height: 100%;
                background: transparent;
                padding: 5px 30px 5px 15px;
                cursor: pointer;
                &:focus{
                    outline: none;
                }
            }
             .v-select2-icon{
                @extend %vertical-align;
                pointer-events: none;
                right: 7px;
                width: 16px;
            }
        }
        .v-select2-options{
            position: fixed;
            top: initial;
            left: initial;
            //top: calc(100% - 1px);
            //left: 0;
            z-index: 50;
            padding: 5px 0;
            margin: 0;
            min-width: 140px;
            height: 200px;
            width: 140px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
            border: 1px solid rgba(60, 60, 60, 0.26);
            border-top-style: none;
            border-radius: 0 0 4px 4px;
            text-align: left;
            list-style: none;
            background: #fff;
            .v-select2-opc{
                line-height: 1.42857143;
                color: #333;
                white-space: nowrap;
                display: block;
                padding: 3px 20px;
                cursor: pointer;
                position: relative;
                &:hover{
                    color: #fff;
                    background: $color-green-light;
                }
                &.disabled{
                    pointer-events: none;
                    opacity: 0.6;
                }
                &.selected{
                    color: $color-blue-dark;
                    &:hover{
                        color: #fff;
                    }
                }
            }
        }

    }
    &.disabled{
        .v-select2{
            pointer-events: none;
            .v-select2-selected-opc{
                border: 1px solid #E2E2E2;
                .v-select2-selected{
                    color: #E0E0E0;
                }
                .v-select2-icon{
                    fill: #E0E0E0;
                }
            }
        }
        .to .txt-to{
            color: #E0E0E0; 
        }
    }
    .to{
        max-width: 50px;
        @include cp-property(transition, 400ms);
    }
}

// Assigned subjects
.assigned-subjects{
    .title{
        font-size: 20px;
        color: $color--text2;
    }
    .str{
        font-size: 12px;
        line-height: 14px;
        color: $color--text3;
        padding-left: 20px;
        position: relative;
        display: block;
        &::before{
            content: '';
            @extend %vertical-align;
            left: 0;
            width: 14px;
            height: 14px;
        }
        &[color="pink"]::before{
            background: #FC8190;
        }
        &[color="green"]::before{
            background: #58C2CB;
        }
    }
    .as-btn, .as-select{
        min-height: 40px;
        height: 100%;
        background: #F3F9FF;
        @include cp-property(transition, 400ms);
        cursor: pointer;
        .content-as-txt{
            font-size: 14px;
            line-height: 16px;
        }
        .as-txt{
            font-size: 14px;
            line-height: 16px;
            color: $color--text2;
            &[txtColor='purple']{
                color: #B14997;
            }
            &[txtColor='green']{
                color: $color-green-light;
            }
            &[txtColor='blue']{
                color: $color-blue-main;
            }
            &[txtColor='yellow']{
                color: $color--yellow;
            }
        }
        .icon{
            fill: #4A4A4A;
            width: 14px;
        }
        &:hover, &.active{
            box-shadow: -2px 2px 4px 0 rgba(161,193,224,0.5);
        }
    }

    .as-btn{
        .az-txt{
            font-size: 10px;
            color: $color--text2;
        }
    }

    .as-select{
        position: relative;
        input{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 10;
            cursor: pointer;
            &:focus{
                outline-style: none;
                outline: none;
                border: none;
            }
        }
        .opc-select{
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            min-width: 200px;
            background: #fff;
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.21);
            border-radius: 4px;
            @include cp-property(transition, 400ms);
            opacity: 0;
            z-index: 15;
            .item-select{
                padding: 12px 15px 12px 40px;
                position: relative;
                .i-txt{
                    font-size: 14px;
                    font-weight: 300;
                    color: $color--text2;
                }
                &::before{
                    content: '';
                    @extend %vertical-align;
                    left: 15px;
                    width: 12px;
                    height: 12px;
                    border-radius: 7px;
                }
                &:not(:last-child)::after{
                    content: '';
                    @extend %horizontal-align;
                    bottom: 0;
                    width: 90%;
                    height: 1px;
                    background: #E5E5E5;
                }
                &[pColor='gray']::before{
                    background: #9B9B9B;
                }
                &[pColor='purple']::before{
                    background: #B14997;
                }
                &[pColor='green']::before{
                    background: $color-green-light;
                }
                &[pColor='blue']::before{
                    background: $color-blue-main;
                }
                &[pColor='yellow']::before{
                    background: $color--yellow;
                }
                .as-checkbox{
                     height: 16px;
                     width: 16px;
                     border: 1px solid #979797;
                     border-radius: 2px;
                     cursor: pointer;
                     position: relative;
                     .icon-check{
                        @extend %center-align;
                        width: 80%;
                        fill: #979797;
                        stroke: #979797;
                        pointer-events: none;
                        opacity: 0;
                    }
                    &.active{
                        border-color: $color-green-light;
                        .icon-check{
                            opacity: 1;
                            stroke: $color-green-light;
                            fill: $color-green-light;
                        }
                    }
                }
            }
            &.active{
                opacity: 1;
                @include cp-property(transform, translateY(6px));
            }
        }
    }

    .availability-subjects{
        .av-subject{
            @include cp-property(transition, 400ms);
            cursor: pointer;
            .nameAndCategory{
                position: relative;
                padding-left: 50px;
                .color{
                    @extend %vertical-align;
                    left: 15px;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    border: 4px solid rgba(255,255,255,0.4);
                }
            }
            .txt-info{
                font-size: 12px;
                font-weight: 400;
                display: block;
                position: relative;
            }
            .bgsrt{
                width: 14px;
                height: 14px;
                &[color="pink"]{
                    background: #FC8190;
                }
                &[color="green"]{
                    background: #58C2CB;
                }
            }
            .btn-customize{
                display: none;
            }
            &:hover{
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), inset 0 0 0 2px $color-green-light;
                .bgsrt{
                    display: none;
                }
                .btn-customize{
                    display: block;
                }
            }
        }
    }
}

.customize-subject{
    .cs-info{
        padding-left: 45px;
        position: relative;
        .color{
            @extend %vertical-align;
            left: 0;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 4px solid rgba(255,255,255,0.4);
        }
    }
    .subject-info{
        font-size: 14px;
        font-weight: 300;
        color: $color--text3;
        position: relative;
        .txt-bold{
            color: $color--text2;
            font-weight: 400;
        }
    }

    .specific-scheduling, .specific-places{
        position: relative;
        .title{
            font-size: 16px;
            font-weight: 500;
        }
        &::before{
            content: '';
            @extend %vertical-align;
            left: 10px;
            height: 70%;
            width: 4px;
        }
        .btn_{
            border: none;
            background: transparent;
            font-size: 12px;
            color: #424242;
            position: relative;
            padding: 0;
            &::after{
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background: #424242;
            }
            &:focus{
                outline-style: none;
                outline: none;
                border: none;
            }
            &.disabled{
                opacity: 0.6;
                pointer-events: none;
            }
        }
    }

    .specific-scheduling{
        background: rgba(252,129,144,0.05);
        &::before{
            background: #FC8190;
        }
    }

    .specific-places{
        background: rgba(88,194,203,0.05);
        &::before{
            background: #58C2CB;
        }
        .search{
            max-width: 280px;
        }
    }

    .schedule-slick{
        .item{
            &.disabled{
                opacity: 0.5;
                pointer-events: none;
            }
            .sch-day {
                box-shadow: 0 1px 4px rgba(0,0,0,0.15), inset 0 0 0 2px transparent;
                background: #fff;
                border-radius: 2px;
                cursor: pointer;
                @include cp-property(transition, 400ms);
                min-height: 135px;
            }
            &:not(.disabled):hover .sch-day{
                box-shadow: 0 1px 4px rgba(0,0,0,0.15), inset 0 0 0 2px $color-green-light;
            }
            .sch-title{
                font-size: 12px;
                font-weight: 500;
            }
            ul{
                list-style: none;
                li{
                    font-size: 10px;
                    font-weight: 300;
                }
            }
            .btn-more{
                background: transparent;
                border: none;
                color: $color--text2;
                font-size: 10px;
                font-weight: 500;
                &:focus{
                    outline: none;
                    outline-style: none;
                    border: none;
                }
            }
        }
    }

    .list-places{
        .btn-firsts, .btn-az{
            background: transparent;
            border: none;
            color: $color--text2;
            font-size: 12px;
            padding: 0;
            position: relative;
            &:focus{
                outline: none;
                outline-style: none;
                border: none;
            }
        }
        .btn-firsts, .btn-az{
            font-weight: 500;
            &::after{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px; 
                width: 100%;
                background: $color-green-light;
                opacity: 0;
                @include cp-property(transition, 400ms);
            }
            &.active::after{
                opacity: 1;
            }
        }
        .btn-az{
            padding-right: 16px; 
            .icon{
                @extend %vertical-align;
                right: 0;
                width: 12px;
            }
        }
        .place_{
            box-shadow: 0 1px 4px rgba(0,0,0,0.15);
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            padding: .8rem 2rem .8rem 2.5rem;
            cursor: pointer;
            .place-icon{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-shadow: inset 0 0 0 3px rgba(255,255,255,0.4);
                @extend %vertical-align;
                left: 0.8rem;
                .icon{
                    @extend %center-align;
                    fill: #fff;
                    width: 50%;
                }
            }
            .txt-place{
                font-size: 14px;
                font-weight: 500;
            }
            &.disabled{
                pointer-events: none;
                .check{
                    opacity: 0.5;
                }
            }
        }
        .check{
            width: 15px;
            height: 15px;
            @extend %vertical-align;
            right: 1rem;
            border-radius: 8px;
            border: 1px solid #979797;
            input[type="checkbox"]{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                opacity: 0;
                pointer-events: none;
            }
            &.active{
                border-color: $color-green-light;
                background: $color-green-light;
            }
        }
    }
}