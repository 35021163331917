.v-subjects-t{
    z-index: 30;
    #search-autocomplete{
        .search{
            input{
                padding-left: 20px;
            }
            .icon-arrow-down{
                color: #9E9E9E
            }
        }
    }
}

.v-date-time-t{
    .v-field{
        border: 1px solid #9E9E9E;
        border-radius: 20px;
        overflow: hidden;
        .v-content-field{
            
            &::before, &::after{
                width: 0;
            }
        }
        .field_{
            height: 40px;
            padding-left: 20px;
            padding-right: 40px;
            font-size: 14px;
            &::-webkit-input-placeholder {
                color: #6E6E6E;
                font-weight: 400;
                font-size: 14px;
            }
            &::-moz-placeholder {
                color: #6E6E6E;
                font-weight: 400;
                font-size: 14px;
            }
            &:-ms-input-placeholder {
                color: #6E6E6E;
                font-weight: 400;
                font-size: 14px;
            }
            &::placeholder {
                color: #6E6E6E;
                font-weight: 400;
                font-size: 14px;
            }
        }
        .content-icon{
            @include cp-property(transform, translateY(-50%));
            top: 50%;
            width: 20px;
            height: 20px;
            right: 15px;
            fill: #9E9E9E;
        }
        .mx-icon-calendar, .mx-icon-clear{
            right: 15px;
        }
    }
}

.v-students-t{
    z-index: 20;
    .v-field{
        border: 1px solid #9E9E9E;
        border-radius: 15px;
        overflow: hidden;
        .v-content-field{
            &::before, &::after{
                width: 0;
            }
        }
        .field_{
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    #search-autocomplete{
        &.disabled{
            pointer-events: none;
            opacity: 0.6;
        }
        .dropdown{
            .msg-search{
                color: '#4A4A4A' !important;
                max-width: 400px;
                strong{
                    font-weight: 500;
                }
            }
            .dropdown-item{
                &.disabled{
                    pointer-events: none;
                    opacity: 0.6;
                }
            }
        }
    }

    .v-field{
        max-width: 217px;
    }

    .v-students-list-t{
        .v-student-card-unconfirmed{
            position: absolute;
            left: -4px;
            top: -4px;
            background: #d8d8d8;
            padding: 2px 4px;
            font-size: 10px;
            font-weight: 500;
            font-weight: 14px;
            border-radius: 2px;
            z-index: 10;
    
        }
        .v-student-card{
            background: #fff;
            box-shadow: 0 2px 6px rgba(0,0,0, 0.2);
            border-radius: 4px;
            .v-student-card-content{
                height: 50px;
                padding-left: 55px;
                .v-lesson-photo{
                    position: absolute !important;
                    @include cp-property(transform, translateY(-50%));
                    top: 50%;
                    left: 0;
                }
            }
        }
        .v-student-card-txt{
            font-size: 14px;
            font-weight: 400;
        }
        .v-student-card-btn-delete{
            @extend %removeBtnStyles;
            width: 15px;
            height: 15px;
            position: absolute;
            right: 6px;
            top: 6px;
            .v-student-card-btn-icon{
                @extend %center-align;
                width: 100%;
                fill: #000;
                @include cp-property(transition, 300ms);
            }
            &:hover .v-student-card-btn-icon{
                fill: $color-red-dark;
            }
        }
    }
}

.v-places-t{
    z-index: 10;
    #search-autocomplete{
        &.disabled{
            pointer-events: none;
            opacity: 0.6;
        }
        .search{
            input{
                padding-left: 20px;
            }
            .icon-arrow-down{
                color: #9E9E9E
            }
        }
        .dropdown{
            .dropdown-item{
                padding-left: 2rem;
                font-size: 14px;
                line-height: 17px;
                color: #000;
                min-height: 40px;
                .dropdown-place-icon{
                   @extend %vertical-align;
                   left: 7px;
                   width: 20px;
                   height: 20px;
                   fill: #000;
                }
            }
        }
    }
}