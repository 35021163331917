.v-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    pointer-events: none;
    .v-blurColor{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.0);
        @include cp-property(transition, opacity 400ms ease-out);
        cursor: pointer;
        z-index: 5;
    }
    &.active{
        pointer-events: all;
        .v-modal-paper{
            opacity: 1;
        }
        .v-blurColor{
            background: rgba(0,0,0,0.6);
        }
    }
    .v-modal-paper{
        @extend %center-align;
        max-width: 380px;
        width: 90%;
        border-radius: 8px;
        background: #fff;
        z-index: 10;
        overflow: hidden;
        opacity: 0;
        @include cp-property(transition, opacity 400ms ease-out);
        .v-modal-title{  	
            position: relative;
            padding: 1.5rem 2.5rem 1.2rem 2.5rem;
            .v-t{
            	color: #fff;
            	font-size: 28px;
            	line-height: 30px;
                font-weight: 300;
            }
            &[bgColor="green-main"]{
            	background: $color-green-dark;
            }
            &[bgColor="yellow-main"]{
            	background: $color--yellow;
            }
            .v-btn-close{
                @extend %vertical-align;
                right: 20px;
                width: 22px;
                fill: #fff;
                cursor: pointer;
            }
        }
        .v-modal-btn{
        	height: 44px;
            font-size: 20px;
        }
        &.modal-vacation-mode{
            .title{
                font-size: 34px;
                font-weight: 500;
            }
            .icon-plane{
                width: 90px;
                @include cp-property(transform, rotate(-90deg));
                .icon{
                    width: 100%;
                }
            }
            .descrip{
                font-size: 14px;
                color: $color--text2;
            }
        }
        &.modal-place{
            .title{
                font-size: 24px;
                font-weight: 500;
            }
            .icon-warning{
                width: 90px;
            }
            .descrip{
                font-size: 14px;
                color: $color--text2;
            }
        }

        &.modal-availability{
            max-width: 450px;
            max-height: 90%;
            padding: 3rem 3.5rem 3rem 4.5rem;
            padding-top: 3rem;
            padding-bottom: 3rem;
            padding-left: 4.5rem;
            .checkbox-specific-day{
                padding: 2px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid $color-green-light;
                color: $color-green-light;
                position: relative;
                cursor: pointer;
                @include cp-property(transition, 400ms);
                .txt-day{
                    @extend %center-align;
                    font-size: 12px;
                    font-weight: 400;
                    z-index: 5;
                }
                input[type="checkbox"]{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: 10;
                    opacity: 0;
                    cursor: pointer;
                }
                &.check{
                    color: #fff;
                    cursor: initial;
                    background: $color--gray;
                    border-color: $color--gray;
                }
                &.active:not(.check){
                    background: $color-green-main;
                    border-color: $color-green-main;
                    color: #fff;
                }
                &.disabled{
                    pointer-events: none;
                    opacity: 0.5;
                }
                &:not(.check):not(.active):hover{
                    border-color: $color-green-main;
                    color: $color-green-main;
                } 
            }
            .msg-changes{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 50;
                background: rgba(255,255,255,0.8);
                .content-cgh{
                    width: 90%;
                    margin-left: 5%;
                    margin-right: 5%;
                    border: 2px solid $color-green-main;
                    border-radius: 4px;
                    background: #fff;
                }
                .cgh-title{
                    font-size: 24px;
                    font-weight: 500;
                }
                .cgh-msg{
                    width: 80%;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
            .btn-prev{
                position: absolute;
                left: 0;
                top: 0;
                pointer-events: none;
                overflow: hidden;
                opacity: 0;
                z-index: 5;
                .icon-prev{
                    cursor: pointer;
                    stroke: $color-green-light;
                    width: 18px;
                }
                &.active{
                    opacity: 1;
                    pointer-events: all;
                    width: 40px;
                }
            }
            .title{
                position: relative;
                font-size: 24px;
                font-weight: 500;
            }
            .subtitle{
                font-size: 14px;
                font-weight: 300;
            }
            .c-title-layout{
                width: 130px;
                flex-grow: 0;
            }
            .c-week:last-child{
                display: none;
            }
        }
    }


    .img-feedback-group{
    	height: 115px;
    	width: 115px;
    	border-radius: 50%;
    	background: $color--yellow;
    	position: relative;
    	> .icon{
    		@extend %center-align;
    		fill: #fff;
    		width: 70%;
    	}
    }
    .v-feedback-name{
    	font-size: 22px;
    	font-weight: 300;
    	color: $color--text2;
    	line-height: 100%;
    }
    .v-feedback-subject{
    	font-size: 15.5px;
        line-height: 18px;
    	font-weight: 500;
    	color: $color--text2;
    }
    .v-feedback-duration{
    	font-size: 13px;
    	font-weight: 300;
    	color: $color--text2;
    	.duration{
    		font-weight: 500;
    	}
    }
    .txt-review{
    	font-size: 14px;
    	font-weight: 300;
    	color: $color--text2;
    }
    #feedback-review{
    	background: #f5f5f5;
        border-radius: 4px;
    	border: none;
    	padding: 1rem;
    	font-size: 14px;
    	height: 115px;
        resize: none;
        &.wrg{
            border: 1px solid $color-red-light;
        }
    	&:focus{
    		outline: none;
    	}
    }
    .vue-star-rating-pointer{
    	margin-left: 5px;
        margin-right: 5px;
    }

    .list-students{
            max-height: 450px;
            .student{
                border-bottom: 0.65px solid #DEDEDE;
                position: relative;
                .student-info{
                    @include cp-style(max-width, calc, unquote('(100% - 48px)'));
                    .name-and-subject{
                        @include cp-style(max-width, calc, unquote('(100% - 80px)'));
                    }
                    &::after{
                        content: '';
                        @extend %vertical-align;
                        right: -1.5rem;
                        width: 14px;
                        height: 14px;
                        border-radius: 50%;
                        border: 0.65px solid #979797;
                    }
                }
                .name{
                    font-size: 12px;
                    font-weight: 300;
                    color: #4e4e4e;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow: ellipsis;
                }
                .subject{
                    font-size: 10.5px;
                    font-weight: 300;
                    color: #adadad;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow: ellipsis;
                }
                .comment{
                	padding-left: 48px;
                    .txt-cloud{
                        p{
                            font-size: 11px;
                            line-height: 13px;
                        }
                    }
                }
                .rating{
                    max-width: 80px;
                    .custom-text{
                        font-size: 16px;
                        font-weight: 500;
                        margin-left: 2px;
                        margin-top: 3px;
                    }
                }
                
                &.active .student-info::after{
                    border: none;
                    background: $color-green-light;
                }
            }
        }
}

.v-joined-students{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    pointer-events: none;
    
    .blurColor{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.0);
        @include cp-property(transition, opacity 400ms ease-out);
        cursor: pointer;
        z-index: 5;
    }
    &.active{
        pointer-events: all;
        .v-joined-students-modal{
            opacity: 1;
        }
        .blurColor{
            background: rgba(0,0,0,0.6);
        }
    }
    .v-joined-students-modal{
        @extend %center-align;
        max-width: 380px;
        width: 90%;
        border-radius: 8px;
        background: #fff;
        z-index: 10;
        overflow: hidden;
        opacity: 0;
        @include cp-property(transition, opacity 400ms ease-out);
        .title{
            background: $color--yellow;
            color: #fff;
            line-height: 30px;
            font-size: 28px;
            font-weight: 300;
            position: relative;
            .btn-close{
                @extend %vertical-align;
                right: 20px;
                width: 22px;
                fill: #fff;
                cursor: pointer;
            }
            > span{
                color: #fff;
                font-size: 37px;
                font-weight: 300;
            }
        }
        .list-students{
            max-height: 350px;
            .student{
                border-bottom: 0.65px solid #DEDEDE;
                position: relative;
                .name{
                    font-size: 12px;
                    font-weight: 300;
                    color: #4e4e4e;
                }
                .subject{
                    font-size: 10.5px;
                    font-weight: 300;
                    color: #adadad;
                }
                &.v-check{
                    cursor: pointer;
                    &::after{
                        content: '';
                        @extend %vertical-align;
                        right: 0;
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        border: 1px solid #979797;
                    }
                    &.active::after{
                        background: $color-green-light;
                        border-color: $color-green-light;
                    }
                }
            }
        }
        .btnA{
            height: 44px;
            font-size: 20px;
        }
    }
}