.v-notification-list{
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 3px 6px 0 rgba(0,0,0,0.21);
	cursor: auto;
	.v-nl-title{
		font-size: 1.35rem;
		font-weight: 500;
		line-height: 1.2em;
		color: #000;
	}
	.v-nl-btnMark{
		@extend %removeBtnStyles;
		cursor: pointer;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		@include cp-property(transition, 300ms);
		.v-nl-btn-icon{
			@extend %center-align;
			height: 20px;
		}
	}
	.v-nl-contentSub{
		cursor: pointer;
		input{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			pointer-events: none;
		}
		.v-nl-hover{
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 20;
		}
		.v-nl-subMenu{	
			font-size: 12px;
			line-height: 15px;
			color: #4A4A4A;
			min-width: 120px;
			width: intrinsic;   
			width: max-content;      
			width: -moz-max-content;
			width: -webkit-max-content;
			@extend %vertical-align;
			margin-left: 10px;
			margin-right: 10px;
			background: #fff;
			border-radius: 5px;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.21);
			z-index: 50;
			overflow: hidden;	
			.v-nl-item{
				cursor: pointer;
				@include cp-property(transition, 300ms ease);
				&:not(:last-child)::after{
					content: '';
					position: absolute;
					left: 5%;
					bottom: 0;
					width: 90%;
					height: 1px;
					background: #E5E5E5;
				}
                &:hover{
                	background: #f2f5fa;
                }
			}
		}
		&:hover{
			.v-nl-btnMark{
			    background: rgba(0,0,0, 0.21);
		    }
		}
	}
	.v-nl-alert-icon{
		width: 79px;
		height: 79px;
		.icon-ghost{
			@extend %center-align;
			width: 100%;
			fill: #F0F0F0;
		}
	}
	.v-nl-alert-txt{
		font-size: 1rem;
		line-height: 1.2em;
		font-weight: 500;
		color: #ccc;
	}
	.nav-submenu{
		overflow: hidden !important;
        .ps__rail-y .ps__thumb-y{
            background: var(--scroll-color);
        }
    } 
}

.v-notification{
	border-bottom:  1px solid #E5E5E5;
	cursor: pointer;
	background: #fff;
	@include cp-property(transition, background 400ms ease);
	.n-title{
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.2em;
		color: $color--text4;
	}
	&:first-child .v-hover .txt-cloud{
        bottom: initial !important;
        top: 100%;
	}
	.n-time{
		font-size: 14px;
		font-weight: 300;
		line-height: 17px;
		color: #898989;
	}
	.v-lesson-photo .v-lp-type{
        box-shadow: initial;
    }

    .n-check{
    	@extend %vertical-align;
    	right: 0;
    	width: 12px;
    	height: 12px;
    	border-radius: 6px;
    }

    &.active{
    	background: #E7ECF6;
    	
    }
    &:hover, &.selected{
    	background: lighten(#E7ECF6, 3%);
    }
}