#search-results{
	.filters{
		min-width: 280px;
		.paper{
			overflow: hidden;
			.title-filters{
				background: $color-blue-light;
				color: #fff;
				font-size: 14px;
				padding-top: 12px;
				padding-bottom: 12px;
				font-weight: 400;
			}
		}
	}
	.btn-filters{
		@extend %removeBtnStyles;
		background: $color-blue-main;
		border-radius: 3px;
		@include cp-property(transition, 300ms);
		.icon-equalizer{
			fill: #fff;
			width: 18px;
		}
		.txt-filters{
			color: #fff;
		}
		&:hover{
			background: darken($color-blue-main, 10%);
		}
	}
	.results{
		.title-results{
			font-size: 18px;
			font-weight: 400;
		}
	}
}

#tutoring-lesson-alert{
	.lesson-failed{
		max-width: 700px;
		.lf-title{
			position: relative;
			    &::after{
                content: '';
                @extend %horizontal-align;
                bottom: 0;
                height: 1px;
                width: 60%;
                background: #c6c6c6;
		    }
		}
		.icon-alert{
			width: 75px;
			height: 75px;
			&.success{
				fill: $color-green-light;
			}
			&.error{
				width: 95px;
				height: 95px;
				fill: $color-red-light;
			}
		}
		.status-msg{
			.msg{
				font-size: 24px;
				font-weight: 500;
			}
			.descrip{
				font-weight: 300;
			}
		}
	}
	.lesson-success{
		max-width: 700px;
        .ls-title{
        	padding-left: 35px;
        	.ls-title-icon{
        		position: absolute;
        		left: 0;
        		top: 3px;
        		width: 25px;
        	}
        	.ls-title-msg{
        		font-size: 19px;
        		font-weight: 500;
        		line-height: 24px;
        		color: #000;
        	}
        	.ls-title-descript{
        		font-size: 13px;
        		font-size: 300;
        		line-height: 15px;
        		color: #000;
        	}
        }
        .ls-info{
        	border: 2px solid $color-blue-main;
        	border-radius: 5px;
        	.ls-info-title{
        		font-size: 18px;
        		font-weight: 500;
        		line-height: 22px;
        		color: #000;
        	}
        	.v-lesson-tutor, .v-lesson-subject, ,.v-lesson-date{
        		font-size: 1rem;
        		line-height: 18px;
        		color: #000;
        	}
                .v-lesson-tutor, .v-lesson-subject, .v-lesson-date{
                        font-weight: 500;
                }
        	.v-lesson-subject, .v-lesson-date{
        		padding-left: 25px;
        		.v-ls-icon{
        			position: absolute;
        			top: 3px;
        			left: 0;
        			width: 14px;
        		}
        	}
        	.v-topic-tag{
        		background: #54CD23;
        		color: #fff;
        		font-size: 11px;
        		line-height: 14px;
        		border-radius: 2px;
        	}
        	.v-topic-opc{
        		font-size: 12px;
        		line-height: 15px;
        		color: #000;
        	}
        }
        .btn-lessons{
        	@extend %removeBtnStyles;
        	font-size: 16px;
        	font-weight: 500;
        	color: #000;
        	@include cp-property(transition, 400ms);
        	padding-right: 25px;
        	.bl-icon{
        		@extend %vertical-align;
        		right: 0;
        		width: 16px;
        		fill: #000;
        		@include cp-property(transition, 400ms);
        	}
        	&:hover{
        		color: $color-blue-main;
        		.bl-icon{
        			fill: $color-blue-main;
        		}
        	}
        }
	}
}