/* ================ Variables ================== */
$color-blue-main: #2188FC;
$color-blue-light: #00C0ff;
$color-blue-dark: #1b7ae5;

$color-green-main: #005E58;
$color-green-main2: #6CB300;
$color-green-light: #72BD00;
$color-green-dark: #004844;

$color-orange: #E8612A;

$color-red-light: #EA2E2E;
$color-red-dark: #DE3030;

$color-purple-main: #AD29D8;

$color--text: #979797;
$color--text2: #4a4a4a;
$color--text3: #9b9b9b;
$color--text4: #252525;

$color--whiteDark: #fafafa;
$color--gray: #d8d8d8;
$color--yellow: #F5A623;
$color--yellow2: #F38F1A;
$color--pink: #FC8190;
$color--border: #e5e5e5;
$header-h: 60px;
$search-h: 88px;

$lesson-color: (
	"pending": #F7B500,
	"upcoming": #0091ff,
	"ongoing": #72BD00,
	"canceled": #E02020,
	"finished": #00B1BC,
	"1-on-1": #2188FC,
	"group": #F5A623,
	"in-person": #AD29D8,
	"online": #6CB300
);

/* ================ Mixins (include) ================== */
@mixin cp-property($property,$style){
    #{$property} : $style;
    -webkit-#{$property} : $style;
    -moz-#{$property} : $style;
    -o-#{$property} : $style;
    -sm-#{$property} : $style;
}

@mixin cp-style($property,$style,$val){
    #{$property} : $style#{$val};
    #{$property} : -webkit-$style#{$val};
    #{$property} : -moz-$style#{$val};
    #{$property} : -o-$style#{$val};
    #{$property} : -sm-$style#{$val};
}

/* ================ Functions ================== */
@each $name, $color in $lesson-color {
    .bg-lesson-#{$name} {
        background: $color !important;
    }
}

@each $name, $color in $lesson-color {
    .color-lesson-#{$name} {
        color: $color;
    }
}

@each $name, $color in $lesson-color {
    .fill-lesson-#{$name} {
        fill: $color;
    }
}

/* ================ Herencias (extend) ================== */
.bgColor-tutor-main{
	background: $color-green-main !important;
}
.bgColor-tutor-light{
	background: $color-green-light !important;
}
.bgColor-tutor-dark{
	background: $color-green-dark !important;
}
.bgColor-green-main{
	background: $color-green-main2 !important;
}
.bgColor-student-main{
	background: $color-blue-main !important;
}
.bgColor-student-light{
	background: $color-blue-light !important;
}
.bgColor-student-dark{
	background: $color-blue-dark !important;
}
.bgColor-yellow-main{
	background: $color--yellow !important;
}

.bgColor-yellow-light{
	background: $color--yellow2 !important;
}

.bgColor-red-light{
	background: $color-red-light !important;
}

.bgColor-red-dark{
	background: $color-red-dark !important;
}

.bgColor-purple-main{
	background: $color-purple-main !important;
}

.color-tutor-main{
	color: $color-green-main !important;
}
.color-tutor-light{
	color: $color-green-light !important;
}
.color-tutor-dark{
	color: $color-green-dark !important;
}
.color-student-main{
	color: $color-blue-main !important;
}
.color-student-light{
	color: $color-blue-light !important;
}
.color-student-dark{
	color: $color-blue-dark !important;
}
.color-yellow-main{
	color: $color--yellow !important;
}

.fill-green-main{
	fill: $color-green-main !important;
}

.fill-green-light{
	fill: $color-green-light !important;
}

.fill-blue-main{
	fill: $color-blue-main !important;
}

%center-align{
	position: absolute;
	@include cp-property(transform, translate(-50%, -50%));
	left: 50%;
	top: 50%;
}

%vertical-align{
	position: absolute;
	@include cp-property(transform, translateY(-50%));
	top: 50%;
}

%horizontal-align{
	position: absolute;
	@include cp-property(transform, translateX(-50%));
	left: 50%;
}

%noFocus{
	outline: none;
	outline-style: none;
	box-shadow: none;
	border-color: transparent;
}

%removeBtnStyles{
	background: transparent;
	border: none;
	padding: 0;
	&:focus{
		@extend %noFocus;
	}
}