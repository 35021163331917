.v-list-chats {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    min-height: 400px;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .paper:first-child {
        .v-hover {
            .txt-cloud {
                top: 100%;
                bottom: initial !important;
            }
        }

    }

    .paper {
        border-bottom: 0.51px solid #E5E5E5;
        box-shadow: initial;
        border-radius: initial;
        cursor: pointer;
        @include cp-property(transition, 200ms);

        .v-hover {
            .txt-hover {
                @include cp-property(transition, 200ms);
            }
        }

        .v-lc-r {

            .v-lc-time,
            .v-lc-status {
                @include cp-property(transition, 200ms);
            }

            .v-lc-status {
                @include cp-property(transition, 200ms);

                .play-icon {
                    width: 6px;
                    @include cp-property(transition, 200ms);
                }
            }
        }

        &:hover {
            //@include cp-property(transform, scale(1.02));
            z-index: 15;
        }

        &.router-link-active {
            z-index: 10;

            .v-hover {
                .txt-hover {
                    color: #fff !important;
                }
            }

            .v-lc-r {
                .v-lc-time {
                    color: #fff;
                }

                .v-lc-status {
                    color: #fff !important;

                    .play-icon {
                        fill: #fff !important;
                    }
                }
            }
        }
    }

    .list-empty {
        max-width: 290px;

        p {
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            color: #ccc;
        }
    }

    .v-chat-info {
        padding-left: 50px;
        padding-right: 70px;
        min-height: 44px;

        .v-lesson-photo {
            top: 50%;
            left: 0;
            @include cp-property(transform, translateY(-50%));
        }

        .v-lc-r {
            position: absolute;
            right: 0;
            bottom: 0;

            .v-lc-time {
                font-size: 12px;
                font-weight: 300;
                color: #898989;
            }

            .v-lc-status {
                font-size: 10px;
                font-weight: 500;
                line-height: 12px;
            }

            .v-last-msg {
                font-size: 10px;
                color: #fff;
                position: relative;
                height: 14px;
                width: 14px;
                background: $color-red-light;
                border-radius: 50%;

                &.hide {
                    opacity: 0;
                    pointer-events: none;
                }

                .v-msg {
                    @extend %center-align;
                }
            }
        }
    }
}

.v-chat {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    position: relative;

    .v-btn-close-chat {
        background: transparent;
        border: none;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;

        .icon {
            width: 16px;
        }

        &:focus {
            outline: none;
        }
    }

    .v-chat-msg {
        @extend %center-align;
    }

    .v-chat-date-absolute {
        @extend %horizontal-align;
        top: 50px;
        @include cp-property(transition, 400ms);
        z-index: 10;

        &.hide {
            opacity: 0;
            pointer-events: none;
        }
    }

    .v-chat-date,
    .v-chat-date-absolute {
        padding: .25rem .50rem;
        border-radius: 5px;
        font-size: 14px;
        color: $color--text3;
    }

    .ps {
        //@include cp-style(max-height, calc, unquote('(100% - 60px)'));
        max-height: calc(100% - 60px);

        .ps__rail-y .ps__thumb-y {
            background: var(--scroll-color);
        }
    }

    .v-content-chat {
        .d-flex {
            display: -webkit-box !important;
            display: -webkit-flex !important;
            display: -webkit-flexbox !important;
        }

        .chat-spinner {
            @extend %horizontal-align;
            top: 0;
            background: #fff;
            z-index: 10;
        }

        list-style: none;
        padding-left: 45px;
        padding-right: 45px;

        .v-chat-user {
            font-size: 12px;
            font-weight: 300;
            line-height: 14px;
            margin-bottom: 4px;
            color: $color--text;

            .label-t {
                display: inline-block;
                background: $color-green-main;
                border-radius: 2px;
                padding: .05rem .2rem 0.1rem .2rem;

                span {
                    font-size: 10px;
                    font-weight: 300;
                    color: #fff;
                }
            }
        }

        .v-chat-bubble {
            position: relative;
            margin-bottom: 8px;
            font-size: 14px;
            padding: 5px 15px;
            border-radius: 14px;
            max-width: 85%;
            display: inline-block;

            .v-chat-prev {
                position: absolute;
                top: 13px;
                width: 18px;
            }

            &[type='file'] {
                min-width: 220px;
                cursor: pointer;

                .v-chat-content {
                    padding-left: 36px;
                }

                .v-icon-file {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background: #fff;

                    .v-icon {
                        @extend %center-align;
                        height: 19px;
                        width: 19px;
                    }

                    .v-content-ih {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        background: #8D949E;
                        @include cp-property(transform, translate(25%, 25%));
                        @include cp-property(transition, 400ms);
                        opacity: 0;

                        .v-icon-hover {
                            @extend %center-align;
                            width: 10px;
                            height: 10px;
                            fill: #fff;
                        }
                    }
                }

                .btn-download,
                .btn-cancel-download {
                    @extend %removeBtnStyles;
                    position: absolute;
                    top: 3px;
                    right: 0;
                    width: 26px;
                    height: 26px;
                    border-radius: 3px;
                    background: #8D949E;
                    @include cp-property(transition, 400ms);

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background: rgba(0, 0, 0, 0.4);
                        border-radius: 3px;
                        z-index: 5;
                    }

                    .d-icon {
                        @extend %center-align;
                        height: 16px;
                        width: 16px;
                        fill: #fff;
                        z-index: 10;
                    }
                }

                .btn-cancel-download {
                    .cv-spinner {
                        @extend %center-align;
                        width: 24px;
                        height: 24px;
                        z-index: 15;

                        .spinner {
                            stroke: #fff !important;
                        }
                    }

                    .d-icon {
                        width: 11px;
                        height: 11px;
                    }
                }

                .btn-download {
                    opacity: 0;
                    pointer-events: none;
                }

                &:hover {
                    .btn-download {
                        opacity: 1;
                        pointer-events: all;
                    }

                    .v-icon-file {
                        .v-content-ih {
                            opacity: 1;
                        }
                    }
                }
            }

            &[type="file"] {

                @include cp-property(transition, 400ms);

                .v-chat-prev {
                    @include cp-property(transition, 400ms);
                }

                .v-msg {
                    text-decoration: underline;
                }

                &:hover {
                    /*&:not(.bgColor-tutor-main), &:not(.bgColor-student-main){
                        background: darken(#e7e7e7, 5%) !important;
                        .v-chat-prev{
                            fill: $color-green-main;
                        }
                    }
                    &.bgColor-tutor-main{ 
                        background: darken($color-green-main, 5%) !important;
                        .v-chat-prev{
                            fill: $color-green-main;
                        }
                    }
                    &.bgColor-student-main{
                        background: darken($color-blue-main, 5%) !important;
                        .v-chat-prev{
                            fill: $color-blue-main;
                        }
                    }
                    .v-icon-file{
                        background: darken(#fff, 5%) !important;
                    }*/

                }
            }

            &.v-chat-txt-right {
                .v-link {
                    color: #fff;

                    &:hover,
                    &:focus {
                        color: #fff;
                    }
                }
            }

            .v-msg {
                font-weight: 300;
                line-height: 16px;
                text-align: left;
                //margin-bottom: 4px;
                white-space: pre-line;
                overflow-wrap: break-word;
                -ms-word-break: break-word;
                word-break: break-word;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;
            }

            .v-file-size {
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
                margin-top: 2px;
            }

            .v-link {
                font-weight: 300;
                line-height: 16px;
                color: $color-blue-main;
                @extend %noFocus;

                &:hover,
                &:focus {
                    color: $color-blue-main;
                    //overflow-wrap: break-word;
                }
            }

            .v-time {
                font-size: 12px;
                font-weight: 200;
                line-height: 100%;
                text-align: right;
            }

            .v-chat-img {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                position: absolute;
                top: 0;
                pointer-events: none;

                &.n-img {
                    background: $color--yellow;

                    .icon-users {
                        @extend %center-align;
                        width: 60%;
                        fill: #fff;
                    }
                }
            }

            &.v-chat-txt-right {
                color: #fff;

                .v-chat-img {
                    right: -44px;
                }

                .v-chat-prev {
                    left: -22px;
                }
            }

            &.v-chat-txt-left {
                background: #e7e7e7;
                color: #6B6B6B;

                .v-chat-img {
                    left: -44px;
                    top: 4px;
                }

                .v-chat-prev {
                    right: -22px;
                }
            }
        }
    }

    .btn-fields,
    .btn-emoji,
    .btn-download,
    .btn-other,
    .btn-cancel-download {
        .hover-txt {
            position: absolute;
            left: 50%;
            bottom: 100%;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            min-width: 50px;
            width: intrinsic;
            width: max-content;
            width: -moz-max-content;
            width: -webkit-max-content;
            padding: .2rem .5rem;
            border-radius: 5px;
            background: #fff;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
            color: $color--text2;
            pointer-events: none;
            opacity: 0;
            z-index: 20;
            @include cp-property(transform, translateX(-50%) translateY(10px) scale(0.5));
            @include cp-property(transition, 100ms ease);
        }

        &:hover {
            .hover-txt {
                opacity: 1;
                @include cp-property(transform, translateX(-50%) translateY(-5px) scale(1));
                @include cp-property(transition, 400ms ease);
            }
        }
    }

    .btn-fields {
        @extend %removeBtnStyles;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        @include cp-property(transition, 300ms);

        .icon-clip {
            @extend %center-align;
            top: 55%;
            height: 21px;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.15);
        }
    }

    .v-content-btn-send {
        max-width: 50px;

        .v-btn-send {
            border: none;
            background: transparent;
            padding: 0;

            &:focus {
                outline: none;
            }

            .v-btn-send-icon {
                width: 30px;

                &.fill-t {
                    fill: $color-green-main;
                }

                &.fill-s {
                    fill: $color-blue-main;
                }

            }
        }
    }
}

.v-chat-input {
    .v-content-field-text {
        .v-field-text {
            border: 1px solid #DDDDDD;
            border-radius: 8px;
            height: 44px;
            padding: 5px 35px 5px 20px;
            color: $color--text2;
            font-size: 14px;

            &:focus {
                outline: none;
            }
        }
    }
}

.v-emojis {
    .btn-emoji {
        @extend %vertical-align;
        right: .5rem;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        @include cp-property(transition, 300ms);

        .btn-emoji-hover {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
            z-index: 10;
        }

        .input-hide {
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .emoji-icon {
            @extend %center-align;
            width: 17px;
            z-index: 5;
        }

        &:hover {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .emoji-picker {
        position: absolute;
        @include cp-style(bottom, calc, unquote('(100% + 15px)'));
        right: 0;
        background: #fff !important;

        .container-emoji {
            height: 170px !important;
        }
    }
}


.v-chat-lesson {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);

    .v-chat-lesson-head {
        font-size: 14px;
        line-height: 17px;
        border-radius: 4px 4px 0 0;
        color: #fff;
    }

    .v-chat-lesson-info {
        background: #fff;
        border-radius: 0 0 4px 4px;

        .v-cl-name {
            font-size: 1.35rem;
            line-height: 1.2em;
            color: $color--text4;
        }

        .v-cl-subject {
            font-size: 1rem;
            line-height: 1.2em;
            color: $color--text2;
        }
    }

    .v-lesson-sch {
        .v-ls-calendar {
            position: absolute;
            left: 0;
            top: 2px;
        }

        .ml-3 {
            margin-left: 0 !important;

            .v-ls-date {
                padding-left: 24px;
            }
        }
    }

    .v-cl-color-status {
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .v-cl-status {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #000;
    }

    .v-cl-fulldate {
        font-size: 1rem;
        line-height: 1.2em;
        font-weight: 500;
        color: #000;
    }

    .v-cl-subject,
    .v-cl-fulldate {
        padding-left: 25px;

        .v-cl-icon {
            position: absolute;
            top: 3px;
            left: 0;
            width: 14px;
        }
    }

    .v-lesson-btn[name="btn-lesson-online"] {
        .v-lb-txt {
            font-size: 18px !important;
        }
    }

    .v-cl-memmers {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 15;

        .v-title-memmers {
            font-size: 1rem;
            font-weight: 500;
            color: #000;
        }

        .v-memmers {
            //@include cp-style(height, calc, unquote('(100% - 35px)'));
            height: calc(100% - 35px);
        }

        .v-btn-close {
            @extend %vertical-align;
            right: 0;
            width: 18px;
            fill: #000;
            @include cp-property(transition, 400ms);
            cursor: pointer;

            &:hover {
                fill: $color-red-dark;
            }
        }
    }
}

.v-dropzone {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(255, 255, 255, 0.95);

    .v-dropzone-close {
        position: absolute;
        @extend %removeBtnStyles;
        left: 1.5rem;
        top: 1.5rem;
        z-index: 5;

        .icon-cross {
            width: 16px;
        }

        span {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &::before {
        content: '';
        position: absolute;
        left: 1.5rem;
        top: 3.5rem;
        right: 1.5rem;
        bottom: 1.5rem;
        border: 2px dashed var(--bg-color);
    }

    #dropzone {
        background: transparent;
        position: relative;
        padding-top: 3.5rem;
        overflow: hidden;

        .dz-preview {
            min-height: initial;
            width: 100px;
            height: 100px;
            margin: .4rem;
            border-radius: 8px;
            overflow: hidden;

            .dz-details {
                padding: .5rem;
                z-index: 20;

                .dz-error-message {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: $color-red-dark;
                    opacity: 1;
                    z-index: 5;
                }

                .dz-filename {
                    position: absolute;
                    bottom: 8px;
                    left: 10%;
                    width: 80%;
                    z-index: 10;
                    @include cp-property(transition, 400ms);

                    span {
                        padding: 0;
                    }
                }

                .dz-size {
                    position: relative;
                    font-size: 12px;
                    text-align: left;
                    z-index: 10;
                    @include cp-property(transition, 400ms);
                }

                .dz-error {
                    @extend %center-align;
                    opacity: 0;
                    z-index: 10;
                    font-size: 12px;
                    line-height: 14px;
                    width: 100%;
                    height: auto;
                    text-align: center;
                    pointer-events: none;
                    @include cp-property(transition, 400ms);
                }

                &.dz-error-message:hover {

                    .dz-size,
                    .dz-filename {
                        opacity: 0;
                    }

                    .dz-error {
                        opacity: 1;
                    }
                }
            }

            .dz-image {
                display: flex;
                justify-content: center;
            }

            .dz-progress {
                width: 90%;
                height: 8px;
                left: 45%;
                top: 55%;

                .dz-upload {
                    background: rgba(0, 0, 0, 0.7)8;
                }
            }

            .dz-remove {
                position: absolute;
                right: 5px;
                top: 5px;
                width: 16px;
                height: 16px;
                border: initial;
                padding: 0;
                display: block;
                margin: 0;
                bottom: initial;
                z-index: 50;

                &::before,
                &::after {
                    content: '';
                    @extend %center-align;
                    left: 0;
                    height: 2px;
                    width: 100%;
                    background: #fff;
                }

                &::before {
                    @include cp-property(transform, rotate(45deg));
                }

                &::after {
                    @include cp-property(transform, rotate(-45deg));
                }
            }
        }

        .dz-message {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.2em;
            margin: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.8);
            z-index: 50;
            pointer-events: none;

            .dropzone-custom-content {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                .dropzone-custom-title {
                    @extend %center-align;

                    .dz-title {
                        margin-bottom: 0;
                        color: var(--bg-color);
                    }
                }
            }
        }

        &.dz-drag-hover {
            .dz-message {
                display: block;
            }

            .dz-preview {
                pointer-events: none;
            }
        }
    }
}

.v-file-prevew {
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: 200;
    @include cp-property(transition, 400ms);
    pointer-events: none;

    .blurColor {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        cursor: pointer;
    }

    .v-preview-content {
        @extend %center-align;
        width: 90%;
        height: 90%;
        background: #fff;
        border-radius: 5px;

        .v-preview-header {
            height: 60px;
            border-bottom: 4px solid #efefef;
        }

        .v-preview-info {
            padding-left: 35px;
            min-width: 1px;

            .v-file-size {
                font-weight: 300;
                font-size: 14px;
                line-height: 16px;
            }

            .v-info-icon {
                @extend %vertical-align;
                left: 0;
                width: 25px;
                fill: #000;
            }
        }

        .v-preview-actions {
            .content-btn-close {
                border-left: 1px solid #000;
            }

            .btn-other {
                @extend %removeBtnStyles;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                position: relative;

                .cp-spinner {
                    width: 30px;
                    height: 30px;
                    @extend %center-align;
                }

                .i-other {
                    @extend %center-align;
                    fill: #000;
                    width: 18px;
                    height: 18px;
                    @include cp-property(transition, 400ms);
                }

                .hover-txt {
                    top: 100%;
                    bottom: initial;
                    @include cp-property(transform, translateX(-50%) translateY(-10px) scale(0.5));
                }

                &:hover {
                    .i-other {
                        fill: var(--hover-color);
                    }

                    .hover-txt {
                        @include cp-property(transform, translateX(-50%) translateY(5px) scale(1));
                    }
                }
            }

            .btn-close {
                @extend %removeBtnStyles;

                .i-cross {
                    fill: #000;
                    width: 16px;
                    @include cp-property(transition, 400ms);
                }

                &:hover {
                    .i-cross {
                        fill: $color-red-dark;
                    }
                }
            }
        }

        .v-preview {
            //@include cp-style(height, calc, unquote('(100% - 60px)'));
            height: calc(100% - 60px);

            .content-spinner {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: #fff;
                z-index: 10;
            }

            .fileNotFound {
                font-size: 1.2rem;
                line-height: 1.2em;
                color: #4A4A4A;
            }

            video {
                width: 100%;
                max-width: 900px;
                max-height: 100%;
            }

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}