@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

@-moz-keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/* ==== pulce === */
@keyframes pulse-yellow{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 166, 35, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(245, 166, 35, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 166, 35, 0);
  }
}

@-webkit-keyframes pulse-yellow{
  0% {
    transform: -webkit-scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 166, 35, 0.7);
  }
  
  70% {
    transform: -webkit-scale(1);
    box-shadow: 0 0 0 10px rgba(245, 166, 35, 0);
  }
  
  100% {
    transform: -webkit-scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 166, 35, 0);
  }
}

@-moz-keyframes pulse-yellow{
  0% {
    transform: -moz-scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 166, 35, 0.7);
  }
  
  70% {
    transform: -moz-scale(1);
    box-shadow: 0 0 0 10px rgba(245, 166, 35, 0);
  }
  
  100% {
    transform: -moz-scale(0.95);
    box-shadow: 0 0 0 0 rgba(245, 166, 35, 0);
  }
}

@keyframes pulse-green{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 94, 88, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 94, 88, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 94, 88, 0);
  }
}

@-webkit-keyframes pulse-green{
  0% {
    transform: -webkit-scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 94, 88, 0.7);
  }
  
  70% {
    transform: -webkit-scale(1);
    box-shadow: 0 0 0 10px rgba(0, 94, 88, 0);
  }
  
  100% {
    transform: -webkit-scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 94, 88, 0);
  }
}

@-moz-keyframes pulse-green{
  0% {
    transform: -moz-scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 94, 88, 0.7);
  }
  
  70% {
    transform: -moz-scale(1);
    box-shadow: 0 0 0 10px rgba(0, 94, 88, 0);
  }
  
  100% {
    transform: -moz-scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 94, 88, 0);
  }
}

@keyframes pulse-green-light{
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(114, 189, 0, 0.8);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 7px rgba(114, 189, 0, 0);
  }
  
  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(114, 189, 0, 0);
  }
}

@-webkit-keyframes pulse-green-light{
  0% {
    transform: -webkit-scale(0.98);
    box-shadow: 0 0 0 0 rgba(114, 189, 0, 0.8);
  }
  
  70% {
    transform: -webkit-scale(1);
    box-shadow: 0 0 0 7px rgba(114, 189, 0, 0);
  }
  
  100% {
    transform: -webkit-scale(0.98);
    box-shadow: 0 0 0 0 rgba(114, 189, 0, 0);
  }
}

@-moz-keyframes pulse-green-light{
  0% {
    transform: -moz-scale(0.98);
    box-shadow: 0 0 0 0 rgba(114, 189, 0, 0.8);
  }
  
  70% {
    transform: -moz-scale(1);
    box-shadow: 0 0 0 7px rgba(114, 189, 0, 0);
  }
  
  100% {
    transform: -moz-scale(0.98);
    box-shadow: 0 0 0 0 rgba(114, 189, 0, 0);
  }
}

@keyframes pulse-blue{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 136, 252, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(33, 136, 252, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 136, 252, 0);
  }
}

@-webkit-keyframes pulse-blue{
  0% {
    transform: -webkit-scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 136, 252, 0.7);
  }
  
  70% {
    transform: -webkit-scale(1);
    box-shadow: 0 0 0 10px rgba(33, 136, 252, 0);
  }
  
  100% {
    transform: -webkit-scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 136, 252, 0);
  }
}

@-moz-keyframes pulse-blue{
  0% {
    transform: -moz-scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 136, 252, 0.7);
  }
  
  70% {
    transform: -moz-scale(1);
    box-shadow: 0 0 0 10px rgba(33, 136, 252, 0);
  }
  
  100% {
    transform: -moz-scale(0.95);
    box-shadow: 0 0 0 0 rgba(33, 136, 252, 0);
  }
}

@keyframes circles-animation{
  0%, 60%,100%{
    transform: translate(0,0);
  }
  10%{
    transform: translate(-4%, -4px);
  }
  20%{
    transform: translate(0, -2%);
  }
  30%,70%{
    transform: translate(-2%, 2%);
  }
  40%,80%{
    transform: translate(-3px, 0);
  }
  50%,90%{
    transform: translate(-2%, -4px);
  }
}

@-webkit-keyframes circles-animation{
  0%, 60%,100%{
    transform: translate(0,0);
  }
  10%{
    transform: -webkit-translate(-4%, -4px);
  }
  20%{
    transform: -webkit-translate(0, -2%);
  }
  30%,70%{
    transform: -webkit-translate(-2%, 2%);
  }
  40%,80%{
    transform: -webkit-translate(-3px, 0);
  }
  50%,90%{
    transform: -webkit-translate(-2%, -4px);
  }
}

@-moz-keyframes circles-animation{
  0%, 60%,100%{
    transform: translate(0,0);
  }
  10%{
    transform: -moz-translate(-4%, -4px);
  }
  20%{
    transform: -moz-translate(0, -2%);
  }
  30%,70%{
    transform: -moz-translate(-2%, 2%);
  }
  40%,80%{
    transform: -moz-translate(-3px, 0);
  }
  50%,90%{
    transform: -moz-translate(-2%, -4px);
  }
}

@keyframes audioBound{
  0%,10%{
    opacity: 0;
  }
  10%{
    transform: scale(1.35);
    opacity: 1;
  }
  100%{
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes audioBound{
  0%,10%{
    opacity: 0;
  }
  10%{
    -webkit-transform: scale(1.35);
    opacity: 1;
  }
  100%{
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@-moz-keyframes audioBound{
  0%,10%{
    opacity: 0;
  }
  10%{
    -moz-transform: scale(1.35);
    opacity: 1;
  }
  100%{
    -moz-transform: scale(2);
    opacity: 0;
  }
}