*{
	font-family: 'Helvetica Neue';
	font-weight: normal;
	font-style: normal;
}

body{
    background: #F4F7FC;
    min-height: 100vh;
    &.stop-scrolling { 
        height: 100%; 
        overflow: hidden; 
    } 
}

#app{
    overflow: hidden;
}

.VueCarousel-dot-container{
    margin-top: 0 !important;
    .VueCarousel-dot{
        padding: 0 !important;
        width: 12px !important;
        height: 12px !important;
        border-radius: 50% !important;
        margin: 20px 5px 0 5px !important;
        &:focus{
            outline: none !important;
        }
    }
}

/* ================= Compact router-view =================== */
.view{
    margin-top: $header-h;
    margin-left: 260px;
    @include cp-property(transition, 500ms);
    &.compact{
        margin-left: 60px;
    }
}

/* ================= Color scroll bar =================== */
.ps__rail-y{
    z-index: 100;
    .ps__thumb-y{
        background: $color-blue-main;
    }
}

.paper{
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
    border-radius: 4px;
    position: relative;
}

.basic-paper{
    background: #fff;
    border-radius: 4px;
    position: relative;
}

.empty-lessons, .v-chat-msg{
    > .img{
        width: 220px;
        max-width: 300px;
    }
    .txt{

        color: #3a3a3a;
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
        &.bold{
            font-weight: 500;
            font-size: 14px;
        }
        .bold{
            font-weight: 500;
        }
    }
    .txt2{
        color: $color--text3;
        font-weight: 300;
        font-size: 12px;
    }
    > .link{
        font-size: 14px;
        font-weight: 500;
        color: $color-blue-main;
        position: relative;
        &::after{
            content: '';
            @extend %horizontal-align;
            top: 100%;
            height: 1px;
            width: 100%;
            background: $color-blue-main;
            @include cp-property(transition, 300ms);
        }
        &:link{
            text-decoration: none;
        }
    }
}

.stars.hide-num-rating .custom-text{
    opacity: 0;
    pointer-events: none;
}   

.btn-cancel{
    font-size: 12px;
    border: none;
    background: transparent;
    padding: 0;
    position: relative;
    cursor: pointer;
    &::after{
        content: '';
        @extend %horizontal-align;
        bottom: 0;
        height: 1px;
        width: 100%;
        background: #000;
    }
    &:focus{
        outline: none;
    }
    &:active{
        color: initial;
    }
}
        
.v-checkbox{
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 0 0 1px $color--text;
    .v-checked{
        @extend %center-align;
        width: 90%;
        height: 90%;
        border-radius: 2px;
        opacity: 0;
        pointer-events: none;
        z-index: 10;
    }
    &::after{
        content: '';
        @extend %center-align;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background: $color-blue-main;
        opacity: 0;
        pointer-events: none;
    }
    input[type="checkbox"]{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }
    &.active{
        .v-checked{
            opacity: 1;
        }
        &::after{
            opacity: 1;
        }
    }
    &.disabled{
        opacity: 0.7;
        pointer-events: none;
    }
    &.v-green{
        &::after{
            background: $color-green-main;
        }
    }
}

.point{
    width: 14px;
    height: 14px;
    border: 3px solid rgba(255,255,255,0.5);
    border-radius: 50%;
}

.btn-lessons{
    font-size: 14px;
    color: $color-blue-main;
    padding-right: 20px;
    background: transparent;
    border: none;
    position: relative;
    .icon{
        position: absolute;
        @include cp-property(transform, rotate(180deg) translateY(-50%));
        top: 50%;
        right: 0;
        width: 16px;
        @include cp-property(transform-origin, center top);
        @include cp-property(transition, 400ms);
        svg{
            fill: $color-blue-main;
            width: 100%;
        }
    }
    &:focus{
        outline: none;
    }
    &.active{
        .icon{
            @include cp-property(transform, rotateY(180deg) translateY(-50%));
        }
    }
}

/* ================= Start Circle image =================== */
.content-img{
    width: 60px;
    height: 60px;
    position: relative;
    border-radius: 50%;
    .content-icon{
        position: absolute;
        width: 25px;
        height: 25px;
        bottom: 0;
        border-radius: 50%;
        .icon{
            @extend %center-align;
            max-width: 60%;
            max-height: 60%;
            fill: #fff;
        }
        &.p-right{
            right: 0;
        }
    }
}
/* ================= End Circle image =================== */

/* ================= Start Alert Dialog =================== */
#alert-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 200;
    pointer-events: none;
    opacity: 0;
    @include cp-property(transition, 400ms);
    .blurColor{
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        background: rgba(0,0,0,0.4);
        z-index: 5;
    }
    .modal-alert{
        @extend %center-align;
        max-width: 380px;
        width: 90%;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        border-radius: 8px;
        background: #fff;
        z-index: 10;
        @include cp-property(transition, opacity 400ms ease-out);
        > .icon{
            width: 95px;
            height: 95px;
        }
        .alert-msg{
            font-size: 24px;
            font-weight: 500;
        }
        .alert-legend{
            font-weight: 300;
        }
        .btn-accept{
            background: $color-blue-light;
            color: #fff;
            font-size: 20px;
            height: 44px;
        }
        &[alert="error"] .btn-accept{
            background: $color-red-light;
        }
        &[alert="warning"] .btn-accept{
            background: $color--yellow2;
        }
        &[alert="success"] .btn-accept{
            background: $color-green-light;
        }
    }
    &.active{
        pointer-events: all;
        opacity: 1;
    }
}
/* ================= End Alert Dialog =================== */

/* ================= Btn prev =================== */
.v-btn-prev{
    button{
        border: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
        padding-left: 30px;
        font-size: 14px;
        color: $color--text2;
        position: relative;
        .icon{
            @extend %vertical-align;
            left: 0;
            width: 20px;
            fill: $color--text2;
        }
        &:hover{
            color: $color-red-light;
            .icon{
                fill: $color-red-light;
            }
        }
        &:focus{
            outline: none;
        }
    }
}

/* ================= Score =================== */
.score{
    .stars{
        .star{
            display: inline-block;
            width: 25px;
            fill: $color--yellow;
        }  
    }
    .score-n{
            font-size: 30px;
            font-weight: 500;
        }
}


.v-slide-animated{
    @include cp-property(transition, height 400ms ease);
    &.bound > * {
        opacity: 1;
        @include cp-property(transition, opacity 400ms linear);
        @include cp-property(transition-delay, 400ms);
    }
    &.hide{
        height: 0 !important;
        overflow: hidden;
        &.bound > * {
            opacity: 0;
            @include cp-property(transition-delay, 0);
        }
    }
}

/* ================= icon ================== */
.bg-icon{
    height: 82px;
    width: 82px;
    border-radius: 50%;
    background: $color-blue-main;
    position: relative;
    .inset-icon{
        @extend %center-align;
        width: 40px;
        height: 40px;
        fill: #fff;
        &.lock2{
            left: 54%;
        }
    }
    &::after{
        content: '';
        @extend %horizontal-align;
        top: 105%;
        width: 50%;
        height: 6px;
        border-radius: 50%;
        background: #ebebeb;
    }
}

.vue-html2pdf{
    .content-wrapper{
        background: #fff;
    }
}
