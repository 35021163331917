#account-details {
	overflow: hidden;
	//@include cp-style(min-height, calc, unquote('( 100vh - '+$header-h+' )'));
	min-height: calc(100vh - #{$header-h});

	.title {
		font-size: 18px;
	}

	>.paper {
		box-shadow: initial;
		//overflow: hidden;
	}

	.details {
		max-width: 400px;
		min-width: 400px;

		form {
			position: relative;
			z-index: 10;

			.editImg {
				width: 110px;
				height: 110px;
			}

			.content-img {
				cursor: pointer;

				img {
					display: none !important;
				}

				input[type="file"] {
					opacity: 0;
					pointer-events: none;
				}

				.content-icon {
					width: 35px;
					height: 35px;
					z-index: 10;
				}

				.img-edit {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					border-radius: 50%;
					@include cp-property(transition, 400ms);
					z-index: 5;

					>.icon {
						@extend %center-align;
						width: 50px;
						fill: #fff;
					}
				}

				&:hover {
					.img-edit {
						opacity: 1;
					}
				}
			}
		}

		#circles3 {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			@include cp-property(transform, translateX(-50%));
		}

		#circles4 {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 5;
			@include cp-property(transform, translateX(60%));
		}
	}
}

#change-password {
	overflow: hidden;

	>.paper {
		box-shadow: initial;
		overflow: hidden;
	}

	.title {
		font-size: 20px;
		width: 100%;
	}

	.txt-steps {
		font-size: 10px;
		font-weight: 500;
		color: $color-red-light;
	}

	.details {
		max-width: 390px;

		form {
			z-index: 10;
			padding-bottom: 3rem !important;

			.picture {
				height: 180px;
				width: 100%;

				img {
					max-height: 100%;
					max-width: 160px;
				}

				.icon-lock {
					max-width: 180px;
					max-height: 100%;
					width: 100%;
				}

				.icon-locks {
					border-radius: 50%;
					width: 150px;
					height: 150px;
					background: $color-blue-main;
					position: relative;

					.lock {
						@extend %center-align;
						left: 54%;
						width: 50%;
					}

					&::after {
						content: '';
						@extend %horizontal-align;
						top: 105%;
						width: 55%;
						height: 10px;
						border-radius: 50%;
						background: #ebebeb;
					}
				}
			}
		}

		#circles3 {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			@include cp-property(transform, translateX(-50%));
		}

		#circles4 {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 5;
			@include cp-property(transform, translateX(60%));
		}
	}
}

#notification-preferences {
	>.paper {
		box-shadow: initial;
		overflow: hidden;
		overflow: hidden;
	}

	.emails,
	.notifications {
		max-width: 435px;

		>.title {
			font-size: 18px;
			z-index: 10;
		}

		form {
			position: relative;
			z-index: 10;
		}
	}

	.emails {
		.checkBox {
			padding-left: 30px;
			position: relative;
			min-height: 56px;

			.title {
				font-size: 14px;
				font-weight: 500;
				z-index: 10;
			}

			.descrip {
				font-size: 14px;
				line-height: 18px;
			}
		}

		.general {
			position: relative;

			>.title {
				font-size: 16px;
				font-weight: 400;
				color: $color-blue-main;
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 40px;
				height: 1px;
				background: $color--text;
			}
		}

		.lessons {
			>.title {
				font-size: 16px;
				font-weight: 400;
				color: $color-blue-main;
			}
		}

		#circles3 {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
			@include cp-property(transform, translateX(-50%));
		}

		&.v-green {
			.checkBox {
				.v-checkbox {
					&::after {
						background: $color-green-main;
					}
				}
			}
		}
	}

	.notifications {
		.paper {
			padding: 2rem;
		}

		.descrip {
			font-size: 14px;
			font-weight: 400;
		}

		.title-app {
			font-size: 14px;
			font-weight: 500;
		}

		.img-app {
			cursor: pointer;
			display: inline-block;
			max-height: 35px;
			max-width: 48%;
		}
	}
}