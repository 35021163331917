.v-init-form{
    min-height: 100vh;
    background: #fff;
    .v-init-form-content{
        width: 90%;
        max-width: 400px;
    }
    .v-paper{
        background: #fff;
        box-shadow: 0 5px 14px 0 rgba(0,0,0,0.2);
        border-radius: 3px;
        overflow: hidden;
        .v-if-head{
            background: $color-blue-main;
            .v-btn-prev{
                @extend %removeBtnStyles;
                @include cp-property(transition, 300ms);
                .v-btn-prev-icon{
                    width: 25px;
                }
                &:hover{
                    @include cp-property(transform, translateX(-3px));
                }
            }
            .v-if-title{
                font-size: 1.125rem;
                font-weight: 500;
                line-height: 1.2em;
                color: #fff;
            }
        }
        .v-institution-logo{
            max-width: 100%;
            max-height: 60px;
        }
        .v-if-txt{
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.2em;
            color: #4A4A4A;
            &[weight="bold"]{
                font-weight: 500;
            }
            strong{
                color: $color-blue-main;
                font-weight: 400;
            }
        }
        .v-if-txt-opps{
            font-size: 3.125rem;
            color: #000;
        }

        .v-btn-linc{
            font-size: 12px;
            color: $color--text2;
            position: relative;
            text-decoration: underline;
            @extend %removeBtnStyles;
            @include cp-property(transition, 300ms ease);
            &:hover{
                color: $color-blue-main;
            }
        }

        .v-if-checkbox{
            font-size: 12px;
            line-height: 15px;
            color: #4A4A4A;
            input{
                width: 16px;
                height: 16px;
            }   
        }

        .v-if-alert{
            font-size: 10px;
            line-height: 13px;
            font-weight: 500;
            color: #E8612A;
            &[color = 'gray']{
                color: #4A4A4A;
            }
        }
        .v-if-icon-info{
            width: 15px;
        }
    }
    .v-if-copyright{
        font-size: 11px;
        line-height: 12px;
        color: #4A4A4A;
    }
    .v-if-link-logo{
        width: 65px;
    }
}