/* ============== Bound =============== */
.bound-enter,
.bound-leave-to {
	opacity: 0;
}

.bound-enter-to {
	opacity: 1;
}

.bound-enter-active {
	@include cp-property(transition, 400ms ease-in !important);
}

.bound-leave-active {
	@include cp-property(transition, 300ms ease-out !important);
}

/* ============== Bound =============== */
.bound-chat-enter,
.bound-chat-leave-to {
	opacity: 0;
}

.bound-chat-enter-to {
	opacity: 1;
}

.bound-chat-enter-active {
	@include cp-property(transition, 400ms ease-in !important);
}

.bound-chat-leave-active {
	position: absolute !important;
	@include cp-property(transition, 0s !important);
}

/* ============== Slide right =============== */
.slide-right-enter {
	opacity: 0;
	position: absolute !important;
	@include cp-property(transform, translateX(-35px));
}

.slide-right-enter-to {
	opacity: 1;
	@include cp-property(transform, translateX(0));
}

.slide-right-leave-active {
	@include cp-property(transition, 400ms ease-out);
}

.slide-right-enter-active {
	@include cp-property(transition-delay, 400ms);
	@include cp-property(transition, 400ms ease-out);
}

.slide-right-leave-to {
	opacity: 0;
	position: absolute !important;
	z-index: 5;
	@include cp-property(transform, translateX(35px));
}

/* ============== Slide Left =============== */
.slide-left-enter {
	opacity: 0;
	position: absolute !important;
	@include cp-property(transform, translateX(35px));
}

.slide-left-enter-to {
	opacity: 1;
	@include cp-property(transform, translateX(0));
}

.slide-left-leave-active {
	@include cp-property(transition, 400ms ease-out);
}

.slide-left-enter-active {
	@include cp-property(transition-delay, 400ms);
	@include cp-property(transition, 400ms ease-out);
}

.slide-left-leave-to {
	opacity: 0;
	position: absolute !important;
	z-index: 5;
	@include cp-property(transform, translateX(-35px));
}

/* ============== Slide bottom =============== */
.slide-bottom-enter,
.slide-bottom-leave-to {
	@include cp-property(transform, translateY(100%));
}

.slide-bottom-enter-to {
	@include cp-property(transform, translateY(0));
}

.slide-bottom-leave-active {
	@include cp-property(transition, 300ms ease-out);
}

.slide-bottom-enter-active {
	@include cp-property(transition, 400ms ease-in);
}

/* ============== Slide right =============== */
.notification-enter {
	opacity: 0;
	@include cp-property(transform, translateX(50%));
}

.notification-enter-to {
	opacity: 1;
	@include cp-property(transform, translateX(0));
}

.notification-leave-active {
	@include cp-property(transition, 300ms ease-out);
}

.notification-enter-active {
	@include cp-property(transition, 300ms ease-out);
}

.notification-leave-to {
	opacity: 0;
	@include cp-property(transform, scale(0));
}

/* ============== dropdown component =============== */
.dropdown-enter {
	opacity: 0;
	@include cp-property(transform, translateY(-10px));
}

.dropdown-enter-to {
	opacity: 1;
	@include cp-property(transform, translateY(0));
}

.dropdown-leave-active {
	@include cp-property(transition, 200ms ease-in);
}

.dropdown-enter-active {
	@include cp-property(transition, 300ms ease-out);
}

.dropdown-leave-to {
	opacity: 0;
	pointer-events: none;
	@include cp-property(transform, translateY(-10px));
}

/* ============== dropdown component =============== */
.session-details-enter {
	opacity: 0;
	@include cp-property(transform, translateY(10px));
}

.session-details-enter-to {
	opacity: 1;
	@include cp-property(transform, translateY(0));
}

.session-details-leave-active {
	@include cp-property(transition, 200ms ease-in);
}

.session-details-enter-active {
	@include cp-property(transition, 300ms ease-out);
}

.session-details-leave-to {
	opacity: 0;
	pointer-events: none;
	@include cp-property(transform, translateY(10px));
}

/* ============== dropdown component =============== */
.videoRoom-enter {
	opacity: 0;
	@include cp-property(transform, scale(0.5));
}

.videoRoom-leave-to {
	display: none;
}

.videoRoom-enter-to {
	opacity: 1;
	@include cp-property(transform, scale(1));
}

.videoRoom-enter-active {
	@include cp-property(transition, all 200ms ease !important);
}

.videoRoom-leave-active {
	position: absolute;
}

/* ============== chat and users (chat) =============== */
/* ============== Bound =============== */
.chatHide-enter,
.chatHide-leave-to {
	width: 0px !important;
	@include cp-property(transform, scaleX(0));
}

.chatHide-enter-to {
	width: 140px !important;
	@include cp-property(transform, scaleX(1));
}

.chatHide-leave-active,
.chatHide-enter-active {
	@include cp-property(transition, 400ms ease);
	@include cp-property(transform-origin, 100%);
	overflow: hidden;
}