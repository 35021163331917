.join-onboarding {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
	z-index: 200;
	@include cp-property(transition, 400ms);

	.blurColor {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.7);
		cursor: pointer;
	}

	&.active {
		pointer-events: all;
		opacity: 1;
	}

	.v-modal-paper {
		@extend %center-align;
		width: 90%;
		max-width: 320px;
		max-height: 90%;

		.v-modal-title {
			border-radius: 4px 4px 0 0;

			.v-t {
				color: #fff;
				font-size: 1.3rem;
				font-weight: 300;
				line-height: 1.1em;
			}

			.v-btn-close {
				@extend %vertical-align;
				right: 1rem;
				fill: #fff;
				width: 16px;
				cursor: pointer;
				@include cp-property(transition, 400ms);

				&:hover {
					fill: $color-red-dark;
				}
			}
		}

		.v-modal-info {
			background: #fff;
			border-radius: 0 0 4px 4px;

			.vt-lesson-content {
				.vt-subject {
					.vt-icon {
						position: absolute;
						top: 1px;
						left: 0;
						width: 15px;
					}

					.vt-txt {
						font-size: 1rem;
						line-height: 1.1em;
						color: #000;
					}
				}
			}

			.vt-detail {
				font-size: 16px;
				font-weight: 500;
			}

			.v-lesson-btn .v-lb-txt {
				font-size: 18px;
			}
		}

	}
}

.config-video-room {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.7);
	opacity: 0;
	pointer-events: none;
	z-index: 200;
	@include cp-property(transition, 400ms);

	&.active {
		pointer-events: all;
		opacity: 1;
	}

	.btn-close {
		border: none;
		background: transparent;
		position: absolute;
		right: 3rem;
		top: 1.5rem;
		z-index: 5;

		>.icon {
			width: 20px;
			fill: $color--text3;
			@include cp-property(transition, 400ms);
		}

		&:hover {
			>.icon {
				fill: $color-red-light;
			}
		}

		&:focus {
			outline: none;
			outline-style: none;
			border: none;
		}
	}

	.msg-hover {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 15;
		opacity: 0;
		pointer-events: none;
		@include cp-property(transition, 400ms ease-out);

		.blurcolor {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(255, 255, 255, 0.9);
		}

		.content-msg-hover {
			@extend %center-align;
			width: 90%;
			max-width: 420px;
			z-index: 5;
		}

		.title {
			font-size: 20px;
			font-weight: 500;
		}

		.descrip {
			font-size: 14px;
			font-weight: 400;
		}

		&.active {
			opacity: 1;
			pointer-events: all;
		}
	}

	.config-content {
		@extend %center-align;
		width: 90%;
		max-width: 700px;
		max-height: 90%;

		.title {
			font-size: 24px;
			font-weight: 500;
		}

		.tip:not(.txt) {
			font-size: 14px;
			font-weight: 500;
			position: relative;
			padding-left: 20px;

			.tip-icon {
				@extend %vertical-align;
				left: 0;
				width: 14px;
			}
		}

		.gif {
			max-width: 100%;
			max-height: 220px;
		}

		.txt {
			font-size: 14px;
			font-weight: 300;

			&.tip {
				font-weight: 400;
			}

			&.bold {
				font-weight: 500;
			}
		}

		.btn-prev {
			position: absolute;
			left: 3rem;
			top: 1.5rem;
			padding-left: 18px;

			.icon {
				@extend %vertical-align;
				left: 0;
				width: 15px;
				@include cp-property(transition, 400ms);
			}

			&:hover .icon {
				fill: $color-red-dark !important;
			}
		}

		.btn-next,
		.btn-prev {
			background: transparent;
			border: none;
			font-size: 14px;
			cursor: pointer;
			@include cp-property(transition, 400ms);

			&:hover {
				color: $color-red-dark !important;
			}

			&:focus {
				outline-style: none;
				outline: none;
				border: none;
			}
		}

		.microphone {
			width: 100%;
			max-width: 240px;
			height: 16px;
			overflow: hidden;
			position: relative;
			background: rgba(0, 0, 0, 0.5);

			.audio-lvl {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 0;
				background: $color-green-light;
				@include cp-property(transition, 200ms linear);
			}

			.noMicrophone {
				position: absolute;
				left: 0;
				top: 18px;
				font-size: 10px;
				color: $color-red-light;
			}
		}

		.v-room-video {
			height: 180px;
			overflow: initial;
		}

		#listening {
			opacity: 0;
			pointer-events: none;
			position: absolute;
		}

		.btn-test-sound {
			@extend %removeBtnStyles;
			line-height: 20px;

			.sound-txt {
				font-size: 18px;
				text-decoration: underline;
				color: $color-blue-main;
				@include cp-property(transition, 300ms);
			}

			&.active {
				pointer-events: none;

				.sound-txt {
					text-decoration: initial;
					color: $color--text3;
				}
			}

			&:hover {
				.sound-txt {
					color: $color-blue-dark;
				}
			}
		}

		.icon-hearing {
			width: 160px;
			fill: $color-green-dark;
		}

		.btn-enabled {
			width: 60px;
			height: 32px;
			position: relative;
			border: 1px solid $color--border;
			border-radius: 4px;
			background: transparent;

			.btn-icon {
				@extend %center-align;
				max-width: 20px;
				max-height: 20px;
			}

			&:focus {
				outline: none;
			}
		}
	}
}

.v-room-header {
	background: #fff;
	width: 100%;
	max-width: 350px;
	position: fixed;
	right: 0;
	top: 0;
	height: 100%;
	z-index: 150;
	@include cp-property(transition, 400ms ease);
	@include cp-property(transform, translateX(100%));

	&.show {
		@include cp-property(transform, translateX(0));
		@include cp-property(transition, 400ms ease);
	}

	.v-room-chat {
		width: 100%;

		.tabs-uc,
		.v-users {
			background: #fff;
		}

		.v-chat {
			border-radius: 0;
			overflow: hidden;
		}

		.v-users .vol-bars {
			position: relative;
			left: initial;
			top: initial;
			@include cp-property(transform, initial);
		}
	}

	.v-actions {
		height: 50px;
		background: #fff;
		border-radius: 0 0 0 5px;
		position: absolute;
		right: 100%;
		top: 0;

		.v-content-ricon {
			width: 25px;
			height: 25px;
			position: relative;

			.v-total-users,
			.v-total-msgs {
				position: absolute;
				top: 0;
				left: 100%;
			}

			.v-total-users {
				font-size: 10px;
				line-height: 12px;
				color: #383838;
				@include cp-property(transform, translate(-12%, -20%));
			}

			.v-total-msgs {
				width: 12px;
				height: 12px;
				background: #FF1100;
				border-radius: 50%;
				@include cp-property(transform, translate(10%, -30%));

				span {
					@extend %center-align;
					font-size: 8px;
					line-height: 10px;
					color: #fff;
				}
			}

			.v-ricon {
				width: 100%;
				height: 100%;
			}
		}

		.v-sh-chat {
			width: 140px;
		}

		.v-rduration {
			width: 80px;
			max-width: 80px;
		}

		.v-ruser {
			width: 100px;
			max-width: 100px;

			.icon-contract {
				@extend %center-align;
				width: 22px;
			}

			.v-room-video {
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;

				.v-user {
					left: 0;
					bottom: 0;
					right: 0;
					background: transparent;
					padding: 2px 4px 0;

					.vol-bars {
						left: initial;
						top: initial;
						right: 4px;
						bottom: 3px;
						height: 14px;
						@include cp-property(transform, initial);

						.content-vol-bar {
							width: 2px;
							margin-left: 0px;

							&:nth-child(1) {
								height: 8px;
							}

							&:nth-child(2) {
								height: 10px;
							}

							&:nth-child(3) {
								height: 14px;
							}
						}
					}

					.mute-vol {
						left: initial;
						top: initial;
						right: 4px;
						bottom: 3px;
						width: 14px;
						height: 14px;
						@include cp-property(transform, initial);
					}

					.v-user-name {
						font-size: 12px;
					}
				}
			}

			.bb-blue,
			.bb-green {
				background: #000;
			}

			.bb-blue {
				border-bottom: 2px solid $color-blue-main;
			}

			.bb-green {
				border-bottom: 2px solid $color-green-main;
			}
		}

		.v-rduration {
			color: $color--text2;

			.v-duration {
				line-height: 15px;
			}

			.v-duration-title {
				font-size: 10px;
			}

			.v-duration-time {
				font-size: 12px;

				.time-bold {
					font-weight: 500;
				}
			}
		}

		.v-users {
			border-radius: 0 0 0 5px;
		}

		.v-rchat {
			.v-content-ricon {
				width: 23px;
				height: 23px;
			}
		}

		.v-users,
		.v-rchat {
			cursor: pointer;
			width: 50%;
			position: relative;
			@include cp-property(transition, 400ms ease);

			&:hover {
				background: rgba(58, 80, 107, 0.1);
			}
		}

		.v-users,
		.v-rchat {
			&:hover {
				.msg-hover {
					opacity: 1;
					@include cp-property(transform, translateX(-50%) translateY(0) scale(1));
					@include cp-property(transition, 400ms ease);
				}
			}
		}

		.msg-hover {
			position: absolute;
			left: 50%;
			top: 110%;
			font-size: 10px;
			line-height: 12px;
			text-align: center;
			min-width: 50px;
			padding: .2rem .5rem;
			border-radius: 5px;
			background: #fff;
			color: $color--text2;
			pointer-events: none;
			opacity: 0;
			@include cp-property(transform, translateX(-50%) translateY(-10px) scale(0.5));
			@include cp-property(transition, 100ms ease);
		}
	}

	.v-room-video video {
		object-fit: cover;
	}

	.v-room-video video:not(.sharing) {
		@include cp-property(transform, scaleX(-1));
	}

	.content-header {
		width: 100%;
	}

	.v-close-chat {
		min-height: 50px;
		background: #fff;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.20);

		.v-rquote {
			font-size: 18px;
			color: #383838;
		}

		.v-btn-close {
			@extend %removeBtnStyles;

			.icon-cross {
				width: 22px;
				fill: #383838;
				@include cp-property(transition, 300ms ease);
			}

			&:hover {
				.icon-cross {
					fill: $color-red-dark;
				}
			}
		}
	}

	.content-tabs {
		//@include cp-style(height, calc, unquote('(100% - 94px)'));
		height: calc(100% - 94px);
	}

	.v-tab-chat {
		@extend %removeBtnStyles;
		padding: 10px 15px;
		text-align: left;
		line-height: 18px;
		color: #383838;
		opacity: 0.7;
		position: relative;
		@include cp-property(transition, 300ms ease);

		.v-total-msgs {
			width: 12px;
			height: 12px;
			background: #FF1100;
			border-radius: 50%;
			position: absolute;
			top: -2px;
			right: -6px;
			@include cp-property(transform, translate(10%, -30%));

			span {
				@extend %center-align;
				font-size: 8px;
				line-height: 10px;
				color: #fff;
			}
		}

		&:last-child {
			.v-icon-tab {
				width: 22px;
			}
		}

		.v-icon-tab {
			width: 24px;
			fill: #383838;
			vertical-align: bottom;
		}

		&::after {
			content: '';
			@extend %horizontal-align;
			bottom: 0;
			height: 3px;
			width: 0;
			@include cp-property(transition, 300ms ease);
		}

		&.bb-blue::after {
			background: $color-blue-main;
		}

		&.bb-green::after {
			background: $color-green-main;
		}

		&.active {
			opacity: 1;

			&::after {
				width: 100%;
			}
		}

		&:hover:not(.active) {
			opacity: 0.9;

			&::after {
				width: 20%;
			}
		}
	}
}

.vol-bars {
	@extend %center-align;
	left: 1rem;
	height: 24px;

	.content-vol-bar {
		width: 4px;
		border-radius: 2px;
		margin-left: 1px;
		margin-right: 1px;
		background: rgba(255, 255, 255, 0.2);
		position: relative;
		z-index: 10;

		.vol-bar {
			height: 100%;
			background: $color-blue-light;
			border-radius: 2px;
			@include cp-property(transition, 50ms linear);
		}

		&:nth-child(1) {
			height: 15px;
		}

		&:nth-child(2) {
			height: 21px;
		}

		&:nth-child(3) {
			height: 27px;
		}
	}
}

.v-room-video {
	overflow: hidden;

	//background: #000;
	video {
		position: absolute;
		left: 0;
		top: 0;
		object-fit: contain;
		object-position: center center;
		margin: 0;
		padding: 0;
		border: 0;
	}

	&.speaking::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		box-shadow: inset 0 0 0 2px $color-blue-main;
		border-radius: 3px;
		z-index: 5;
	}

	.noVideo {
		position: absolute;
		left: 0;
		top: 0;

		&.cam-error {
			background: $color--whiteDark;
		}

		.noCamera {
			@extend %center-align;
			color: $color--text;
			font-size: 24px;
			text-align: center;
			font-weight: 500;
			line-height: 26px;
			width: 90%;
		}

		.icon-noVideo {
			@extend %center-align;
			width: 20%;
			height: 50%;
			fill: $color--gray;
		}

		.content-noVideo-img {
			@extend %center-align;

			.noVideo-img {
				position: relative;
				border-radius: 50%;
				z-index: 20;
			}

			.microphone {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				z-index: 10;
				background: rgba(229, 229, 229, 0.2);
				@include cp-property(transition, 100ms linear);
			}

			.bound-vol {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				box-shadow: inset 0 0 0 1px rgba(229, 229, 229, 0.6);
				border-radius: 50%;

				&.show {
					@include cp-property(animation, audioBound 1.5s ease-out);
				}
			}
		}
	}

	.expand-and-mic {
		position: absolute;
		left: 50%;
		@include cp-property(transform, translate(-50%, -50%));
		height: 44px;
		width: 100px;
		background: rgba(58, 80, 107, 0.32);
		border-radius: 22px;
		opacity: 0;
		z-index: 10;
		@include cp-property(transition, 400ms ease);

		.action-video {
			width: 34px;
			height: 34px;
			@extend %removeBtnStyles;
			background: #d8d8d8;
			border-radius: 50%;
			@include cp-property(transition, 400ms ease);

			&.btn-expand {
				background: #fff;

				.icon-eam {
					width: 100%;
					fill: #3A506B;

					circle {
						display: none;
					}
				}

				&.active {
					.icon-eam {
						fill: #fff;
					}
				}

				&.active.s-eam {
					background: $color-blue-main;
				}

				&.active.t-eam {
					background: $color-green-main;
				}
			}

			&:hover {
				background: #d8d8d8;

				.msg-hover {
					opacity: 1;
					@include cp-property(transform, translateX(-50%) translateY(0) scale(1));
					@include cp-property(transition, 400ms ease);
				}
			}
		}

		.msg-hover {
			position: absolute;
			left: 50%;
			top: 110%;
			font-size: 10px;
			line-height: 12px;
			text-align: center;
			min-width: 50px;
			width: intrinsic;
			width: max-content;
			width: -moz-max-content;
			width: -webkit-max-content;
			padding: .2rem .5rem;
			border-radius: 5px;
			background: #fff;
			box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
			color: $color--text2;
			pointer-events: none;
			opacity: 0;
			z-index: 20;
			@include cp-property(transform, translateX(-50%) translateY(-10px) scale(0.5));
			@include cp-property(transition, 100ms ease);
		}
	}

	&:hover {
		.expand-and-mic:not(.hide) {
			opacity: 1;
		}
	}

	.v-user {
		position: absolute;
		left: 1.2rem;
		bottom: 1.2rem;
		padding: .6rem 1rem .6rem 2rem;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 8px;
		line-height: 14px;
		z-index: 10;
		@include cp-property(transition, 400ms ease);

		.mute-vol {
			@extend %center-align;
			left: 1rem;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background: #990606;

			.icon-mic {
				@extend %center-align;
				width: 100%;
				height: 100%;

				circle {
					display: none;
				}
			}
		}

		.v-user-name {
			font-size: 14px;
			font-weight: 400;
			color: #fff;
		}

		.tag-tutor {
			display: inline-block;
			background: $color-green-main;
			border-radius: 2px;
			padding: .05rem .2rem 0.1rem .2rem;

			span {
				font-size: 12px;
				font-weight: 300;
				color: #fff;
			}
		}
	}
}

.v-onboarding-video {
	max-height: 400px;
	padding-top: 56.25%;
	border-radius: 5px;
	overflow: hidden;

	.v-ov-content {
		position: absolute;
		left: 0;
		top: 0;
	}

	video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center center;
		margin: 0;
		padding: 0;
		border: 0;
		@include cp-property(transform, scaleX(-1));
	}

	.noVideo {
		.noCamera {
			@extend %center-align;
			color: $color--text;
			font-size: 24px;
			text-align: center;
			font-weight: 500;
			line-height: 26px;
			width: 90%;
		}

		.icon-noVideo {
			@extend %center-align;
			width: 20%;
			height: 50%;
			fill: $color--gray;
		}

		.content-noVideo-img {
			@extend %center-align;

			.noVideo-img {
				position: relative;
				border-radius: 50%;
				z-index: 20;
			}

			.microphone {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				z-index: 10;
				background: rgba(229, 229, 229, 0.2);
				@include cp-property(transition, 100ms linear);
			}

			.bound-vol {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				box-shadow: inset 0 0 0 1px rgba(229, 229, 229, 0.6);
				border-radius: 50%;

				&.show {
					@include cp-property(animation, audioBound 1.5s ease-out);
				}
			}
		}
	}

	.v-actions {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 70px;

		.vol-bars {
			left: 2rem;
			height: 27px;

			&::before {
				content: '';
				@extend %center-align;
				width: 40px;
				height: 40px;
				background: rgba(0, 0, 0, 0.4);
				border-radius: 2px;
				z-index: 5;
			}

			.content-bol-bar {
				z-index: 10;
			}
		}

		.v-audioVideo {
			@extend %center-align;

			.hl-btn {
				background: #fff;
				position: relative;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				position: relative;
				cursor: pointer;
				@include cp-property(transition, 300ms ease);

				.icon-action {
					@extend %center-align;
					fill: #3A506B;
					width: 22px;
					height: 22px;

					&.i-100 {
						width: 90%;
						height: 90%;

						circle {
							display: none;
						}
					}
				}

				&:hover {
					background: #d8d8d8;

					&.disabled {
						background: #990606;
					}

					.msg-hover {
						opacity: 1;
						@include cp-property(transform, translateX(-50%) translateY(0) scale(1));
						@include cp-property(transition, 400ms ease);
					}
				}

				&.disabled,
				&.close-room {
					background: #E82719;

					.icon-action {
						fill: #fff;
					}
				}

				.msg-hover {
					position: absolute;
					left: 50%;
					bottom: 115%;
					font-size: 10px;
					line-height: 12px;
					text-align: center;
					min-width: 50px;
					width: intrinsic;
					width: max-content;
					width: -moz-max-content;
					width: -webkit-max-content;
					padding: .2rem .5rem;
					border-radius: 5px;
					background: #fff;
					color: $color--text2;
					pointer-events: none;
					opacity: 0;
					@include cp-property(transform, translateX(-50%) translateY(15px) scale(0.5));
					@include cp-property(transition, 100ms ease);
				}
			}
		}
	}
}

.v-room-users {
	.v-users {
		width: 350px;

		.v-user-tabs {
			height: 40px;
			box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.10);

			.v-user-tab {
				@extend %removeBtnStyles;
				@include cp-property(transition, 300ms ease);

				.content-tab-icon {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background: rgba(255, 255, 255, 0.4);
					@include cp-property(transition, 300ms ease);

					.tab-icon {
						width: 20px;
						@extend %center-align;
						fill: #3a506b;
					}
				}

				&:hover {
					background: rgba(0, 0, 0, 0.10);
				}
			}
		}

		.user-info {
			@include cp-style(max-width, calc, unquote('(100% - 34px)'));
		}

		.v-room-user {
			min-height: 50px;

			/*.user-info{
				.v-hover{
					@include cp-style(width, calc, unquote('(100% - 34px)'));
				}
			}*/
			.audio-mute {
				width: 18px;
				height: 18px;
				background: #e82719;
				border-radius: 50%;
				position: relative;

				.icon-mic {
					@extend %center-align;
					width: 100%;
					height: 100%;

					circle {
						display: none;
					}
				}
			}

			.vol-bars {
				height: 24px;
				width: 18px;

				.content-vol-bar {
					width: 2px;
					border-radius: 1px;
					margin-left: 1px;
					margin-right: 1px;
					background: rgba(0, 0, 0, 0.2);
					position: relative;

					.vol-bar {
						height: 100%;
						background: $color-blue-light;
						border-radius: 2px;
						@include cp-property(transition, 50ms linear);
					}

					&:nth-child(1) {
						height: 10px;
					}

					&:nth-child(2) {
						height: 14px;
					}

					&:nth-child(3) {
						height: 20px;
					}
				}
			}

			.v-user-sharing {
				.icon-sharing {
					@extend %center-align;
					z-index: 5;
					width: 18px;

				}

				&.t-eu {
					box-shadow: inset 0 0 0 1px $color-green-main;

					.icon-sharing {
						fill: $color-green-main;
					}
				}

				&.s-eu {
					box-shadow: inset 0 0 0 1px $color-blue-main;

					.icon-sharing {
						fill: $color-blue-main;
					}
				}
			}

			.v-user-sharing,
			.v-user-photo {
				width: 35px;
				height: 35px;
				border-radius: 50%;
				position: relative;
				cursor: pointer;

				.expanding-user {
					opacity: 0;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					border-radius: 50%;
					@include cp-property(transition, 100ms ease);
					z-index: 10;

					.icon-pin {
						fill: #fff;
						width: 100%;

						circle {
							display: none;
						}
					}

					&.t-eu {
						background: rgba($color-green-main, 0.8);
					}

					&.s-eu {
						background: rgba($color-blue-main, 0.8);
					}

					&.active {
						opacity: 1;
					}
				}

				.msg-hover {
					position: absolute;
					left: 50%;
					top: 110%;
					font-size: 10px;
					line-height: 12px;
					text-align: center;
					min-width: 50px;
					width: intrinsic;
					width: max-content;
					width: -moz-max-content;
					width: -webkit-max-content;
					padding: .2rem .5rem;
					border-radius: 5px;
					background: #fff;
					box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
					color: $color--text2;
					pointer-events: none;
					opacity: 0;
					z-index: 20;
					@include cp-property(transform, translateX(-50%) translateY(-10px) scale(0.5));
					@include cp-property(transition, 100ms ease);
				}

				&:hover {
					.msg-hover {
						opacity: 1;
						@include cp-property(transform, translateX(-50%) translateY(0) scale(1));
						@include cp-property(transition, 400ms ease);
					}
				}
			}

			.label-you {
				font-size: 14px;
				font-weight: 400;
				color: #383838;
			}

			.txt-sharing {
				font-size: 12px;
				line-height: 16px;
				color: #383838;
			}

			.is-tutor {
				background: $color-green-main;
				border-radius: 2px;
				line-height: 20px;

				span {
					font-size: 14px;
					font-weight: 300;
					color: #fff;
				}
			}
		}
	}
}

.v-room-toolbar {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 70px;
	background: rgba(58, 80, 107, 0.4);
	z-index: 100;
	@include cp-property(transition, 400ms ease);

	&.hide {
		@include cp-property(transform, translateY(100%));
	}

	&.compact {
		@include cp-style(width, calc, unquote('(100% - 350px)'));
	}

	.btn-hover {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		cursor: pointer;
		z-index: 10;
	}

	.more-info .cloud {
		bottom: 32px !important;
		cursor: default;
	}

	.btn-session-details {
		@extend %removeBtnStyles;
		@include cp-property(transition, 300ms ease);

		.bsd-txt {
			font-weight: 500;
			color: #fff;
			position: relative;

			.icon-arrow {
				width: 20px;
				margin-left: 5px;
				fill: #fff;
				vertical-align: text-bottom;
			}
		}

		.icon-subject {
			width: 25px;
			fill: #fff;
		}

		&:hover {
			background: rgba(58, 80, 107, 0.7);
		}

		&.active {
			background: #3A506B;
		}
	}

	.input-hide {
		opacity: 0;
		position: absolute;
		bottom: 0;
		left: 0;
		pointer-events: none;
	}

	.v-session-details {
		position: absolute;
		left: 1rem;
		bottom: 120%;
		width: 90%;
		max-width: 400px;
		border-radius: 5px;
		overflow: hidden;

		.bg-blue {
			background: $color-blue-main;
		}

		.bg-green {
			background: $color-green-main;
		}

		.v-title {
			font-size: 18px;
			font-weight: 500;
			color: #fff;
		}

		.v-body {
			background: #fff;

			.sd-item {
				padding-left: 25px;
				position: relative;

				.icon-item {
					position: absolute;
					@extend %vertical-align;
					left: 0;
					width: 16px;
					height: 20px;
					fill: #3A506B;
				}

				.sd-item-info {
					font-size: 14px;
					line-height: 16px;
					color: #3A506B;

					.sd-item-info {
						font-weight: 500;
					}
				}
			}
		}
	}

	.toolbar-menu-more {
		position: absolute;
		right: 1rem;
		bottom: 120%;
		width: 45%;
		max-width: 180px;
		border-radius: 5px;
		background: #fff;

		ul {
			list-style: none;
		}

		.l-top {
			border-top: 1px solid #DEDEDE;
		}

		.toolbar-btn-menu,
		.toolbar-btn-submenu {
			min-height: 30px;
			padding-left: .5rem;
			padding-right: .5rem;
			cursor: pointer;
			border-radius: 2px;
			@include cp-property(transition, 300ms ease);

			&:hover,
			&.active {
				background: #E8E8E8;
			}
		}

		.toolbar-btn-icon {
			width: 18px;
			height: 18px;
			margin-right: 1rem;
			fill: #3A506B;
		}

		.toolbar-btn-txt {
			font-size: 12px;
			line-height: 14px;
			color: #3A506B;
		}

		.toolbar-submenu-more {
			position: absolute;
			right: 105%;
			bottom: -0.25rem;
			width: 45vw;
			max-width: 180px;
			border-radius: 5px;
			background: #fff;
			overflow: hidden;
		}
	}

	.close-room,
	.action-toolbar {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		position: relative;
		cursor: pointer;
		@include cp-property(transition, 300ms ease);

		.icon-action {
			@extend %center-align;
			width: 22px;
			height: 22px;

			&.i-100 {
				width: 90%;
				height: 90%;

				circle {
					display: none;
				}
			}
		}
	}

	.action-toolbar {
		background: #fff;
		position: relative;

		.c-icon-action {
			border-radius: 50%;
			z-index: 10;
		}

		.icon-action {
			fill: #3A506B;
		}

		.more-info {
			z-index: 5;
		}

		&:hover {
			background: #d8d8d8;

			&.disabled {
				background: #990606;
			}

			.msg-hover {
				opacity: 1;
				@include cp-property(transform, translateX(-50%) translateY(0) scale(1));
				@include cp-property(transition, 400ms ease);
			}
		}

		&.disabled,
		&.close-room {
			background: #E82719;

			.icon-action {
				fill: #fff;
			}
		}

		.msg-hover {
			position: absolute;
			left: 50%;
			bottom: 115%;
			font-size: 10px;
			line-height: 12px;
			text-align: center;
			min-width: 50px;
			width: intrinsic;
			width: max-content;
			width: -moz-max-content;
			width: -webkit-max-content;
			padding: .2rem .5rem;
			border-radius: 5px;
			background: #fff;
			color: $color--text2;
			pointer-events: none;
			opacity: 0;
			z-index: 5;
			@include cp-property(transform, translateX(-50%) translateY(15px) scale(0.5));
			@include cp-property(transition, 100ms ease);
		}

		.more-info {
			@extend %horizontal-align;
			top: 0;
		}
	}

	.btn-screen,
	.btn-more {
		@extend %removeBtnStyles;
		@include cp-property(transition, 300ms ease);

		.v-btn-icon {
			width: 28px;
			height: 28px;
		}

		.v-btn-txt {
			font-size: 11px;
			font-weight: 300;
			color: #fff;
		}

		&:hover,
		&.active {
			background: rgba(58, 80, 107, 0.7);
		}
	}
}

.room-settings {
	.vf-title {
		line-height: 100%;
		font-size: 13px;
		font-weight: 400;
	}

	.v-field {
		.field_ {
			font-size: 13px;
		}
	}

	.v-room-video {
		padding-top: 56.25%;
		max-height: 200px;

		>div {
			position: absolute !important;
			left: 0;
			top: 0;
		}
	}

	.v-field {
		.v-placeholder {
			font-size: 19px;
		}
	}

	.vf-descript {
		color: #9B9B9B;
		font-size: 13px;
		line-height: 16px
	}

	.vf-switch {
		width: 28px;
		height: 8px;
		border-radius: 4px;
		background: rgba(74, 74, 74, 0.5);
		position: relative;
		@include cp-property(transition, 300ms ease);

		.vf-onOff {
			@extend %vertical-align;
			left: 0;
			height: 14px;
			width: 14px;
			background-color: #979797;
			box-shadow: 0 0 0 4px rgba(74, 74, 74, 0.29);
			border-radius: 50%;
			cursor: pointer;
			@include cp-property(transition, 300ms ease);
		}

		&.active {
			background: rgba(33, 136, 252, 0.4);

			.vf-onOff {
				left: 14px;
				background-color: $color-blue-main;
				box-shadow: 0 0 0 4px rgba(33, 136, 252, 0.5);
			}
		}

		&.disabled {
			opacity: 0.6;

			.vf-onOff {
				pointer-events: none;
			}
		}
	}
}