#get-app{
	.info{
		max-width: 475px;
		min-width: 420px;
        .title{
        	font-size: 30px;
        	color: $color-blue-main;
            font-weight: 400;
        }
        .subtitle{
        	font-size: 19px;
        	font-weight: 500;
        	margin-bottom: 2rem;
        }
        .img-app{
        	cursor: pointer;
			display: inline-block;
			max-height: 35px;
			max-width: 48%;
        }
        .list-benefits{
        	padding-left: 0;
        	dt{
        		font-size: 19px;
        		font-weight: 500;
        	}
        	dd{
        		font-size: 15px;
        		padding-left: 15px;
        		margin-bottom: 0;
        	}
        }
	}
	.img{
		max-width: 540px;
		min-width: 420px;
		img{
            @include cp-style(max-width, calc, unquote('(100% - 80px)'));
			max-height: 550px;
		}
        .qr-code{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 145px;

            .qr{
                width: 120px;
                [pid="0"]{
                    fill: transparent;
                }
            }
            .txt-qr{
                @include cp-property(transform, rotate(-10deg));
                .arrow{
                    position: absolute;
                    bottom: 100%;
                    right: 0;
                    width: 45px;
                    @include cp-property(transform, rotate(110deg) scale(-1, 1) translate(23%,-65%));
                }
                .txt-top{
                    font-size: 20px;
                    line-height: 20px;
                    font-family: 'Caveat', cursive;
                    font-weight: 700;
                }
                .txt-down{
                    font-size: 16px;
                    line-height: 18px;
                    font-family: 'Caveat', cursive;
                    font-weight: 700;
                }
            }
        }
	}

    &.tutor{
        .info{
            max-width: 510px;
            .title{
                max-width: 300px;
            }
        }
    }
}