#tutor-profile{
	.score{
		height: 35px;
		.vue-star-rating-star{
		    margin-right: 5px;
	    }
	}
	.see-tutor-profile{
		@extend %horizontal-align;
		bottom: 10px;
		color: $color-blue-main;
		font-weight: 500;
		border: none;
		background: transparent;
		cursor: pointer;
		opacity: 0;
		pointer-events: none;
		@include cp-property(transition, 400ms);
		&.show{
			opacity: 1;
			pointer-events: all;
			@include cp-property(transition-delay, 500ms);
			
		}
		&:focus{
			outline: none;
		}
		.arrow{
			width: 18px;
			fill: $color-blue-main;
		}
	}
	.image-tutor{
		position: relative;
		margin-top: -70px;
		#circles1{
			position: absolute;
			left: 0;
			top: 0;
			@include cp-property(transform, translate(-25%,-25%));
			z-index: 5;
		}
		#circles2{
			position: absolute;
			right: 0;
			bottom: 0;
			@include cp-property(transform, translate(25%,25%));
			z-index: 5;
		}
		.content-img{
			z-index: 10;
		}
	}
	.name-tutor{
		font-size: 35px;
		font-weight: 300;
	}
	.section-tutor{
		max-width: 800px;
		.line::after{
			content: '';
			position: absolute;
			left: 1rem;
			bottom: 0;
			width: 40px;
			height: 1px;
			background: $color--text3;
		}
		> .title{
		    font-size: 18px;
		    max-width: 140px;
		    > h4{
			    font-size: 18px;
		    }
	    }
	    > .about{
	    	.description{
	    		@include cp-property(transition, 300ms);
	    		overflow: hidden;
	    		&.suspend{
	    			position: relative;
	    			&::after{
	    				content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        z-index: 0;
                        box-shadow: 0 0 8px 15px rgba(255,255,255,0.9);
	    			}
	    		}
	    	}
            .txt{
        	    white-space: pre-wrap;
        	    line-height: 18px;
        	    color: $color--text2;
        	    font-family: 'Helvetica Neue';
        	    
        	    font-weight: 400;
            }
	    }
	}
	.btn-edit{
		border: none;
		background: transparent;
		color: $color-green-light;
		font-size: 14px;
		font-weight: 500;
		padding-left: 18px;
		position: relative;
		@include cp-property(transition, 400ms);
		.icon{
			position: absolute;
			top: 2px;
			left: 0;
			fill: $color-green-light;
			width: 16px;
			@include cp-property(transition, 400ms);
		}
		&:focus{
			outline: none;
		}
		&:hover{
			color: $color-green-dark;
			.icon{
				fill: $color-green-dark;
			}
		}
	}
	.btn-read-more{
		font-size: 16px;
		color: $color-blue-main;
		font-weight: 500;
		border: none;
		background: transparent;
		padding: 0;
		&:focus{
			outline: none;
		}
	}
	.schedule{
		.day{
			font-size: 13px;
			font-weight: 500;
			padding-left: 35px;
			max-width: 120px;
			text-transform: capitalize;
			display: block;
			color: $color--text2;
			.pt{
				@extend %vertical-align;
				left: 15px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				z-index: 5;
			}
			&::before{
				content: '';
				position: absolute;
				top: 5px;
				left: 15px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: #c6c6c6;
			}
			&.active::before{
                background: $color-blue-main;
			}
		}
		&.tutor{
			.day.active::before{
				background: $color-green-main;
			}
		}
		.dates{
            font-size: 13px;
            font-weight: 300;
            color: $color--text2;
            .date{
            	margin-right: 1rem;
            	min-width: 124px;
            	display: inline-block;
            }
		}
	}
	.subject{
		border-bottom: 1px solid #ebebeb;
		.info{
			&.namet{
				/*max-width: 250px;
                min-width: 180px;*/
			    > div{
			    	@include cp-style(max-width, calc, unquote('(100% - 45px)'));
			    }
			}
			&.name{
			    /*max-width: 220px;
			    min-width: 180px;*/
			    > div{
			    	@include cp-style(max-width, calc, unquote('(100% - 45px)'));
			    }
		    }
			.icon-subject{
				width: 35px;
			}
			.name-subject{
				overflow:hidden;
                white-space:nowrap;
                text-overflow: ellipsis;
				.icon{
					width: 18px;
					fill: $color--text3;;
				}
			}
			.icon-type{
				width: 25px;
				height: 25px;
				border-radius: 50%;
				position: relative;
				&.one{
					background: $color-blue-main;
				}
				&.group{
					background: $color--yellow;
				}
				.icon{
					@extend %center-align;
					width: 65%;
					height: 65%;
					fill: #fff;
				}
			}
			/*&.data,&.type{
				max-width: 110px;
			}*/
			&.data{
				.icon{
					@extend %vertical-align;
					width: 20px;
					left: 0;
				}
			}
			.type,.txt1{
				font-size: 10px;
				font-weight: 500;
				color: $color--text2;
			}
			.area{
				font-size: 12px;
				color: $color--text3;
				overflow:hidden;
                white-space:nowrap;
                text-overflow: ellipsis;
			}
		}
		
		.btn-schedule{
			max-width: 170px;
		}
	}
	.preview{
        .info-review{
        	min-width: 300px;
        	.content-img{
        		max-width: 60px;
        		&.v-group{
        			width: 60px;
        			height: 60px;
        			background: $color--yellow;
        			.icon-group{
        				@extend %center-align;
        				width: 36px;
        			}
        		}
        	}
        	.name-review{
        		font-size: 1.14rem;
                line-height: 1.2em;
        		font-weight: 500;
        	}
        	.name-subject{
        		font-size: 12px;
        		font-weight: 500;
        		color: #9B9B9B;
        	}
        	.txt-preview{
        		font-size: 12px;
        	}
        }
        .score-date{
        	max-width: 190px;
        	.date{
        		font-size: 12px;
        		font-weight: 500;
        	}
        }
        .score .score-n{
            font-size: 22px;
        }
	}
	.schedule-lesson{
		max-width: 300px;
		.tutor-info{
			position: relative;
			&::after{
				content: '';
				@extend %horizontal-align;
				bottom: 0;
				height: 1px;
				width: 30px;
				background: $color--text3;
			}
		}
		.content-img{
			max-width: 52px;
		}
		.name{
			font-size: 18px;
		}
		.score{
			.star{
				width: 20px;
			}
			.score-n{
				font-size: 24px;
			}
		}
		.btnA{
			height: 40px;
		}
	}
	.schedule-lesson-fixed{
		position: fixed;
		right: 0;
		bottom: 0;
		background: #fff;
		box-shadow: 0 0 5px rgba(0,0,0, 0.3);
		@include cp-property(transition, 500ms);
	}
	.schedule-form{
		.step{
			.lists-select, .v-schedule, .v-places-list, .content-btn-step{
				max-width: 700px;
			}
			.content-btn-sumbit{
				max-width: 550px;
			}
		}
		.btn-close-step{
			button{
				border: none;
				background: transparent;
				cursor: pointer;
				svg{
					width: 20px;
					fill: $color--text2;
					@include cp-property(transition, 400ms);
				}
				&:hover{
					svg{
						fill: $color-red-light;
					}
				}
				&:focus{
					outline: none;
				}
			}
	    }
		.btn-step{
			height: 36px;
			font-size: 20px;
			max-width: 200px;
		}
	    .title-step{
		    font-size: 26px;
		    font-weight: 300;
	    }
	}

	.similar-profiles{
		.title{
			max-width: initial;
		}
		.tutors{
			.item{
				.tutor{
					position: relative;
					cursor: pointer;
					.img{
						height: 200px;
					}
					.name{
						font-size: 18px;
						font-weight: 500;
						color: $color--text2;
					}
					.matter{
						font-size: 12px;
						color: $color--text2;
					}
					.btn-see{
                        position: absolute;
                        top: 15px;
                        right: 25px;
                        border: none;
                        background: transparent;
                        color: #fff;
                        font-size: 12px;
                        padding-right: 20px;
                        font-weight: normal;
                        opacity: 0;
                        pointer-events: none;
                        @include cp-property(transition, 400ms ease-out);
                        .icon{
                            @extend %vertical-align;
                            top: 45%;
                            right: 0;
                            fill: #fff;
                            width: 15px;
                        }
                        &:focus{
                            outline: none;
                        }
                    }
                    &:hover{
                        .btn-see{
                            right: 15px;
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
				}
			}
		}
	}
}