.v-tutor-sch-session{
    .t-sch-content{
        max-width: 650px;
        .t-sch-head{
            .t-sch-title{
                font-size: 1.5rem;
                font-weight: 500;
                line-height: 1.2em;
                color: #000;
            }
            .t-sch-label{
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.2em;
                color: $color-green-main;
            }
        }
    }

    #search-autocomplete .dropdown .dropdown-item{
        &:active{
            color: initial;
            background: initial;
        }
    }

    .v-paper{
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 3px rgba(0,0,0,0.12);

        .v-sch-t-title{
            font-size: 1rem;
            font-weight: 500;
            color: #000;
        }

        .v-sch-summary-t{
            border-top: 2px solid #F2F2F2;
        }

        .v-sch-summary-content{
            background: #F4F7FC;
            border-radius: 4px;
            .v-sch-summary-icon{
                width: 18px;
                svg{
                    @extend %vertical-align;
                    width: 17px;
                    height: 18px;
                    fill: #000;
                }   
            }
            .v-sch-summary-txt{
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.2em;
                color: #000;
                &.bold{
                    font-weight: 500;
                }
            }
            .v-lesson-place{
                .v-hover .txt-hover{
                    font-size: 1rem !important;
                }
                .v-lp-icon{
                    width: 18px;
                }
            }
        }
    }
}