@import './global.scss';
@import './fields.scss';
@import './schedule.scss';
@import './lessons.scss';
@import './chat.scss';
@import './room.scss';
@import './availability.scss';
@import './notifications.scss';
@import './formats.scss';
@import './schedule-session-t.scss';

/* ================= Header =================== */
#header-main {
	height: 60px;
	color: #fff;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 200;

	.logo {
		max-width: 260px;
		overflow: hidden;
		@include cp-property(transition, 500ms ease);

		img {
			max-width: 230px;
			max-height: 45px;
			cursor: pointer;
		}
	}

	.btn-menu {
		.content-btn-menu {
			max-width: 60px;
			@include cp-property(transition, 500ms ease);

			.btn-menu-conpact {
				background: transparent;
				border: none;

				&:focus {
					outline: none;
				}

				.icon {
					@extend %center-align;
					fill: #fff;
					width: 26px;
				}
			}
		}

		.text-dashboard {
			font-size: 18px;
			padding-left: 0;
			@include cp-property(transition, 500ms ease);
		}
	}

	.menu {
		.btn-count {
			width: 32px;
			height: 32px;
			border-radius: 15px;
			background: rgba(0, 0, 0, 0.2);
			@extend %removeBtnStyles;
			@include cp-property(transition, 400ms);

			.icon-count {
				@extend %center-align;
				width: 20px;
				height: 20px;
				fill: #fff;
				@include cp-property(transition, 400ms);
			}

			.counter-btn {
				position: absolute;
				left: 100%;
				bottom: 100%;
				@include cp-property(transform, translate(-80%, 80%));
				width: 16px;
				height: 16px;
				background: #EA2E2E;
				border-radius: 8px;
				font-size: 10px;
				line-height: 11px;
				color: #fff;

				span {
					@extend %center-align;
				}
			}

			.v-dropdown-list {
				left: 50%;

				.v-notification-list {
					right: 50%;
					@include cp-property(transform, translateX(-50%));
				}
			}

			&::after {
				content: '';
				position: absolute;
				top: 100%;
				left: 0;
				border-bottom: 16px solid white;
				border-top: 16px solid transparent;
				border-left: 16px solid transparent;
				border-right: 16px solid transparent;
				@include cp-property(transform, translateY(-30%));
				@include cp-property(transition, 200ms);
				opacity: 0;
				pointer-events: none;
			}

			&.arrow::after {
				@include cp-property(transition, 300ms);
				@include cp-property(transition-delay, 400ms);
				@include cp-property(transform, translateY(-60%));
				opacity: 1;
			}

			&:not(.disabled):hover,
			&.active {
				background: #fff;

				.icon-count {
					fill: var(--fill-hover);
				}
			}

			&.disabled {
				cursor: auto;
				pointer-events: all;

				.icon-count {
					opacity: 0.7;
				}
			}
		}

		.v-notification-list {
			width: 90vw;
			max-width: 360px;
		}

		.nav-link {
			font-weight: 300;
			font-size: 14px;
			cursor: pointer;

			&.language,
			&.btn-help {
				position: relative;

				>.icon {
					margin-left: 6px;
					width: 12px;
					fill: #fff;
					pointer-events: none;
				}
			}

			&.profile {
				position: relative;

				.picture {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					display: inline-block;
					margin-right: 8px;
					pointer-events: none;
				}

				.name {
					font-weight: 300;
					pointer-events: none;
					max-width: 130px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}

				>.icon {
					width: 7px;
					fill: #fff;
					margin-left: 10px;
					pointer-events: none;
				}

				.submenu {
					width: 240px;

					.link-submenu {
						padding: 12px 15px 12px 50px;
					}
				}
			}
		}
	}

	.text-dashboard {
		font-size: 18px;
	}

	&.compact {
		.logo {
			max-width: 0;
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		.content-btn-menu {
			max-width: 56px;

			.text-dashboard {
				padding-left: 1rem;
			}
		}
	}
}

/* ================= Menu =================== */
#main-menu {
	position: fixed;
	left: 0;
	top: $header-h;
	//@include cp-style(min-height, calc, unquote('( 100vh - '+$header-h+' )'));
	min-height: calc(100vh - #{$header-h});
	max-width: 260px;
	background: #fff;
	border-right: 1px solid $color--border;
	@include cp-property(transition, 500ms);
	z-index: 150;

	.schedule {
		padding: 2px 0.50rem;
		margin-top: 14px;
		margin-bottom: 14px;

		.btn-schedule {
			height: 46px;
			color: #fff;
			font-size: 18px;
			border: none;
			background: $color-blue-main;
			border-radius: 4px;
			width: 100%;
			@include cp-property(transition, 500ms ease);

			.icon {
				width: 20px;
				fill: #fff;
			}

			&:focus {
				outline: none;
			}
		}
	}

	.menu {
		.content-btn {

			.btn-menu,
			.btn-sub {
				padding: 14px 35px 12px 55px;
				color: $color--text2;
				position: relative;
				font-size: 14px;
				height: 50px;
				@include cp-property(transition, 500ms ease);

				.text {
					pointer-events: none;
					@include cp-property(transition, 500ms);
				}

				.icon-sub {
					position: absolute;
					@include cp-property(transform, translateY(-50%) rotate(0deg));
					top: 50%;
					right: 10px;
					@include cp-property(transition, 500ms);
				}

				.icon,
				.icon-sub {
					width: 18px;
					fill: $color--text2;
					pointer-events: none;
				}

				.icon {
					@extend %vertical-align;
					left: 20px;
				}

				&::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 0;
					@include cp-property(transition, 500ms ease);
				}

				&:hover,
				&.router-link-active {
					background: $color--whiteDark;

					&::before {
						width: 5px;
					}
				}
			}

			.btn-menu {
				&.active-sub {
					background: $color--whiteDark;

					.icon-sub {
						@include cp-property(transform, translateY(-50%) rotate(180deg));
					}
				}
			}

			.v-slide-animated {
				overflow: hidden !important;
			}
		}

		&[activeColor='blue'] .content-btn {

			.btn-menu,
			.btn-sub {
				&::before {
					background: $color-blue-light;
				}
			}
		}

		&[activeColor='green'] .content-btn {

			.btn-menu,
			.btn-sub {
				&::before {
					background: $color-green-light;
				}
			}
		}
	}

	.linc-app {
		padding: 2px 0.50rem;
		margin-top: 14px;
		margin-bottom: 14px;

		.btn-linc-app {
			height: 46px;
			width: 100%;
			color: $color-blue-main;
			border: none;
			background: #fff;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
			border-radius: 4px;
			@include cp-property(transition, 500ms ease);

			.thin {
				font-weight: 300;
			}

			.bold {
				font-weight: 500;
			}

			.all {
				display: none;
			}

			.text {
				vertical-align: middle;
			}

			.icon {
				width: 33px;
				vertical-align: middle;
				margin-right: 10px;
				background: #fff;
				box-shadow: 0 1px 2px 0 rgba(155, 155, 155, 0.43);
				border-radius: 3px;
			}

			&:focus {
				outline: none;
			}

			&:hover {
				background: $color-blue-main;
				color: #fff;
			}
		}

		&[activeColor='blue'] .btn-linc-app {
			&:hover {
				background: $color-blue-main;
			}
		}

		&[activeColor='green'] .btn-linc-app {
			&:hover {
				background: $color-green-main;
			}
		}
	}

}