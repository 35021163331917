.v-notifications{
	.v-nf-paper{
    	background: #fff;
    	box-shadow: 0 3px 6px 0 rgba(0,0,0,0.21);
    	border-radius: 5px;
    }

    .vn-btn-prev{
        @extend %removeBtnStyles;
        .vn-btn-icon{
            width: 18px;
            fill: #000;
        }
        .vn-btn-txt{
            color: #000;
        }
        .vn-btn-icon, .vn-btn-txt{
            @include cp-property(transition, 300ms ease);
        }

        &:hover{
            .vn-btn-icon{
                fill: var(--hover-color);
            }
            .vn-btn-txt{
                color: var(--hover-color);
            }
        }
    }

    .v-n-alert-icon{
        .icon-bell{
            width: 82px;
            fill: #F0F0F0;
        }
    }

    .v-n-alert-txt{
        font-size: 1rem;
        line-height: 1.2em;
        font-weight: 500;
        color:  #ccc;
    }

    .v-nf-title{
    	font-size: 1.4rem;
    	line-height: 1.2em;
    	color: #000;
    }
    .more-info .info-icon path{
        fill: #CECECE;
    }
    .v-nf-date{
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 1.2em;
        color: #4F4F4F;
    }
    .v-nf-typeMsg{
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.2em;
        color: #4F4F4F;
        b{
            font-weight: 500;
        }
        a{
            cursor: pointer;
            color: $color-blue-main;
            @include cp-property(transition, 300ms);
            &:hover{
                color: $color-blue-dark;
            }
        }
    }

    .v-bf-info{
        border-radius: 8px;
    }

    .v-nf-color-status{
        width: 12px;
        height: 12px;
        border-radius: 6px;
    }

    .v-nf-status{
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #000;
    }

    .v-nf-tutor, .v-nf-subject, .v-nf-fulldate{
        font-size: 1rem;
        line-height: 1.2em;
        font-weight: 500;
        color: #000;
    }
    .v-nf-subject, .v-nf-fulldate{
        padding-left: 25px;
        .v-nf-icon{
            position: absolute;
            top: 3px;
            left: 0;
            width: 14px;
        }
    }

    .v-topic-tag{
        background: #54CD23;
        color: #fff;
        font-size: 11px;
        line-height: 12px;
        border-radius: 2px;
    }
    .v-topic-opc{
        font-size: 12px;
        line-height: 15px;
        color: #000;
    }
    .v-topic-field{
        height: 28px;
        background: #ececec;
        border-radius: 3px;
        font-size: 12px;
        color: #000;
        padding-left: 1rem;
        padding-right: 1rem;
        border: none;
        min-width: 200px;
        box-shadow: initial;
        @include cp-property(appearance, none);
        &::-webkit-input-placeholder { /* Edge */
            color: #7f7f7f;
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #7f7f7f;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: #7f7f7f;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: #7f7f7f;
        }
        &::placeholder {
            color: #7f7f7f;
        }
        &:focus{
            outline: none;
        }
    }

    .v-nf-lessons{
        @extend %removeBtnStyles;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        @include cp-property(transition, 400ms);
        padding-right: 25px;
        .bl-icon{
            @extend %vertical-align;
            right: 0;
            width: 16px;
            fill: #000;
            @include cp-property(transition, 400ms);
        }
        &:hover{
            color: var(--hover-color);
            .bl-icon{
                fill: var(--hover-color);
            }
        }
    }
}